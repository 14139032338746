import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

var ENTER = 13;

var pressEnter = createReactClass({
    displayName: 'Press Enter',
    props: {
        wizard: PropTypes.object,
        onEnter: PropTypes.func,
        showPressEnter: PropTypes.bool,
    },

    getDefaultProps: function() {
        return {
            showPressEnter: true
        };
    },

    clickHandler: function() {
        if (this.props.onEnter) {
            this.props.onEnter();
        }

        this.props.wizard.nextQuestion();
    },

    componentDidMount: function() {
        if (this.props.showPressEnter) {
            document.addEventListener("keydown", this.handleEnterKey);
        }
    },

    componentWillUnmount: function() {
        if (this.props.showPressEnter) {
            document.removeEventListener("keydown", this.handleEnterKey);
        }
    },

    handleEnterKey: function(e) {
        if (e.which === ENTER) {
            if (e.target.tagName === "SELECT") return; // don't want to go next on this

            this.clickHandler();
        }
    },

    render: function() {
        return false; // I used to render something, now I just attach a click handler
    }
});

export default pressEnter;
