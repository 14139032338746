import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import SiteService from '../utilities/SiteService.js';

export default {
    getAll: function() {
        SiteService.getAll().done(function (sites) {
            Dispatcher.dispatch({ type: ActionTypes.GET_ALL_SITES, sites: sites.Items });
        });
    }
};