import Dispatcher from "../utilities/OraDispatcher.js";
import { ActionTypes } from "../constants/Constants.js";
import CourseService from "../utilities/CourseService.js";

var coursesRequested = {};

export function getById(courseId) {
  if (coursesRequested.hasOwnProperty(courseId)) {
    return;
  }

  coursesRequested[courseId] = true;
  CourseService.getById(courseId).done(function (course) {
    Dispatcher.dispatch({ type: ActionTypes.GET_COURSE, course: course });
  });
}

export function getCourses() {
  CourseService.getCourses().done(function (courses) {
    Dispatcher.dispatch({
      type: ActionTypes.GET_COURSES,
      courses: courses,
    });
  });
}
