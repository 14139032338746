import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import TermsAndConditionsService from '../utilities/TermsAndConditionsService.js';

export default {
    getAll: function() {
        TermsAndConditionsService.getAll().done(function (termsAndConditions) {
            Dispatcher.dispatch({ type: ActionTypes.GET_ALL_TERMS_AND_CONDITIONS, termsAndConditions: termsAndConditions });
        }).fail(function () {
            Dispatcher.dispatch({ type: ActionTypes.GET_ALL_TERMS_AND_CONDITIONS, termsAndConditions: [] });
        });
    }
};