import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';
import BasketStore from '../../stores/BasketStore.js';
import PressEnterToContinue from './press-enter-to-continue.jsx';
import FormGroup from '../common/form-group.jsx';
import partial from 'lodash/partial';
import any from 'lodash/some';

function isStudentBetween13and18(now, dateOfBirth) {
    // to figure out if they're the right age for online
    // we give them the option of being 13 or 18 over quite a long time period
    var start, end;
    if (now.month() < 8) {
        // this summer
        start = moment.max(now.clone(), now.clone().startOf('month').month(5));
        end = now.clone().startOf('month').month(8);
    } else {
        // next summer 
        start = now.clone().startOf('month').add(1, 'y').month(5);
        end = now.clone().startOf('month').add(1, 'y').month(8);
    }

    var ageStart = start.diff(dateOfBirth, 'years');
    var ageEnd = end.diff(dateOfBirth, 'years');

    return (13 <= ageStart && ageStart <= 18) || (13 <= ageEnd && ageEnd <= 18);
}

var HowToStudy = createReactClass({
    displayName: "HowToStudy",
    mixins: [],
    getInitialState: function() {
        return { isValid: true };
    },
    props: {
        wizard: PropTypes.object
    },
    handleClick: function(method) {
        if (method === "ondemand") {
            location.href = global.OraWidgets.Options.ondemandLearningUrl;
        }

        this.props.wizard.set({
            StudyMethod: method
        }, this.props.wizard.nextQuestion);
    },
    render: function() {
        var wizard = this.props.wizard;
        
        var isThisASubsequentTimeThroughTheWizard = any(BasketStore.getCourses());
        var showOnline = isStudentBetween13and18(moment.utc(), this.props.wizard.state.Student.DateOfBirth);
        var showOnDemand = false; //!isThisASubsequentTimeThroughTheWizard && isStudentBetween13And18;

        var is3rdPerson = wizard.state.IsUserCustomer;
        var selectedStudyMethod = wizard.state.StudyMethod;

        function methodChooser(method, displayName, pictureUrl, description, isComingSoon) {
            function getClassNameForStudyMethod(method) {
                return "h-full border border-solid cursor-pointer shadow hover:shadow-lg mb-6 p-2" + 
                    " " + (selectedStudyMethod === method ? "border-blue-300 ring-2 ring-blue-300" : "border-gray-300") +
                    " " + (isComingSoon ? "hover:bg-gray-300 hover:border-0 hover:ring-0 cursor-not-allowed hover:shadow" : "");
            }

            return <div className={getClassNameForStudyMethod(method)} onClick={!isComingSoon ? partial(this.handleClick, method) : function () { }}>
                <a className="bg-brand-primary text-white block font-bold mb-3 py-3 text-3xl text-center text-lg uppercase no-underline hover:text-gray-100">
                    {displayName}</a>
                <img className="w-full mx-auto" src={pictureUrl}/>
                 <p className="pt-4">{description}</p>
             </div>;
        }

        return <div className="how-to-study">
            <h2>Thank you.
                {is3rdPerson 
                    ? " How would " + wizard.state.Student.FirstName + " like to study with Oxford Royale?" 
                    : " How would you like to study with Oxford Royale?"}
            </h2>
            <FormGroup state={this.state.isValid ? "valid" : "invalid"}>
                <div className="lg:flex lg:space-x-6">
                    <div className="lg:flex-1 lg:max-w-xs">
                        {methodChooser.call(this, "onsite", "On Campus", "/images/on-campus.jpg", "Award-winning educational experiences on the campuses of the world's leading universities and schools - Oxford, Cambridge, Yale and Uppingham School.")}
                    </div>
                    {global.OraWidgets.Options.showOnline && showOnline ? <div className="lg:flex-1 lg:max-w-xs">
                        {methodChooser.call(this, "online", "Online", "/images/on-line.jpg", "Live tutorials and seminars with academics from the world's top universities, and alongside students from across the globe. Delivered to your home using Microsoft Teams.")}
                    </div> : false }
                    { showOnDemand
                        ? <div className="lg:flex-1 lg:max-w-xs">
                            {methodChooser.call(this, "ondemand", "On Demand", "/images/on-demand.jpg", "Our suite of on-demand courses in a range of subjects. Click to go through to our ORA Prep Online Learning store.")}
                        </div>
                        : false
                    }
               </div>
           </FormGroup>
        </div>;
},
    isValid: function() {
        var isValid = true;
        if (this.props.wizard.state.StudyMethod !== "onsite" && this.props.wizard.state.StudyMethod !== "online") {
            isValid = false;
        }
        this.setState({
            isValid: isValid
        });
        return isValid
    }
});

export default HowToStudy;