import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import map from 'lodash/map';

var CardIcons = createReactClass({
    render: function () {
        var iconNames = [
            'payments-accepts-01.svg', // Visa
            'payments-accepts-02.svg', // MasterCard
            'payments-accepts-03.svg', // Amex
            //'payments-accepts-04.svg', // Google Pay
            // 'payments-accepts-05.svg' // Apple Pay
        ];

        return (
            <div className="card-icons-container">
                {map(iconNames, function (iconName) {
                    return (
                        <div className="card-icon">
                            <img src={global.OraWidgets.Options.imagePath + 'vector-icons/' + iconName}/>
                        </div>);
                })}
            </div>
        );
    }
});

export default CardIcons;