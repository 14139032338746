function upgradeBasket(basket) {
    copyAndDeleteProp(basket, 'courses', 'Courses');

    // map over to PascalCase if necessary
    for (var key in basket.Courses) {
        if (basket.Courses.hasOwnProperty(key)) {
            copyAndDeleteProp(basket.Courses[key], 'course', 'Course');
            copyAndDeleteProp(basket.Courses[key], 'itemId', 'ItemId');
            copyAndDeleteProp(basket.Courses[key], 'intensity', 'Intensity');
            copyAndDeleteProp(basket.Courses[key], 'options', 'Options');
            copyAndDeleteProp(basket.Courses[key], 'selectedRegions', 'SelectedRegions');
            copyAndDeleteProp(basket.Courses[key], 'region', 'Region');
            copyAndDeleteProp(basket.Courses[key], 'student', 'Student');

            if (basket.Courses[key].Course.hasOwnProperty('Type') &&
                !basket.Courses[key].Course.hasOwnProperty('CourseType')) {
                basket.Courses[key].Course.CourseType = basket.Courses[key].Course.Type;
            }

            if (basket.Courses[key].SelectedRegions &&
                basket.Courses[key].SelectedRegions.length &&
                !basket.Courses[key].Region) {
                basket.Courses[key].Region = basket.Courses[key].SelectedRegions[0];
            }

            // TODO: maybe change price mapping for old baskets?
            if (basket.Courses[key].hasOwnProperty('Price') && 
                basket.Courses[key].Price.hasOwnProperty('Price')) {
                basket.Courses[key].Price = basket.Courses[key].Price.Price;
            }
        }
    }

    return basket;
}

function copyAndDeleteProp(obj, from, to) {
    if (obj.hasOwnProperty(from) && !obj.hasOwnProperty(to)) {
        obj[to] = obj[from];
        delete obj[from];
    }
}

function StudentEquals(left, right) {
    if (!left || !right) return false;
    return left.FirstName == right.FirstName
                && left.LastName == right.LastName
                && left.Gender == right.Gender
                && left.DateOfBirth && left.DateOfBirth.isSame(right.DateOfBirth)
                && (left.Nationality ? left.Nationality.NationalityId : -1 ) == (right.Nationality ? right.Nationality.NationalityId : -2);
}

export default { 
    upgradeBasket: upgradeBasket,
    studentEquals: StudentEquals
};