import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import create from 'lodash/create';
import Store from './Store.js';

var user;

function UserStore() {
    Store.call(this);
}

UserStore.prototype = create(Store.prototype, {
    'constructor': UserStore,

    getUser: function() {
        return user;
    },

    isWebAdmin: function() {
        return location.href.indexOf('https://localhost:44300') === 0
            || location.href.indexOf('https://ora-app-uat.azurewebsites.net') === 0
            || location.href.indexOf('https://webadmin.oxford-royale.co.uk') === 0
            || location.href.indexOf('https://webadmin.oxford-royale.com') === 0
            || global.OraWidgets.Options.IsWebAdmin;
    }
});

var userStore = new UserStore();
userStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.WHO_AM_I_SUCCESS:
            user = payload.me;
            break;

        default:
            return;
    }

    userStore.emitChange(payload.context);
});

export default userStore;