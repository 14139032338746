import React from 'react';
import createReactClass from 'create-react-class';

var ErrorBoundary = createReactClass({ 
    displayName: 'ErrorBoundary',

    componentDidCatch: function(error, errorInfo) {    
        // You can also log the error to an error reporting service  
        if (!window.rg4js) {
            return;
        }          

        rg4js('send', {
            error: error,
            customData: { info: errorInfo }
         });
    },

    getInitialState: function () {
        return { hasError: false };
    },

    restartApp: function () {
        localStorage.clear();
        location.reload();
    },

    render: function() {
        if (this.state.hasError) {      
            // You can render any custom fallback UI      
            return <div class="max-w-xl mx-auto">
                <h1 className="text-xl md:text-2xl text-center mb-4">Well, this is unexpected...</h1>
                <p className="mb-4">Sorry, this is not working properly. We've sent the mistake to our tech team so that they can get it fixed asap.</p>
                <p className="mb-4">Usually it's a temporary issue and just starting the application again works.</p>
                <p className="mb-4"><button type='button' onClick={this.restartApp} className='btn btn-primary mx-auto'>Start application again</button></p>
                <p className="mb-4 text-sm">(Sometimes it's a more permanent error - in that case, please contact us below and we'll get your application finished)</p>
            </div>;
        }
        
        return this.props.children; 
    }
});

ErrorBoundary.getDerivedStateFromError = function (error) {
    // Update state so the next render will show the fallback UI.    
    return { hasError: true };  
}

export default ErrorBoundary;