import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';
import Linkify from 'react-linkify';
import Loading from '../common/loading.jsx';
import FormGroup from '../common/form-group.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import PressEnter from './press-enter-to-continue.jsx';
import FiltersStore from '../../stores/FiltersStore.js';
import OptionsStore from '../../stores/OptionsStore.js';
import SiteActionCreators from '../../actions/SiteActionCreators.js';
import AvailabilityActionCreators from '../../actions/AvailabilityActionCreators.js';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import find from 'lodash/find';
import first from 'lodash/first';
import forOwn from 'lodash/forOwn';
import reduce from 'lodash/reduce';
import flatten from 'lodash/flatten';
import distinct from 'lodash/uniq';
import partial from 'lodash/partial';
import clone from 'lodash/clone';
import any from 'lodash/some';
import size from 'lodash/size';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import reject from 'lodash/reject';

var WhatSite = createReactClass({
    displayName: "WhatSite",
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function () {
        var state = {
            isValid: true,
            sites: FiltersStore.getSites(),
            forbiddenSitesBySession: {}
        };

        var wizardState = this.props.wizard.state;
        if (!wizardState.Course.hasCourseBreakdown) {
            state.availabilities = OptionsStore.getAvailabilities(wizardState.Course, wizardState.Intensity, wizardState.Region, wizardState.Sessions);
        }

        return state;
    },

    componentDidMount: function () {
        var wizardState = this.props.wizard.state;
        if (!wizardState.Course.hasCourseBreakdown) {
            AvailabilityActionCreators.getOptions(wizardState.Course, wizardState.Intensity, wizardState.Region, wizardState.Sessions);
            OptionsStore.addChangeListener(this.updateAvailability);
        }

        SiteActionCreators.getAll();
        FiltersStore.addChangeListener(this.updateSites);
    },

    componentWillUnmount: function() {
        FiltersStore.removeChangeListener(this.updateSites);
        OptionsStore.removeChangeListener(this.updateAvailability);
    },

    updateAvailability: function () {
        var wizardState = this.props.wizard.state;
        this.setState({
            availabilities: OptionsStore.getAvailabilities(wizardState.Course,
                wizardState.Intensity,
                wizardState.Region,
                wizardState.Sessions)
        });
    },
    
    updateSites: function() {
        var sites = FiltersStore.getSites();
        this.setState({
            sites: sites
        });
    },

    skipIfOnlyOneOption: function() {
        if (!this.state.sites || !this.state.availabilities.length) return;

        if (this.props.wizard.state.SessionPricing.IsNonResidentialAvailable) return; 

        var sessionSitePairs = getPossibleSitesBySession(this.props.wizard.state, this.state.availabilities);
        if (Object.keys(sessionSitePairs).length === 1) {
            var sessionId = Object.keys(sessionSitePairs)[0];
            if (sessionSitePairs[sessionId].length === 1) {
                var session = find(this.props.wizard.state.Sessions, function (s) { return s.SessionId == Object.keys(sessionSitePairs) });
                var site = sessionSitePairs[session.SessionId];
                this.chooseSite(session, site, this.props.wizard.nextQuestion);
            }
        }
    },

    render: function() {
        var isLoading = !this.state.sites.length || (!this.props.wizard.state.Course.HasCourseBreakdown && !this.state.availabilities.length);
        if (isLoading) { return <Loading />; }

        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;
        var student = wizard.state.Student;
        var isNonResidentialAvailable = wizard.state.SessionPricing.IsNonResidentialAvailable;
        var sessionSitePairs = getPossibleSitesBySession(wizard.state, this.state.availabilities);
        var isMultiSessionCourse = size(sessionSitePairs) > 1;

        var nonRezOption = (!isNonResidentialAvailable 
            ? false 
            : <div className="mt-12">
                <ul className="">
                    <li className="">
                        <h3 className="mb-4 pb-2 text-xl border-b-2 border-brand-secondary font-bold">Non-Residential (no accommodation)</h3>
                        <p className="mb-2">For this course, you can arrange your own accommodation and live off campus.
                        For full details on what's included and what isn't in our non-residential packages,
                            please contact us on <a href="mailto:admin@oxford-royale.co.uk" className="text-blue-500 underline cursor-pointer">admin@oxford-royale.co.uk</a> or using our live chat facility.
                            The price to take this course as non-residential is <span className="font-bold">GBP {wizard.state.SessionPricing.NonResidentialPriceIncludingDiscount}</span>
                        </p>
                        <button className={wizard.state.Accommodation.IsNonResidential ? "btn btn-selected" : "btn btn-default"} onClick={this.chooseNonRez}> Choose No Accommodation</button>
                    </li>
                </ul>
            </div>);

        return <React.Fragment><div>
            <h2>{is3rdPerson
                ? "In which accommodation would " + student.FirstName + " like to stay?"
                : "In which accommodation would you like to stay?"}
                 </h2>

                 <FormGroup state={this.state.isValid ? "valid" : "invalid"}>
                   {map(sessionSitePairs, function (sites, sessionId, self) {
                    var session = find(wizard.state.Sessions, function (s) { return s.SessionId == sessionId });
                    var isFirstInLoop = Object.keys(self)[0] === sessionId;

                   var from = moment.utc(session.From).format("Do MMMM");
                   var to = moment.utc(session.To).format("Do MMMM YYYY");

                    return <React.Fragment key={sessionId}>
                             <div className={isFirstInLoop ? undefined : "mt-12"}>
                               {isMultiSessionCourse
                                ? <React.Fragment>
                                    <h3 className="mb-4 pb-2 text-xl border-b-2 border-brand-secondary">For <span className="font-bold">{from} - {to}</span></h3>
                                    <p className="mb-4">
                                        We have the following accommodation options for {wizard.state.Course.Name} in {wizard.state.Region.RegionName}:
                                      </p>
                                </React.Fragment>
                                : <p className="mb-4">
                                    We have the following accommodation options for {wizard.state.Course.Name} in {wizard.state.Region.RegionName} for <span className="font-semibold">{from} - {to}</span>:
                                </p>}

                               <ul className="space-y-4">
                                 {map(sites, function(basicSite) {
                                    var site = find(this.state.sites, function (s) { return s.SiteId === basicSite.SiteId });
                                    var currentAccommodation = wizard.state.Accommodation;
                            
                                    var isSelected = !currentAccommodation.IsNonResidential && currentAccommodation[sessionId] && currentAccommodation[sessionId].SiteId == site.SiteId;
                                    var isForbidden = this.state.forbiddenSitesBySession[sessionId] && any(this.state.forbiddenSitesBySession[sessionId], function (s) { return s.SiteId == basicSite.SiteId });

                                     var buttonClass = "btn " + (isSelected ? "btn-primary" : "btn-default"); 

                                     var hasEnsuite = any(this.state.availabilities, function (availability) { return availability.Site.SiteId === site.SiteId && availability[wizard.state.Student.Gender + 'Ensuite'] > 0 });
                                    
                                    return <li className="shadow border border-gray-300 rounded" key={sessionId + "|" + site.SiteId}>
                                        <div className='flex flex-col items-stretch md:flex-row-reverse md:gap-2 relative'>
                                            {site.ThumbnailUrl ? 
                                                <React.Fragment>
                                                    <div className={"h-40 -mb-10 md:mb-0 md:h-auto md:w-48 shrink-0 rounded-t md:rounded-none md:rounded-r bg-cover bg-no-repeat bg-center bg-origin-border "} style={({backgroundImage: "url('" + site.ThumbnailUrl + "')"})} alt={site.Name + " thumbnail" }    />
                                                    <div className="h-40 absolute w-full md:hidden bg-gradient-to-b from-transparent from-50% to-75% to-white"></div>
                                                </React.Fragment>
                                                : false}

                                            <div className="md:flex-atleast-2/3 grow px-4 pb-4 z-10 md:pt-4">
                                                <div className="flex flex-col md:flex-row justify-between md:items-center md:gap-2 mb-2 md:mb-0">
                                                    <label className="text-lg mt-4 md:mt-0">{site.Name}</label>{hasEnsuite ? <p><span className="mr-4 text-xs px-2 py-1 bg-blue-200 text-blue-700 rounded font-semibold whitespace-nowrap">Ensuites Available</span></p> : false}
                                                </div>

                                                {isForbidden 
                                                    ? <p className="italic text-yellow-800">{site.Name} not compatible with your choice of accommodation in the {isFirstInLoop ? "second" : "first"} session. Please choose another.</p> 
                                                    : false}
                                                <Linkify 
                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                        <a target="blank" href={decoratedHref} key={key}>
                                                            {decoratedText}
                                                        </a>
                                                    )}>
                                                    <p className="mb-2">{site.Introduction}</p>
                                                </Linkify>
                                                {site.Url ? <p className="mb-2"><a className="underline text-blue-500" href={site.Url} target="_blank" rel="noreferrer noopener">View more and photographs</a></p> : false}
                                                <button className={buttonClass} onClick={partial(this.chooseSite, session, site, wizard.nextQuestion)}>Choose</button>
                                            </div>
                                        </div>
                                    </li>;
                                }.bind(this))}
                               </ul>
                             </div>
                           </React.Fragment>;
                }.bind(this))}

                   {nonRezOption}
                 </FormGroup>
                 <PressEnter wizard={wizard} showPressEnter={false}/>
               </div>
        <NavigationButtons wizard={this.props.wizard}></NavigationButtons></React.Fragment>;
    },

    chooseSite: function(session, site, wizardCallback) {
        var accom = clone(this.props.wizard.state.Accommodation);
        accom.IsNonResidential = false;
        accom[session.SessionId] = site;
        var naughtyList = whatsTheNaughtyList.call(this, session, site);
        
        // if any accommodation on the forbidden list is now prohibited, unselect it
        forOwn(naughtyList, function(sites, sessionId) {
            if (accom.hasOwnProperty(sessionId) &&
                any(sites, function(s) { return s.SiteId == accom[sessionId].SiteId })) {
                accom = omit(accom, sessionId);
            }
        });


        this.setState({ forbiddenSitesBySession: naughtyList }, function () {
            this.props.wizard.set({
                Accommodation: accom,
            }, wizardCallback);
        })
    },

    chooseNonRez: function() {
        var accom = { IsNonResidential: true };

        this.setState({ forbiddenSitesBySession: this.getInitialState().forbiddenSitesBySession }, function() {
            this.props.wizard.set({
                Accommodation: accom,
            });
        });
    },

    isValid: function() {
        var isValid = true;
        var wizard = this.props.wizard;
        var accommodation = wizard.state.Accommodation;
        if (!accommodation.hasOwnProperty("IsNonResidential")) {
            isValid = false;
        } else if (!accommodation.IsNonResidential) {
            var anySessionsDontHaveASiteSelected = any(wizard.state.SessionIds, function (sId) { return accommodation[sId] === undefined });
            if (anySessionsDontHaveASiteSelected) {
                isValid = false;
            }
        }

        this.setState({
                isValid: isValid
            });
        return isValid;
    }
});

// todo what about the cases where you have solutions: [ Site A, Site B], [Site B, Site A] and they try to choose [Site A, Site A] ??
// returns { sessionId: [{ Site A}, {Site B}....], sessionId: [...]}
function getPossibleSitesBySession(wizardState, availabilities) {
    var course = wizardState.Course;

    if (course.HasCourseBreakdown) { // then we get it from the course option solutions
        var courseOptionSolutions = wizardState.CourseOptionSolutions;
        var sessionSitePairs = forOwn(
                groupBy(flatten(courseOptionSolutions), function (co) { return co.Session.SessionId }),
            function (value, key, self) { self[key] = map(distinct(value, false, function(co) {
                return co.Site.SiteId;
            }), "Site")}
        );

        return sessionSitePairs;
    }

    return mapValues(
        groupBy(
            filter(availabilities,
                function(a) { return !a.IsClosed && a.Total > 0 }),
            function(a) { return a.Session.SessionId }),
        function(array) { return distinct(map(array, function(a) { return a.Site; }), false, function(site) {
            return site.SiteId;
        }); }); 
}

// e.g. the solutions said you could have for sessions 3 and 4 :
//  [ {3 : Site A, 4: Site B}, {3: Site B, 4: Site A}, {3: Site C, 4: Site C}]
// user chooses Session 3, Site A
// remove all solutions containing { 3 : Site A }, 
// then remove the Session 3
// this is a list of sites in session 4 that cannot be chosen
function whatsTheNaughtyList(session, site) {
    var state = this.props.wizard.state;
    var course = state.Course;

    if (!course.HasCourseBreakdown) {
        return this.getInitialState().forbiddenSitesBySession;
    }

    var courseOptionsSolutions = state.CourseOptionSolutions;
    // e.g [ {3: Site A, 4: SiteB }, {3: Site B, 4: Site A}, {3: Site C, 4: Site C} ]
    var sessionSiteCombos = map(courseOptionsSolutions, function(cos) {
        return mapValues(groupBy(cos, function (sol) { return sol.Session.SessionId; }), function(sols) {
            return first(distinct(map(sols, "Site"), false, "SiteId"));
        }); 
    });

    // e.g [{ 3: Site B, 4: Site A}, {3: Site C, 4: Site C} ]
    var removeAnySolutionsAllowedBySessionChoice = reject(sessionSiteCombos,
        function (combo) {
            return any(combo,
                function (thisSite, sessionId) {
                    return site.SiteId == thisSite.SiteId && sessionId == session.SessionId;
                });
        });

    // e.g. [{4: Site B}, {4: Site C}]
    var removeSelectedSessionKeys = map(
        removeAnySolutionsAllowedBySessionChoice, function (combo) {
            return omit(combo, session.SessionId);
        });

    // e.g. { 4: [Site A, Site C]}
    var forbiddenSitesBySessionId = reduce(removeSelectedSessionKeys, function (sessionAccumulator, sessionsAndSites) {
        forOwn(sessionsAndSites,
            function (site, sessionId) {
                if (!sessionAccumulator.hasOwnProperty(sessionId)) {
                    sessionAccumulator[sessionId] = [];
                }
                if (!any(sessionAccumulator[sessionId], function (s) { return site.SiteId === s.SiteId })) {
                    sessionAccumulator[sessionId].push(site);
                }
            });
        return sessionAccumulator;
    }, {});

    return forbiddenSitesBySessionId;
}

export default WhatSite;