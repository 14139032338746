// attach to global namespace
var isLive = location &&
(
    location.href.indexOf('www.oxford-royale.co.uk') > -1 ||
        location.href.indexOf('webadmin.oxford-royale.co.uk') > -1 ||
        location.href.indexOf('portal.oxford-royale.co.uk') > -1 ||
        location.href.indexOf('www.oxford-royale.com') > -1 ||
        location.href.indexOf('webadmin.oxford-royale.com') > -1 ||
        location.href.indexOf('portal.oxford-royale.com') > -1 ||
        (location.href.indexOf('apply.oxford-royale.com') > -1 && location.href.indexOf('flywire-test') === -1)
);
if (!global.OraWidgets) { global.OraWidgets = {}; }
if (!global.OraWidgets.Options) { global.OraWidgets.Options = {}; }
if (!global.OraWidgets.Options.minPartPaymentAmount) { global.OraWidgets.Options.minPartPaymentAmount = 500; }
if (!global.OraWidgets.Options.apiEndpoint) {
    global.OraWidgets.Options.apiEndpoint = isLive ? 'https://api.oxford-royale.com' : 'https://localhost:44302';
}

if (!global.OraWidgets.Options.showOnline) { global.OraWidgets.Options.showOnline = false }
if (!global.OraWidgets.Options.coursesPath) { global.OraWidgets.Options.coursesPath = "/course" }
if (!global.OraWidgets.Options.applyUrl) { global.OraWidgets.Options.applyUrl = "/apply" }
if (!global.OraWidgets.Options.checkoutUrl) { global.OraWidgets.Options.checkoutUrl = "/checkout" }
if (!global.OraWidgets.Options.browseUrl) { global.OraWidgets.Options.browseUrl = "/courses" }
if (!global.OraWidgets.Options.imagePath) { global.OraWidgets.Options.imagePath = "https://az707692.vo.msecnd.net/cdn/images/" }
if (!global.OraWidgets.Options.googleRecaptchaSiteKey) { global.OraWidgets.Options.googleRecaptchaSiteKey = "6LcxbioUAAAAALOhOY4OVtFkayyWkNDDzj4w0x2W" }
if (!global.OraWidgets.Options.stripeKey) { global.OraWidgets.Options.stripeKey = "pk_test_oZZgPzFuYifWb9ZS2izdxlDp" }
if (!global.OraWidgets.Options.callBackUrl) { global.OraWidgets.Options.callBackUrl = isLive ? 'https://api.oxford-royale.com/Flywire/Callback' : "https://flywire-api.ora-uat-website.com/Flywire/Callback" }
if (!global.OraWidgets.Options.applicationInsightsKey && location && (location.href.indexOf('www.oxford-royale.co.uk') > -1 || location.href.indexOf('www.oxford-royale.com') > -1 || location.href.indexOf('apply.oxford-royale.com') > -1)) { global.OraWidgets.Options.applicationInsightsKey = '80ec8bf1-6d8c-4f05-8fe6-addf2e258c67'; }
if (!global.OraWidgets.Options.zenDeskSiteKey) { global.OraWidgets.Options.zenDeskSiteKey = "6LcxbioUAAAAALOhOY4OVtFkayyWkNDDzj4w0x2W" }
if (!global.OraWidgets.Options.checkoutPrivacyNoticeTitle) { global.OraWidgets.Options.checkoutPrivacyNoticeTitle = "How do we use your information?" }
if (!global.OraWidgets.Options.checkoutPrivacyNoticeBody) {
    global.OraWidgets.Options.checkoutPrivacyNoticeBody =
        "<p>At Oxford Royale Academy, we do our best to help customers and students find the right course for them.</p><p>To assist us in doing that, we will store the information you enter into our Checkout form (excluding payment card information), and if you do not or are not able to complete your enrolment we will get in touch with you by email. If you object to this, please do not enter any information into the form.</p><p>For more information, please see our <a href=\"https://www.oxford-royale.com/about/useful-information/policies/privacy-cookies/\" target=\"_blank\">Privacy Notices</a>. Thank you.</p>";
}

if (!global.OraWidgets.Options.checkoutMarketingOptInText) { global.OraWidgets.Options.checkoutMarketingOptInText = "Please tick this box if you would like to receive information about our courses in the future." }
if (!global.OraWidgets.Options.checkoutMarketingOptInTextAdmin) { global.OraWidgets.Options.checkoutMarketingOptInTextAdmin = "Please tick if the customer would like to receive updates and information regarding other courses, products and services from Oxford Royale Academy" }
if (!global.OraWidgets.Options.checkoutPrivacyPolicy) { global.OraWidgets.Options.checkoutPrivacyPolicy = "<p>I accept the <a href=\"https://www.oxford-royale.com/about/useful-information/policies/privacy-cookies/\" target=\"_blank\">Privacy Notice</a></p>" }
if (!global.OraWidgets.Options.cjEnabled) { global.OraWidgets.Options.cjEnabled = false; }
if (!global.OraWidgets.Options.cjType) { global.OraWidgets.Options.cjType = '414210'; }
if (!global.OraWidgets.Options.cjCID) { global.OraWidgets.Options.cjCID = '1555537'; }
if (!global.OraWidgets.Options.cjQueryName) { global.OraWidgets.Options.cjQueryName = 'cjevent'; }
if (!global.OraWidgets.Options.cjCookieMaxAge) { global.OraWidgets.Options.cjCookieMaxAge = 31536000; } // 1 year
if (!global.OraWidgets.Options.isAgentBasket) { global.OraWidgets.Options.isAgentBasket = false; }
if (!global.OraWidgets.Options.paymentEnvironment) { global.OraWidgets.Options.paymentEnvironment = isLive ? "prod" : "demo"; }
if (!global.OraWidgets.Options.defaultFlywireDestination) { global.OraWidgets.Options.defaultFlywireDestination = isLive ? 'ORR' : 'ORC'; }
if (!global.OraWidgets.Options.flywirePendingMessageTitle) { global.OraWidgets.Options.flywirePendingMessageTitle = 'Thank you for arranging your payment. Your order currently has a status of Pending.'; }
if (!global.OraWidgets.Options.flywirePendingMessageText) {
    global.OraWidgets.Options.flywirePendingMessageText = [
        'When we have received the funds from our selected payment partner, Flywire, your order will be Confirmed and we will send you a Confirmation Email, a link to the ORA Portal, and a receipt.',
        'Thank you for choosing Oxford Royale Academy.'
    ];
}
if (!global.OraWidgets.Options.flywireErrorMessageTitle) { global.OraWidgets.Options.flywireErrorMessageTitle = "We're sorry, your payment was not successful."; }
if (!global.OraWidgets.Options.flywireErrorMessageText) {
    global.OraWidgets.Options.flywireErrorMessageText =
        ['Please try again or contact us for assistance.'];
}
if (!global.OraWidgets.Options.ondemandLearningUrl) { global.OraWidgets.Options.ondemandLearningUrl = "https://oraprep.learnupon.com/store"; }
if (!global.OraWidgets.Options.virtualTimeZones) {
    global.OraWidgets.Options.virtualTimeZones = [
        //{ Text: 'Western Hemisphere', Value: 'Western Hemisphere', Offset: '+0' },
        { Text: 'Eastern Hemisphere', Value: 'Eastern Hemisphere', Offset: '+11' }
    ];
}
if (!global.OraWidgets.Options.showTimesInTimezone) { global.OraWidgets.Options.showTimesInTimezone = false; }
if (!global.OraWidgets.Options.injectCss) {
    global.OraWidgets.Options.injectCss = location.href.indexOf('flywire') > -1;
}
if (global.OraWidgets.Options.payTotalDiscount !== false) {
    global.OraWidgets.Options.payTotalDiscount = 5;
}