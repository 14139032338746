export default {
    getItem: function (key) {
        var expiry = JSON.parse(global.localStorage.getItem('wa_ttl') || 'false');
        if (expiry && expiry < new Date().getTime()) {
            global.localStorage.clear();
            return null;
        }

        return global.localStorage.getItem(key);
    },

    setItem: function (key, value) {
        var now = new Date();
        var expiry = now.getTime() + (1000 * 60 * 60 * 24 * 31); // 31 days sliding expiry
        global.localStorage.setItem('wa_ttl', JSON.stringify(expiry));
        global.localStorage.setItem(key, value);
    }
};