import countryData from '../components/common/country_mobile_data';
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';

export default function (inputNumber) {
    if (!inputNumber) {
        return false;
    }

    if (inputNumber.length < 7) {
        return false;
    }

    var countries = countryData.allCountries;
    return some(countries, function (country) {
        return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
    });
};