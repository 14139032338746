import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isArray from 'lodash/isarray';

var FormGroup = createReactClass({
    displayName: "FormGroup",
    propTypes: {
        label: PropTypes.string,
        description: PropTypes.string,
        state: PropTypes.oneOf(['valid', 'invalid']),
        validationMessage: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ])
    },

    getDefaultProps: function() {
        return {
            state: 'valid',
            validationMessage: ''
        };
    },

    render: function() {
        var desc = this.props.description ? <div className="help-block">{this.props.description}</div> : false;
        var validation = this.props.state == 'invalid' ? <div className="error sr-only">{isArray(this.props.validationMessage) ? (this.props.validationMessage.length ? this.props.validationMessage[0] : '') : (!!this.props.validationMessage ? this.props.validationMessage : 'This field is required')}</div> : false;
        var className = classNames(
            { 'has-error': this.props.state == 'invalid'},
            { 'form-group': true }
        );
        return (
            <div className={className}>
                {this.props.label && <label>{this.props.label}</label>}
                <div>
                    {this.props.children}
                </div>
                {validation}
                {desc}
            </div>
		);
    }
});

export default FormGroup;