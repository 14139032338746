import React from 'react';
import ReactDOM from 'react-dom';
import ApplyWizard from './components/applywizard/apply-wizard.jsx';
import { getCourses } from './actions/CourseActionCreators.js';
import { getAll } from './actions/CountryActionCreators.js';

var applyWizard = function(div, options) {
    getCourses();
    getAll();
    ReactDOM.render(<ApplyWizard />, div);
}

jQuery("*[data-ora-application]").each(function() {
    applyWizard(this, {});
});

export default applyWizard;