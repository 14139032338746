import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import BasketStore from '../../stores/BasketStore.js';
import BasketActionCreators from '../../actions/BasketActionCreators.js';
import Ui from '../../utilities/Ui.js';
import RegionPrettyNameLookup from './region-pretty-name-lookup';
import first from 'lodash/first';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import some from 'lodash/some';
import partial from 'lodash/partial';
import partialRight from 'lodash/partialRight';
import reduce from 'lodash/reduce';
var sum = partialRight(reduce, function (total, n) { return total + n; });

var Summary = createReactClass({
    displayName: "Summary",
    getInitialState: function () {
        var totals = BasketStore.getBasketTotals();
        var bookings = BasketStore.getCourses();
        return {
            bookings: bookings,
            totalIncludingCourseDiscounts: totals.totalIncludingCourseDiscounts,
            totalIncludingBasketDiscounts: totals.totalIncludingBasketDiscounts,
            basketDeposit: totals.deposit,
            showUpsells: false,
        };
    },

    getDepositFromWizard: function () {
        var booking = this.props.wizard.state;
        var deposit = false;
        if (booking.SessionPricing) {
            deposit += booking.SessionPricing.Deposit;
        }

        if (booking.SelectedUpsellOptions && booking.SelectedUpsellOptions.length) {
            deposit += sum(map(booking.SelectedUpsellOptions, "Deposit"));
        }

        return deposit;
    },

    getTotalFromWizard: function () {
        var booking = this.props.wizard.state;
        return this.getPriceFromBooking(booking) + this.getPriceFromUpsells(booking);
    },

    getPriceFromBooking: function (booking) {
        return booking.SessionPricing &&
            booking.Accommodation &&
            booking.Accommodation.IsNonResidential
            ? booking.SessionPricing.NonResidentialPriceIncludingDiscount
            : (booking.SessionPricing ? booking.SessionPricing.PriceIncludingDiscount : false);
    },

    getPriceFromUpsells: function (booking) {
        if (booking.SelectedUpsellOptions && booking.SelectedUpsellOptions.length) {
            return sum(map(booking.SelectedUpsellOptions, function (option) { return option.Price; }));
        }

        if (booking.Course && booking.Course.CourseType.AvailabilityModel === 2) { // it's an upsell from the basket
            return booking.PriceExcludingDiscount;
        }

        return false;
    },

    componentDidMount: function () {
        BasketStore.addChangeListener(this.change);
    },

    componentWillUnmount: function () {
        BasketStore.removeChangeListener(this.change);
    },

    change: function () {
        var bookings = BasketStore.getCourses();
        var change = {};
        if (bookings !== this.state.bookings) {
            change.bookings = bookings;
        }

        var totals = BasketStore.getBasketTotals();
        var totalFromWizard = this.getTotalFromWizard();
        if (totals.totalIncludingBasketDiscounts !== this.state.totalIncludingBasketDiscounts ||
            totals.totalIncludingCourseDiscounts !== this.state.totalIncludingCourseDiscounts ||
            totals.deposit !== this.state.basketDeposit) {
            change.totalIncludingCourseDiscounts = totals.totalIncludingCourseDiscounts;
            change.totalIncludingBasketDiscounts = totals.totalIncludingBasketDiscounts;
            change.basketDeposit = totals.deposit;
        }

        if (!isEmpty(change)) {
            this.setState(change);
        }
    },

    handleRemove: function (booking) {
        var message = "Are you sure you want to remove " + booking.Course.Name + " for " + booking.Student.FirstName + " " + booking.Student.LastName + "?";

        if (confirm(message)) {
            BasketActionCreators.removeCourse(booking.ItemId);
        }
    },

    handleRemoveUpsell: function (upsell) {
        var message = "Are you sure you want to remove " + upsell.LineItem.Course.Name + "?";

        if (confirm(message)) {
            this.props.wizard.set({
                SelectedUpsellOptions: filter(this.props.wizard.state.SelectedUpsellOptions, function (u) { return u !== upsell })
            });
        }
    },

    handleChange: function (booking) {
        var course = BasketStore.getCourseByItemId(booking.ItemId);
        this.props.wizard.resetCourse();
        this.props.wizard.set({ 
            CurrentItemId: booking.ItemId,
            Student: course.Student,
            Region: booking.Region
        }, () => this.props.wizard.jump('WhatCourse'));
    },

    jumpToCustomer: function () {
        this.props.wizard.jump('Customer');
    },

    jumpToStudent: function (itemId) {
        var course = BasketStore.getCourseByItemId(itemId);
        this.props.wizard.set({ 
            CurrentItemId: itemId,
            Student: course.Student },
            () => this.props.wizard.jump('Student'));        
    },

    clearBasket: function () {
        if (confirm('Are you sure you want to clear your basket?')) {
            BasketActionCreators.clearBasket();
            this.props.wizard.reset();
        }
    },

    render: function () {
        var wizard = this.props.wizard;

        var data = this.props.wizard.state;
        var basketBookings = this.getBookingDataFromBasket();
        var basketUpsells = filter(this.state.bookings, function (b) { return b.Course.CourseType.AvailabilityModel === 2 });
        var upsellsBasketTotal = isEmpty(basketUpsells) ? 0 : sum(map(basketUpsells, this.getPriceFromUpsells));

        var bookings = basketBookings;

        var depositTotal = this.state.basketDeposit;
        var upsellsTotal = upsellsBasketTotal;

        var renderBookings = partialRight(map, function (booking, index, bookingsCollection) {
            // don't render any booking stuff if all I have is student data
            if (!booking.IsBasketCase && !data.Region && !data.Course) {
                return false;
            }

            var price = this.getPriceFromBooking(booking);

            return <React.Fragment>
                <div className="pb-2 " key={booking.IsBasketCase ? booking.key : "wizard-data"}>
                <div className="flex flex-row items-baseline">
                    {bookingsCollection.length > 1 ?
                        <div className="w-[2rem]">
                            <span className="font-semibold"> {(index + 1)}</span>
                        </div> : false
                    }
                    <div>
                    <div className="">
                        {
                            booking.StudyMethod && booking.StudyMethod === "online" || (booking.Course && booking.Course.CourseType && booking.Course.CourseType.StudyMethod === 1)
                                ? <p>Online Summer School</p>
                                : false
                        }
                        {!booking.Course
                            ? false
                            : <h3 className=" text-xl font-sans">
                                {booking.Course.Name}
                                {!booking.CourseOptionSolutions || !booking.CourseOptionSolutions.length
                                    ? false
                                    : <ul className="list-inside list-circle">
                                        {map(booking.CourseOptionSolutions[0], function (co) { return <li key={co.CourseOptionId}>{co.CourseOption.Name}</li>; })}
                                    </ul>}
                            </h3>
                        }
                        {!booking.Region
                            ? false :
                            <div>
                                { booking.Region.RegionName === 'Oxford' || booking.Region.RegionName === "Cambridge"  
                                    ? "Studying in " + booking.Region.RegionName 
                                    : "On the campus of " 
                                        + (RegionPrettyNameLookup.hasOwnProperty(booking.Region.RegionName) 
                                            ? RegionPrettyNameLookup[booking.Region.RegionName] 
                                            : booking.Region.RegionName)
                                }
                            </div>
                        }
                        {!booking.Intensity || booking.Intensity.Name === 'Default'
                            ? false
                            : <div>
                                {!booking.SessionIds
                                    ? false
                                    : <span>{map(booking.SessionDates, function (d) { return d.format("Do MMMM YYYY") }).join(" - ")}</span>}
                            </div>
                        }
                        {isEmpty(booking.Accommodation) || !booking.Course || booking.Course.CourseType.AvailabilityModel === 2 || booking.Course.CourseType.StudyMethod === 1
                            ? false
                            : <div>
                                {booking.Accommodation.IsNonResidential
                                    ? "Non Residential"
                                    : filter(uniq(map(booking.Accommodation, function(a) { 
                                        return a.Name === "Oxford College Accommodation"
                                            ? "Accommodation in an Oxford College"
                                            : a.Name === "Cambridge College Accommodation"
                                            ? "Accommodation in a Cambridge College"
                                            : a.Name;
                                    })), function (v) { return v; }).join(" & ")}
                            </div>}
                        {!booking.TimeZone
                            ? false
                            : <div>
                                {booking.TimeZone.Text}
                            </div>}
                    </div>
                    </div>
                    <div className="space-x-1 flex-auto text-right ml-auto">
                        {price
                            ? <span className="font-semibold text-lg">&pound;{Ui.formatWithCommas(price)}</span>
                            : false}
                        {!booking.IsBasketCase
                            ? false
                            : <div className="hidden group-hover:block">
                            <a className="btn btn-link ml-2" 
                                href="javascript:void(0)" 
                                onClick={partial(this.handleChange, booking, index + 1)}>change</a>
                                <br/>
                                <a className="btn btn-link ml-2" 
                                    href="javascript:void(0)" 
                                    onClick={partial(this.handleRemove, booking, index + 1)}>remove</a>
                                </div>
                        }
                    </div>
                </div>
                
            </div>
            
            {map(filter(basketUpsells, function (upsell) { return upsell.ParentItemId === booking.ItemId }), function (booking) {
                                                    return <div key={booking.ItemId} className='flex flex-row'>
                                                        {bookingsCollection.length > 1 ?
                        <div className="w-[2rem]">
                            
                        </div> : false
                    }
                                                        <p className="italic">+ {booking.Course.Name} </p>
                                                        <p className="text-right font-semibold ml-auto">
                                                            <span className='block'>&pound;{Ui.formatWithCommas(this.getPriceFromUpsells(booking))}</span>
                                                            <a className="btn btn-link invisible group-hover:visible" onClick={partial(this.handleRemove, booking)}>remove</a>
                                                        </p>
                                                    </div>
                                                }.bind(this))}
            </React.Fragment>;
        }.bind(this));

        var bookingsByStudent = groupBy(bookings, function (booking) {
            return getStudentKey(booking.Student);
        });

        return <div className="flex-auto md:flex-upto-1/3">
            <div className="flex items-baseline justify-between">
                
                
            </div>
            <div className="flex flex-col gap-y-12">
                {!data.HasStartedWizard
                    ? <span className="summary-sub-title">Watch your live summary update here as you make your selections!</span>
                    : <React.Fragment>
                        {data.IsUserCustomer || data.IsUserCustomer === null
                            ? <div className="hover:shadow hover:-mx-4 hover:px-4 hover:py-3 hover:-my-3 hover:outline hover:outline-gray-100 group">
                                <div className='float-right flex flex-col items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="text-gray-400 h-6 w-6" viewBox="0 0 16 16">
                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                                    </svg>
                                    <a className="btn btn-link hidden group-hover:block" onClick={this.jumpToCustomer}>edit</a>
                                </div>
                                <div className="uppercase text-navy-700 text-xs">Customer</div>
                            
                                <div> <span className="text-3xl font-semibold">{data.Customer.FirstName} {data.Customer.LastName}</span></div>
                                <div className="text-navy-700"> {data.Customer.Email}</div>
                                <div className="text-navy-700"> {data.Customer.Mobile}</div>
                            </div>
                            : false}

                        {map(bookingsByStudent, function (bookings, studentIdentifier) {
                            var student = bookings[0].Student;
                            return <div key={studentIdentifier} className="hover:shadow hover:-mx-4 hover:px-4 hover:py-3 hover:-my-3 hover:outline hover:outline-gray-100 group">
                                    <div className='float-right flex flex-col items-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="text-gray-400 h-6 w-6" viewBox="0 0 16 16">
                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                                    </svg>
                                    <a className="btn btn-link hidden group-hover:block" onClick={partial(this.jumpToStudent, bookings[0].ItemId)}>edit</a>
                                </div>
                                    <div className="mb-3">
                                        <div className="uppercase text-navy-700 text-xs">Student</div>
                                        <div><span className="text-3xl font-semibold">{student.FirstName} {student.LastName}</span> </div>
                                        <div>{student.DateOfBirth ? (moment.utc().diff(student.DateOfBirth, 'years')) : false} / {student.Gender} / {student.Nationality ? student.Nationality.Name : false}</div>

                                        {!data.IsUserCustomer 
                                            ? <React.Fragment>
                                                <div><label className="summary-label">Email:</label> {student.Email}</div>
                                                <div><label className="summary-label">Mobile:</label> {student.Mobile}</div>
                                            </React.Fragment>
                                            : false}

                                    </div>
                                    {renderBookings(bookings)}
                                </div>;
                        }.bind(this))}

                    </React.Fragment>
                }
            </div>
        </div>;
    },

    getBookingDataFromBasket: function () {
        var studentInWizard = this.props.wizard.state.Student;

        var courseBookings = filter(this.state.bookings, function (b) { return b.Course.CourseType.AvailabilityModel !== 2 });
        return map(courseBookings, function (data, courseKey) {
            var sessions = data.Options ? uniq(sortBy(map(data.Options, function (o) { return o.Session; }), "From"), false, "SessionId") : [];
            var wizardStateLikeData = {
                key: data.ItemId,
                ItemId: data.ItemId,
                SessionPricing: {
                    PriceIncludingDiscount: data.PriceIncludingDiscount,
                    PriceExcludingDiscount: data.PriceExcludingDiscount,
                    Deposit: data.Deposit,
                    NonResidentialPriceIncludingDiscount: data.PriceIncludingDiscount,
                    NonResidentialPriceExcludingDiscount: data.PriceExcludingDiscount
                },
                Region: data.Region,
                Course: data.Course,
                Student: data.Student,
                CourseOptionSolutions: data.Course.HasCourseBreakdown ? [flatten(map(data.Options, "Options"))] : [],
                Intensity: data.Intensity,
                SessionIds: uniq(sessions, "SessionId"),
                SessionDates: sessions.length ? [moment.utc(first(sessions).From), moment.utc(last(sessions).To)] : [],
                Accommodation: some(map(data.Options, 'IsNonResidential')) ? { IsNonResidential: true } : uniq(map(data.Options, "Site"), false, "SiteId"),
                IsBasketCase: true
            };

            return wizardStateLikeData;
        });
    },

    toggleShowUpsells: function () {
        this.setState({
            showUpsells: !this.state.showUpsells
        });
    }
});

function getStudentKey(student) {
    return student.FirstName + "|" + student.LastName + "|" + moment.utc(student.DateOfBirth).format("YYYYMMDD");
}

export default Summary;
