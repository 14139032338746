import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import AvailabilityActionCreators from '../../actions/AvailabilityActionCreators.js';
import TimeZonesStore from '../../stores/TimeZonesStore.js';
import partial from 'lodash/partial';
import map from 'lodash/map';
import FormGroup from '../common/form-group.jsx';
import NavigationButtons from './navigation-buttons.jsx';

function calcTime(currentTime, offset) {
    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    var utc = currentTime.getTime() + (currentTime.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    var nd = new Date(utc + (3600000 * offset));

    // return time as a string
    return nd.toLocaleTimeString();

}

var TimeZone = createReactClass({
    displayName: "TimeZone",
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function() {
        var data = this.props.wizard.state;
        return {
            time: new Date(),
            timeZones: TimeZonesStore.getTimeZones(data.Course, data.Sessions)
        };
    },

    updateTime: function() {
        this.setState({
            time: new Date()
        });
    },

    componentDidMount: function() {
        var data = this.props.wizard.state;
        AvailabilityActionCreators.getTimeZones(data.Course, data.Sessions);
        TimeZonesStore.addChangeListener(this.updateTimeZones);
        this.setState({
            intervalId: global.setInterval(this.updateTime, 1000)
        });
    },

    componentWillUnmount: function () {
        TimeZonesStore.removeChangeListener(this.updateTimeZones);
        if (this.state.intervalId) {
            global.clearInterval(this.state.intervalId);
        }
    },
    
    updateTimeZones: function () {
        var data = this.props.wizard.state;
        this.setState({
            timeZones: TimeZonesStore.getTimeZones(data.Course, data.Sessions)
        });
    },

    render: function() {
        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;

        function getClassNameForButton(isSelected) {
            return "btn " + (isSelected ? "btn-selected" : "btn-default");
        }

        var inner = this.state.timeZones.length
            ? <FormGroup state={this.state.showError ? "invalid" : "valid"} validationMessage="Please select an option">
                  { global.OraWidgets.Options.showTimesInTimezone
                      ? <p className="mb-8">Your local time: {this.state.time.toLocaleTimeString()}</p>
                      : false }
                  <div className="md:flex md:flex-row md:space-x-4">
                      {map(this.state.timeZones,
                          function(timeZone) {
                              return <div key={timeZone.Value} className="mb-4">
                                         <button onClick={partial(this.updateTimeZone, timeZone)} className={
getClassNameForButton(wizard.state.TimeZone === timeZone) + ' block w-full md:w-auto'}>{timeZone.Text}</button>
                                         { global.OraWidgets.Options.showTimesInTimezone
                                ? <p className="text-center">Current time: {calcTime(this.state.time, timeZone.Offset)
                                }</p>
                                : false }
                                     </div>;
                          }.bind(this))}
                  </div>
              </FormGroup>
            : <div>Finding available time zones...</div>;

        return <React.Fragment><div>
                   <h4 className="-mt-4 mb-4 italic">Course Information</h4>
                   <h2>{is3rdPerson ? "In which time zone would " + wizard.state.Student.FirstName + " like to study?" : "In which time zone would you like to study?"}</h2>
                   {inner}
               </div>
        <NavigationButtons wizard={this.props.wizard}
            hideCancel={!wizard.state.CurrentItemId}></NavigationButtons></React.Fragment>;
    },

    updateTimeZone: function (timeZone) {
        this.props.wizard.set({ TimeZone: timeZone });
        this.props.wizard.nextQuestion(true);
    },

    isValid: function() {
        if (this.props.wizard.state.TimeZone) {
            return true;
        }

        this.setState({ showError: true });
        return false;
    }
});

export default TimeZone;