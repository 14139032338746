import isEmail from 'validator/es/lib/isEmail';
import trim from 'validator/es/lib/trim';
import normalizeEmail from 'validator/es/lib/normalizeEmail';
import common from './Common.js';

// validates a student object, adding state variables and error messages where necessary
// returns an array of error messages
// indicating that an empty array indicates a valid student
export default function (customer, billingOnly) {
    // sanitisation
    var email = customer.Email && isEmail(trim(customer.Email)) ? normalizeEmail(trim(customer.Email), { remove_dots: false }) : customer.Email;
    var confirmEmail = customer.ConfirmEmail && isEmail(trim(customer.ConfirmEmail)) ? normalizeEmail(trim(customer.ConfirmEmail), { remove_dots: false }) : customer.ConfirmEmail;

    // errors
    var errorObject = {};
    if (!billingOnly) {
        common.required(customer, 'FirstName', 'The first name is required', errorObject);
        common.required(customer, 'LastName', 'The last name is required', errorObject);
        common.required(customer, 'Email', 'The email is required', errorObject);
        if (!isEmail(email)) {
            common.invalid(customer, 'Email', 'The email is not valid', errorObject);
        }

        common.required(customer, 'ConfirmEmail', 'The email confirmation is required', errorObject);
        if (email != confirmEmail) {
            common.invalid(customer, 'ConfirmEmail', 'The email confirmation does not match', errorObject);
        }

        common.required(customer, 'Mobile', 'The mobile number is required', errorObject);
    }

    common.required(customer, 'BillingLine1', '', errorObject, 'first line of the billing address');
    common.required(customer, 'BillingCity', '', errorObject, 'billing city');
    common.required(customer, 'BillingCountry', '', errorObject, 'billing country');

    return errorObject;
};