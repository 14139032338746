import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';
import AdManagerService from '../../utilities/AdManagerService.js';
import Loading from '../common/loading.jsx';
import FormGroup from '../common/form-group.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import AvailabilityStore from '../../stores/AvailabilityStore.js';
import AvailabilityActionCreators from '../../actions/AvailabilityActionCreators.js';
import CourseStore from '../../stores/CourseStore.js';

import first from 'lodash/find';
import last from 'lodash/findLast';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import any from 'lodash/some';
import all from 'lodash/every';
import size from 'lodash/size';
import partial from 'lodash/partial';
import distinct from 'lodash/uniq';
import omit from 'lodash/omit';
import Ui from '../../utilities/Ui.js';
import uniqBy from 'lodash/uniqBy.js';


var WhatSession = createReactClass({
    displayName: "WhatSession",
    props: {
        wizard: PropTypes.object,
        course: PropTypes.object
    },

    getInitialState: function () {
        return {
            availability: AvailabilityStore.getCourseAvailability(this.props.course.CourseId, null),
            courses: CourseStore.getCourses(),
            isValid: true,
            sentWaitlistDates: []
        };
    },

    componentDidMount: function () {
        AvailabilityActionCreators.getAvailability(this.props.course.CourseId, null, null);
        AvailabilityStore.addChangeListener(this.updateAvailability);
        CourseStore.addChangeListener(this.updateCourses);
        AdManagerService.sessionOptionsTag(
            this.props.course.CourseId,
            this.props.course.Name,
            this.props.course.CourseType.Name,
            this.props.wizard.state.Region ? this.props.wizard.state.Region.RegionName : null);
    },

    componentWillUnmount: function () {
        AvailabilityStore.removeChangeListener(this.updateAvailability);
        CourseStore.removeChangeListener(this.updateCourses);
    },

    updateAvailability: function () {
        var availability = AvailabilityStore.getCourseAvailability(this.props.course.CourseId, null);
        var selectedIntensity = this.props.wizard.state.Intensity;
        var intensityId =
            selectedIntensity && selectedIntensity.IntensityId
                ? selectedIntensity.IntensityId
                : availability.length
                    ? first(sortBy(distinct(map(availability, function (a) { return a.Intensity }),
                        function (i) { return i.IntensityId; }),
                        function (i) { return i.NumberOfSessions })).IntensityId
                    : null;
        this.setState({ availability: availability, intensityId: intensityId });
    },

    updateCourses: function () {
        this.setState({ courses: CourseStore.getCourses() });
    },

    render: function () {
        var wizard = this.props.wizard;
        var student = wizard.state.Student;
        var course = this.props.course;
        var imageStyleSrc = { backgroundImage: "url('" + course.ImageUrl + "')" };
        
        var isLoading = !this.state.availability.length || !this.state.courses.length;
        if (isLoading) {
            return <div className="mb-2 border rounded border-gray-300 shadow">
                <div className="flex flex-col items-stretch md:flex-row md:gap-2 relative">
                    <div className="h-40 -mb-10 md:mb-0 md:h-auto md:w-48 shrink-0 rounded-t md:rounded-none md:rounded-l bg-cover bg-no-repeat bg-center bg-origin-border" style={imageStyleSrc} alt="Flavour image" />
                    <div className="h-40 absolute w-full md:hidden bg-gradient-to-b from-transparent from-50% to-75% to-white"></div>
                    <div className="grow px-4 pb-4 z-10">
                        <div className='w-full'>
                            <h2 className="mb-4">
                                <span>{this.props.course.Name}</span>
                            </h2>

                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        }

        var hasIntensities = course.Intensities.length > 1;
        var intensitiesWithAnySessions = filter(course.Intensities, function (i) {
            // include only intensities that have a non-zero amount of sessions (in the same region)
            return any(this.state.availability, function (a) {
                var isSelectedRegion = !wizard.state.Region || a.Region.RegionId === wizard.state.Region.RegionId;
                var isSameIntensity = a.Intensity.IntensityId == i.IntensityId;
                return isSameIntensity && isSelectedRegion;
            });
        }.bind(this));
    
        var region = wizard.state.Region;
        var sessionsByPrice = uniqBy(filter(this.state.availability, s => s.Region.RegionId === region.RegionId), a => a.Price);
        var hasJustOnePrice = sessionsByPrice.length === 1;
        var intensitySessions = map(intensitiesWithAnySessions, function(intensity) {
            var sessions = filterAvailabilities.call(this, intensity);
            var seasonSessions = groupBy(sessions, function (session) {
                return session.Season.SeasonId;
            });

            return {intensity: intensity, seasonSessions: seasonSessions};
        }.bind(this));


        var allUnavailable = all(intensitySessions, function(pair) {
            return all(pair.seasonSessions, function(availabilities) {
                return all(availabilities, function(availability) {
                    var dates = getStartAndEndDates(availability.Sessions);
                    var start = dates.start;
                    var studentAgeOnSession = start.diff(student.DateOfBirth, 'years');
                    var isStudentAgeOutOfRange = studentAgeOnSession < availability.MinAge || availability.MaxAge < studentAgeOnSession;
                    return isStudentAgeOutOfRange;
                });
            });
        });

        if (allUnavailable) { // no sessions can be picked because ages out of range, therefore, this course shouldn't be on the list
            return false;
        }
        
        return <div className="mb-2 border rounded border-gray-300 shadow">
            <div className="flex flex-col items-stretch md:flex-row md:gap-2 relative">
                <div className="h-40 -mb-10 md:mb-0 md:h-auto md:w-48 shrink-0 rounded-t md:rounded-none md:rounded-l bg-cover bg-no-repeat bg-center bg-origin-border" style={imageStyleSrc} alt="Flavour image" />
                <div className="h-40 absolute w-full md:hidden bg-gradient-to-b from-transparent from-50% to-75% to-white"></div>
                <div className="grow px-4 pb-4 z-10">
                    <div className="w-full">
                        <h3 className="mt-2 mb-4 text-xl md:text-2xl flex items-center justify-between gap-4">
                            <span className=''>{this.props.course.Name}</span>

                            <span className="font-sans">
                                {hasJustOnePrice
                                    ? <span className='whitespace-nowrap'>
                                        <span className='text-gray-600 text-base mr-1'></span>
                                        <span className='font-bold'>&pound;{Ui.formatWithCommas(sessionsByPrice[0].Price)}</span>
                                    </span>
                                    : false}
                            </span>
                        </h3>

                        {map(intensitySessions, function (pair) {
                            var intensity = pair.intensity;
                            var seasonSessions = pair.seasonSessions;

                            return <div key={intensity.IntensityId}>
                                {hasIntensities
                                    ? <h4 className="text-gray-600 font-bold mb-2">{intensity.Name}</h4>
                                    : false}

                                {map(seasonSessions, function (sessions, seasonId) {
                                    return <div key={seasonId}>
                                        <ol className="gap-4">
                                            {size(seasonSessions) > 1 ? <h4>{sessions[0].Season.Name}</h4> : false}
                                            {map(
                                                sortBy(
                                                    sessions,
                                                    function (availability) {
                                                        var dates = getStartAndEndDates(availability.Sessions);
                                                        return dates.start.unix();
                                                    }),
                                                function (availability) {
                                                    var dates = getStartAndEndDates(availability.Sessions);
                                                    var start = dates.start;
                                                    var end = dates.end;

                                                    var placesForStudent = availability[student.Gender];
                                                    var sessionIds = map(availability.Sessions, function (s) { return s.SessionId }).join("-");
                                                    var isSelected = wizard.state.Course && wizard.state.Course.CourseId === this.props.course.CourseId
                                                        && wizard.state.SessionIds && wizard.state.SessionIds.join("-") === sessionIds;

                                                    var studentAgeOnSession = start.diff(student.DateOfBirth, 'years');
                                                    var isStudentAgeOutOfRange = studentAgeOnSession < availability.MinAge || availability.MaxAge < studentAgeOnSession;

                                                    var bookButtonClass = "my-1 btn btn-sm " + (isSelected ? "btn-primary " : "btn-default ") + (isStudentAgeOutOfRange ? 'hint--left' : '');

                                                    var hasWaitlistBeenJoined = !!this.state.sentWaitlistDates && any(this.state.sentWaitlistDates, function (datePair) {
                                                        return datePair.start._i === start._i && datePair.end._i === end._i;
                                                    });
                                                    var key = this.props.course.CourseId + ":" + sessionIds;

                                                    return <li className="flex items-center gap-2" key={key} >
                                                        <div className='md:w-20'>
                                                            {placesForStudent === 0
                                                                ? <button className="my-1 btn btn-sm btn-disabled">Full</button>
                                                                : <button className={bookButtonClass} onClick={partial(this.selectSession, availability)} disabled={isStudentAgeOutOfRange} data-hint={isStudentAgeOutOfRange ? "Student age will not be suitable for this course when it starts" : null}>Book</button>}

                                                        </div>

                                                        <div>
                                                            <p className='md:text-lg'>
                                                                {start.format("ddd Do MMMM")} - <span className="text-sm">{end.format("ddd Do MMMM")}</span>
                                                            </p>

                                                            <p className='md:grow -mt-2'>
                                                                {placesForStudent <= 5
                                                                    ? placesForStudent <= 0 && !isStudentAgeOutOfRange
                                                                        ? (hasWaitlistBeenJoined
                                                                            ? <span className='text-blue-900 text-xs'>Waiting list joined</span>
                                                                            : <a onClick={partial(this.joinWaitlist, availability)} >Join waiting list</a>)
                                                                        : <span className="text-amber-800 text-xs">Last few spaces</span>
                                                                    : <span className="text-green-800 text-xs">Good availability</span>}
                                                            </p>
                                                        </div>



                                                        {!hasJustOnePrice
                                                            ? <span className='whitespace-nowrap md:grow text-right md:pr-6'>
                                                                <span className='text-gray-600 text-base mr-1'></span>
                                                                <span className='font-bold'>&pound;{Ui.formatWithCommas(availability.Price)}</span>
                                                            </span>
                                                            : false}
                                                    </li>
                                                }.bind(this))
                                            }
                                        </ol>
                                    </div>
                                }.bind(this))
                                }
                            </div>
                        }.bind(this))}
                    </div>

                </div>
            </div>
        </div>
    },

    selectSession: function (availability, intensity) {
        var sessions = availability.Sessions;
        this.props.wizard.set({
            SessionIds: map(sessions, function (s) { return s.SessionId }),
            SessionDates: map(getStartAndEndDates(sessions), function (d) { return d }),
            Sessions: sessions,
            Intensity: availability.Intensity,
            Course: this.props.course,
            SessionPricing: omit(availability, ["Seasons", "Sessions", "Region", "Intensity", "Male", "Female", "Total"]),
        }, this.props.wizard.nextQuestion);
        AdManagerService.bookTag(
            this.props.course.CourseId,
            this.props.course.Name,
            this.props.course.CourseType.Name,
            this.props.wizard.state.Region ? this.props.wizard.state.Region.RegionName : null,
            availability.Intensity.Name,
            availability.Price,
            map(sessions, function (s) { return s.Name; }).join(','));
    },

    joinWaitlist: function (availability) {
        var wizard = this.props.wizard;
        var student = wizard.state.Student;
        var is3rdPerson = wizard.state.IsUserCustomer;
        var course = this.props.course;

        var options = map(availability.Sessions, function (s) {
            return {
                Session: s
            };
        });

        AvailabilityActionCreators.sendNotifyRequest(course,
            availability.Intensity,
            options,
            availability.Region,
            is3rdPerson ? wizard.state.Customer.Email : student.Email,
            student.Mobile,
            student.FirstName + ' ' + student.LastName,
            student.Gender);
        this.setState({ isSent: true, errorMessage: '' });

        var currentNotifyDates = this.state.sentWaitlistDates;
        currentNotifyDates.push(getStartAndEndDates(availability.Sessions));
        this.setState({ sentWaitlistDates: currentNotifyDates });
    },

    updateIntensity: function (intensity) {
        this.setState({
            intensityId: intensity.IntensityId
        });
    },

    isValid: function () {
        var isValid = true;
        var availabilities = filterAvailabilities.call(this);
        var wizard = this.props.wizard;
        if (!wizard.state.SessionIds || !wizard.state.SessionIds.length || !any(availabilities, function (a) {
            return all(a.Sessions, function (s, i) { return s.SessionId === wizard.state.SessionIds[i] });
        })) {
            isValid = false;
        }

        this.setState({
            isValid: isValid
        });
        return isValid;
    }
});

function getStartAndEndDates(sessions) {

    var dateOrderedSessions = sortBy(sessions, function (s) { return s.StartDate }); // lexical sorting is FINE because it's ISO format :)
    return { start: moment.utc(first(dateOrderedSessions).From), end: moment.utc(last(dateOrderedSessions).To) };
}

function filterAvailabilities(intensity) {
    var sessions = this.state.availability;
    if (intensity) {
        sessions = filter(sessions, function (s) { return s.Intensity.IntensityId === intensity.IntensityId }.bind(this));
    }
    if (this.props.wizard.state.Region) {
        sessions = filter(sessions, function (s) { return s.Region.RegionId === this.props.wizard.state.Region.RegionId }.bind(this));
    }
    return sessions;
}

export default WhatSession;
