import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';
import CourseStore from '../../stores/CourseStore';
import NavigationButtons from './navigation-buttons';

var NoCoursesAvailable = createReactClass({ 
    displayName: 'NoCoursesAvailable',
    getInitialState: function() {
        return { courses: CourseStore.getCourses() }
    },
    props: {
        wizard: PropTypes.object
    },
    
    render: function() {
        var wizard = this.props.wizard;
        
        var studentAgeNow = moment().diff(wizard.state.Student.DateOfBirth, 'years');

        // var isAfterSeptember = moment.utc().month() >= 8;
        // var studentAgeInSummer =
        //     isAfterSeptember 
        //         ? moment([moment.utc().year() + 1, 7, 1]).diff(wizard.state.Student.DateOfBirth, 'years')
        //         : moment([moment.utc().year(), 7, 1]).diff(wizard.state.Student.DateOfBirth, 'years');

        const lowerAgeBound = 14;
        const upperAgeBound = 18;
        return <React.Fragment> <div>

            <h2>Unfortunately we are not currently offering courses for students aged {studentAgeNow}.</h2>

            {studentAgeNow >= 25 
            ? <p className='mb-8'>
                Please check you've entered the right details for the student who will be attending the course.
                You can go back and <a onClick={wizard.previousQuestion}>change their details</a> if required.
            </p> 
            : false}
                
            <p className='mb-16'>
                We currently offer a variety of courses for students aged {lowerAgeBound} - {upperAgeBound} through our website, if you're interested in opportunities for older or youger students, please contact our enrolment team.
                </p>
 
        </div>
        <NavigationButtons wizard={this.props.wizard} hideNext={true}></NavigationButtons>
        </React.Fragment>;
    }
});

export default NoCoursesAvailable;