import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import Loading from '../common/loading.jsx';
import PressEnter from './press-enter-to-continue.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import UpsellActionCreators from '../../actions/UpsellActionCreators.js';
import BasketStore from '../../stores/BasketStore.js';
import some from 'lodash/some';
import map from 'lodash/map';
import filter from 'lodash/filter';
import partial from 'lodash/partial';
import flatten from 'lodash/flatten';

var Upsells = createReactClass({
    displayName: 'Upsells',

    props: {
        wizard: PropTypes.object
    },

    getInitialState: function() {
        return {
            isSearching: true,
            upsellOptions: []
        };
    },

    componentDidMount: function () {
        UpsellActionCreators.getOptions(this.props.wizard.getLineItem());
        BasketStore.addChangeListener(this.updateBasket);
    },

    componentWillUnmount: function() {
        BasketStore.removeChangeListener(this.updateBasket);
    },

    isValid: function() {
        return true;
    },

    updateBasket: function() {
        var isSearching = BasketStore.isUpsellSearching();
        if (this.state.isSearching !== isSearching) {
            if (!isSearching) {
                var lineItem = this.props.wizard.getLineItem();
                if (!BasketStore.getUpsellOptions(lineItem).Options.length) {
                    this.props.wizard.nextQuestion(function () {
                        this.props.wizard.set({
                            SkipUpsell: true // this removes the component from the wizard components and forwards us on,
                        });
                    }.bind(this));                  
                    return;
                }
            }

            this.setState({
                isSearching: isSearching,
                upsellOptions: BasketStore.getUpsellOptions(lineItem).Options
            });
        }
    },

    handleChangeOption: function(option, e) {
        var newSelectedOptions;
        if (this.optionSelected(option)) {
            newSelectedOptions = filter(this.props.wizard.state.SelectedUpsellOptions,
                function(selectedOption) {
                    return option.LineItem.Course.CourseId !== selectedOption.LineItem.Course.CourseId;
                });
        } else {
            newSelectedOptions = this.props.wizard.state.SelectedUpsellOptions.concat(option);
        }

        this.props.wizard.set({
            SelectedUpsellOptions: newSelectedOptions
        });
    },

    optionSelected: function(option) {
        return some(this.props.wizard.state.SelectedUpsellOptions,
            function(selectedOption) {
                return option.LineItem.Course.CourseId === selectedOption.LineItem.Course.CourseId;
            });
    },
    
    render: function() {
        var isLoading = this.state.isSearching;
        if (isLoading) {
            return <Loading />;
        }

        return <React.Fragment><div>
                   <h2>Would you like any of the following extras?</h2>

            {map(this.state.upsellOptions, function (upsellOption) {
                       
                return <div key={upsellOption.Title}>
                    <h4 className="mb-4 pb-2 text-xl border-b-2 border-brand-secondary">{upsellOption.Title}</h4>
                    <div className="flex flex-col md:flex-row items-start justify-between gap-4 mb-4">
                        {upsellOption.ImageUrl ? <img src={upsellOption.ImageUrl} className="md:w-1/3 flex-shrink-0" /> : false}
                        <Linkify 
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}>
                            <p className="mb-1">{upsellOption.Description}</p>
                        </Linkify>
                    </div>
                    <ul className="pb-2 mb-6">
                        {
                            map(flatten(map(upsellOption.Options, "Options")), function (option) {
                                return <li className="flex items-start justify-between" key={option.LineItem.Course.CourseId}>
                                    <span style={{"flex": "0 1 75%"}}>{option.LineItem.Course.Name}</span>
                                    <label className="text-right" style={{"flex": "1 1 auto"}}>
                                        &pound;{option.Price}
                                        <input type="checkbox" checked={this.optionSelected(option)} onChange={partial(this.handleChangeOption, option)} className="ml-2" />
                                    </label>
                                </li>;
                            }.bind(this))
                            }
                    </ul>
                           </div>;
                   }.bind(this))}
            

                   <PressEnter wizard={this.props.wizard} showPressEnter={false}/>
               </div>
        <NavigationButtons wizard={this.props.wizard}></NavigationButtons></React.Fragment>;
    }
});

export default Upsells;