import React from 'react';
import createReactClass from 'create-react-class';
import StudentBase from './student-base.js';
import PressEnter from '../press-enter-to-continue.jsx';
import FormGroup from '../../common/form-group.jsx';
import nationalities from '../../common/nationality-list.js';
import CourseStore from '../../../stores/CourseStore.js';
import BasketStore from '../../../stores/BasketStore.js';
import BasketActionCreators from '../../../actions/BasketActionCreators.js';
import NavigationButtons from '../navigation-buttons.jsx';
import Loading from '../../common/loading.jsx';

import deepLinkParse from '../deep-link-parser.js';

import moment from 'moment';
import partial from 'lodash/partial';
import extend from 'lodash/extend';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import any from 'lodash/some';
import sortBy from 'lodash/sortBy.js';

var Student = createReactClass({
    displayName: 'Student',
    mixins: [StudentBase],
    getInitialState: function () {
        var wizard = this.props.wizard;
        var isAdditionalCourse = BasketStore.numberOfItems() > 0;
        let init = {
            hasValidated: false,
            firstNameError: false,
            lastNameError: false,
            genderError: false,
            dobErrors: [],
            nationalityError: false,
            courses: CourseStore.getCourses(),
            isAdditionalCourse: isAdditionalCourse
        };

        var dob = this.props.wizard.state.Student.DateOfBirth;
        if (dob) {
            return extend(init, {
                    day: dob.date(),
                    month: dob.month(),
                    year: dob.year()
                });
        }
        return init;
    },
    
    componentDidMount: function () {
        CourseStore.addChangeListener(this.updateCourses);
    },

    componentWillUnmount: function () {
        CourseStore.removeChangeListener(this.updateCourses);
    },

    render: function () {
        var wizard = this.props.wizard;
        var customerCountry = wizard.state.Customer.Country;

        if (!this.state.courses.length) {
            return <Loading></Loading>;
        }
        
        function getClassNameForButton(isSelected) {
            return "btn" + " " + (isSelected ? "btn-primary" : "btn-default");
        }

        return <React.Fragment>
            <div>
            <h2>And now, who is attending Oxford Royale?</h2>
            <label>Student's name</label>
            <div className="md:flex md:space-x-4 lg:space-x-8 max-w-screen-sm">
                <div className="md:flex-1">
                    <FormGroup state={this.state.firstNameError ? "invalid" : "valid"}>
                        <input className="form-control" type="text" name="FirstName" onChange={this.updateStudent} value={wizard.state.Student.FirstName || ''} placeholder="First Name" ref={this.state.firstInput} />
                    </FormGroup>
                </div>
                <div className="md:flex-1">
                    <FormGroup state={this.state.lastNameError ? "invalid" : "valid"}>
                        <input className="form-control" type="text" name="LastName" onChange={this.updateStudent} value={wizard.state.Student.LastName || ''} placeholder="Last Name" />
                    </FormGroup>
                </div>
            </div>

            <label className="flex items-center space-x-2">
                <span>Gender</span>
            </label>
            <FormGroup state={this.state.genderError ? "invalid" : "valid"} validationMessage="Please select an option">
                <div className="space-x-4 flex items-center">
                    <button onClick={partial(this.updateStudentGender, "Female", false)} className={getClassNameForButton(wizard.state.Student.Gender === "Female")}>
                        Female
                    </button>
                    <button onClick={partial(this.updateStudentGender, "Male", false)} className={getClassNameForButton(wizard.state.Student.Gender === "Male")}>
                        Male
                    </button>
                <a className="h-5 w-5" data-hint="Oxford Royale welcomes diversity and would like to support all students in feeling comfortable at our programmes. Please contact us if you would like to discuss your child's gender.">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                </a>

                </div>
            </FormGroup>

            <label>Date of birth</label>
            <FormGroup state={this.state.dobErrors.length ? "invalid" : "valid"} validationMessage={this.state.dobErrors}>
                <div className="flex justify-start space-x-4 max-w-screen-sm">
                    <div className="w-[6rem]">
                        <label className="control-label sr-only">Day</label>
                        <input className="form-control " type="text" name="day" onChange={this.updateStudentDob} value={this.state.day || ''} placeholder="dd" inputMode="numeric" pattern="[0-9]*" />
                    </div>
                    <div className="w-[10rem]">
                        <label className="control-label sr-only">Month</label>
                        <select className="form-control" placeholder="month" name="month" onChange={this.updateStudentDob} value={(this.state.month === 0 ? '0' : (this.state.month || '') + '')}>
                            <option value="" disabled>Month</option>
                            <option value="0">January</option>
                            <option value="1">February</option>
                            <option value="2">March</option>
                            <option value="3">April</option>
                            <option value="4">May</option>
                            <option value="5">June</option>
                            <option value="6">July</option>
                            <option value="7">August</option>
                            <option value="8">September</option>
                            <option value="9">October</option>
                            <option value="10">November</option>
                            <option value="11">December</option>
                        </select>
                    </div>
                    <div className="w-[6rem]">
                        <label className="control-label sr-only">Year</label>
                        <input className="form-control" type="text" name="year" onChange={this.updateStudentDob} value={this.state.year || ''} placeholder="yyyy" inputMode="numeric" pattern="[0-9]*" />
                    </div>
                    {wizard.state.CurrentItemId
                    ? <a href="javascript:void(0)" className="h-5 w-5" data-hint="Changing the date of birth may prevent you booking on to the course. In that case, click 'Update' and then change the course from the summary">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                    </a>
                    : false}
                </div>
            </FormGroup>

            <label>Nationality</label>
            <FormGroup state={this.state.nationalityError ? "invalid" : "valid" }>
                <select className="form-control max-w-lg" name="Nationality" onChange={this.updateNationality} defaultValue={wizard.state.Student.Nationality ? wizard.state.Student.Nationality.NationalityId : 0}>
                    <option value={0} key="0">Select</option>
                    {map(sortBy(nationalities, function(c) {
                        return c.CountryId === customerCountry.CountryId ? 0 : 1;
                    }), function(c) {
                        return <option key={c.NationalityId} value={c.NationalityId}>{c.Name}</option>;
                    })}
                </select>
            </FormGroup>

            <PressEnter wizard={wizard} />
        </div>
        <NavigationButtons wizard={this.props.wizard}
            backText={wizard.state.CurrentItemId ? "Cancel" : undefined}
            nextText={wizard.state.CurrentItemId ? "Save": undefined}
            hideBack={this.state.isAdditionalCourse}
            onBack={wizard.state.CurrentItemId ? this.props.wizard.jumpToWhatNext : undefined}></NavigationButtons>
        </React.Fragment>;
    },

    onSubmit: function () {        
        var wizard = this.props.wizard;
        if (wizard.state.CurrentItemId) {
            BasketActionCreators.chooseStudent(wizard.state.CurrentItemId, wizard.state.Student);
            wizard.jumpToWhatNext();
            return false;
        }

        return true;
    },

    isValid: function () {
        let firstNameError = !this.isNonEmptyProp("FirstName");
        let lastNameError = !this.isNonEmptyProp("LastName");
        let genderError = !this.isNonEmptyProp("Gender");
        let dobErrors = [];
        let nationalityError = !this.isNonEmptyProp("Nationality");

        if (!this.state.day || (!this.state.month && this.state.month !== 0) || !this.state.year) {
            dobErrors.push("This field is required");
        }
        else {
            var dob = parseDate(this.state);
            if (!isRealisticDateOfBirth(dob)) {
                dobErrors.push("Please enter a valid date");
            }
        }

        this.setState({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            genderError: genderError,
            dobErrors: dobErrors,
            nationalityError: nationalityError,
            hasValidated: true
        });
        return !firstNameError && !lastNameError && !genderError && !dobErrors.length && !nationalityError;
    },

    updateStudentGender: function (val, jumpToNextQuestion) {
        this.updateStudentProp("Gender", val);
        if (jumpToNextQuestion === true) {
            this.props.wizard.nextQuestion();
        }
    },

    updateStudentDob: function (e) {
        var update = {};
        update[e.target.name] = e.target.value;
        this.setState(update, function () {
            var parsedDob = parseDate(this.state);
            if (isRealisticDateOfBirth(parsedDob)) {
                this.updateStudentProp("DateOfBirth", parsedDob, this.updateAvailableCourses);
            }
        });
    },

    updateCourses: function() {
        this.setState({
            courses: CourseStore.getCourses()
        });
    },

    updateAvailableCourses: function() {
        var studentDob = this.props.wizard.state.Student.DateOfBirth;
        let courses = this.state.courses;
        let coursesInAgeGroup = getCoursesForAgeGroup(courses, studentDob);        
        var result = deepLinkParse(coursesInAgeGroup, this.props.wizard.state) || {};
        result.StudentTooOldOrTooYoung = !any(coursesInAgeGroup);
        this.props.wizard.set(result);
    },  

    updateNationality: function(event) {
        var val = event.target.value;
        var selectedNationality = null;
        if (val) {
            selectedNationality = find(nationalities,
                function(nationality) {
                    return nationality.NationalityId == val;
                });
        }

        this.updateStudentProp('Nationality', selectedNationality);
    },

});

function parseDate(state) {
    var dateObj = mapValues(pick(state, "year", "month", "day"), function (str) { return parseInt(str); });
    return moment.utc(extend({ year: 'invalid' }, dateObj));
}

function isRealisticDateOfBirth(momentDate) {
    return momentDate.isValid() && 1900 < momentDate.year() && momentDate.year() < (moment.utc().year() - 5);
}

function getCoursesForAgeGroup(courses, studentDob) {
    return filter(courses, function (c) {
        return any(c.Dates, function (cd) {
            var studentAgeOnDate = moment.utc(cd.From).diff(studentDob, 'years');

            function isStudentAgeEligible(ag) {
                return (!ag.LowerBound || ag.LowerBound <= studentAgeOnDate) && (!ag.UpperBound || studentAgeOnDate <= ag.UpperBound);
            }

            return any(c.AgeGroups, isStudentAgeEligible);
        });
    });
}

export default Student;