import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isString from 'lodash/isstring';
import debounce from 'lodash/debounce';

var TypeAhead = createReactClass({
    propTypes: {
        dataSource: PropTypes.string,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        placeHolder: PropTypes.string,
        value: PropTypes.string,
        autoSuggestEnabled: PropTypes.bool,
        className: PropTypes.string,
        func: PropTypes.func
    },

    getDefaultProps: function() {
        return {
            value: ''
        };
    },

    getInitialState: function() {
        return {
            selectedIdx: -1,
            items: [],
            isFocussed: false
        };
    },

    componentWillMount: function() {
        this.isMouseDown = false;
        this.func = this.props.autoSuggestEnabled ? this.props.func || generateFuncForUrl(this.props.dataSource) : function() {};
        jQuery(window).on('mousedown', this.pageClick);
    },

    componentWillUnmount: function() {
        jQuery(window).off('mousedown', this.pageClick);
    },

    pageClick: function() {
        if (this.isMouseDown) {
            return;
        }

        this.setState({ isFocussed: false });
    },

    mouseDown: function() {
        this.isMouseDown = true;
    },

    mouseUp: function() {
        this.isMouseDown = false;
    },

    onFocus: function() {
        this.setState({ isFocussed: true });
    },

    onBlur: function() {
        if (!this.isMouseDown) {
            this.setState({ isFocussed: false });
        }
    },

    onKeyDown: function(e) {
        var code = e.which ? e.which : e.keyCode;
        if (code === 40) {
            // down
            e.preventDefault();
            if (this.state.selectedIdx < this.state.items.length - 1) {

                //This looks odd, but we need to pass the whole "e.target.value" up a chain for the onChange event.
                e.target.value = this.state.items[this.state.selectedIdx + 1].Text;
                this.setState({ selectedIdx: (this.state.selectedIdx + 1) });

                this.props.onChange && this.props.onChange(e);
            }
        } else if (code === 38) {
            // up
            e.preventDefault();
            if (this.state.selectedIdx > -1) {

                //This looks odd, but we need to pass the whole "e.target.value" up a chain for the onChange event.
                e.target.value = this.state.items[this.state.selectedIdx - 1].Text;
                this.setState({ selectedIdx: this.state.selectedIdx - 1 });

                this.props.onChange && this.props.onChange(e);
            }
        }

        if (code === 13 || code === 9) {
            // enter or tab
            if (this.state.selectedIdx > -1) {
                this.select(this.state.items[this.state.selectedIdx]);
            } else {
                this.select(this.props.value);
            }
        } else {
            this.setState({ isFocussed: true });
        }

        this.lastKeyPress = code;
    },

    lastKeyPress: false,
    callback: function (items) {
        var newState = { items: items, selectedIdx: -1 };

        // if there's only one item and they've not pressed backspace set the text to the single result - SCRAP THAT, I DON'T LIKE IT
        //if (items.length === 1 && (!this.lastKeyPress || (this.lastKeyPress !== 8 && this.lastKeyPress !== 46))) {
        //    newState.text = items[0];
        //    this.props.onChange && this.props.onChange(items[0]);
        //}

        this.setState(newState);
    },

    onChange: function (e) {
        // update the search box
        this.setState({ text: e.target.value });

        // perform the search
        this.func(e.target.value, this.callback);

        // signal consumer
        this.props.onChange && this.props.onChange(e);
    },

    select: function (selectedItem) {
        //calling setState is not guaranteed to update synchronously
        var item;
        if (!selectedItem) {
            item = { Text: this.props.value };
        } else {
            if (isString(selectedItem)) {
                item = { Text: selectedItem };
            } else {
                item = selectedItem;
            }
        }

        var fakeEvent = { target: { value: this.props.value } };
        this.props.onChange && this.props.onChange(fakeEvent);
        this.props.onSelect && this.props.onSelect(item);
        this.setState({ isFocussed: false });
    },

    render: function () {
        var lists = map(this.state.items, function (item, idx) {
            var className = idx === this.state.selectedIdx ? 'active' : '';
            var click = function() {
                this.select(item);
            }.bind(this);

            var mouseOver = function() {
                this.setState({ selectedIdx: idx });
            }.bind(this);

            return <li key={item.Text + '-' + (item.CourseId || '0') + '-' + (item.CourseOptionId || '0')} className={className} onClick={click} onMouseOver={mouseOver }>{item.Text}</li>;
        }.bind(this));

        var div = false;
        if (this.state.isFocussed && this.props.value && this.props.value.length && this.props.autoSuggestEnabled) {
            div = <div onMouseDown={this.mouseDown} onMouseUp={this.mouseUp} className="filter-picker">
                    <ul className="list-unstyled">{lists}
                    </ul>
            </div>;
        }

        return (
            <div className="typeahead">
                <input placeholder={this.props.placeHolder} type="text" value={this.props.value} onChange={this.onChange} className={this.props.className + " form-control"} onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} />
                {div}
            </div>
		);
    }
});

function generateFuncForUrl(url) {
    return debounce(function(word, callback) {
            jQuery.get(url + '?query=' + encodeURIComponent(word),
                function(response) {
                    callback(response);
                });
        },
        250);
}

function generateFuncForArrayOfStrings(array) {
    return function (word, callback) {
        var results = [];
        for (var i = 0; i < array.length; i++) {
            if (array[i].indexOf(word) > -1) {
                results.push(array[i]);
            }
        }

        callback(results);
    }
}

export default TypeAhead;