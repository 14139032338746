import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import WidgetOptions from '../../utilities/WidgetOptions.js';
import CustomerForm from './customer-form.jsx';
import UserStore from '../../stores/UserStore.js';
import AgentForm from './agent-form.jsx';
import map from 'lodash/map';
import isArray from 'lodash/isarray';

var CheckoutCustomer = createReactClass({
    displayName: 'CheckoutCustomer',
    propTypes: {
        showCustomerFields: PropTypes.bool,
        customer: PropTypes.object,
        errorState: PropTypes.object,
        onUpdate: PropTypes.func, // called whenever a field is blurred or changed considerably (for validation purposes), not called when text is being input for example
        courses: PropTypes.object,
        countries: PropTypes.array,
        isWebAdminUser: PropTypes.bool,
        onUpdateIsOptedInToMarketing: PropTypes.func,
        isOptedInToMarketing: PropTypes.bool,
        termsAndConditions: PropTypes.array,
        hasAcceptedTermsAndConditions: PropTypes.bool,
        hasAcceptedPrivacyPolicy: PropTypes.bool,
        onUpdateTermsAndConditions: PropTypes.func,
        onUpdatePrivacyPolicy: PropTypes.func,
        onUpdateOverrideAgeConstraints: PropTypes.func,
        hasOverrideAgeConstraints: PropTypes.bool,
        isAgentBooking: PropTypes.bool,
        onUpdateIsAgentBooking: PropTypes.func,
        agentId: PropTypes.number,
        onUpdateAgentId: PropTypes.func,
        agentName: PropTypes.string,
        onUpdateAgentName: PropTypes.func,
        onUpdateAreFinancialsVisibleToCustomer: PropTypes.func,
        areFinancialsVisibleToCustomer: PropTypes.bool,
        skipTermsAndConditions: PropTypes.bool,
        skipPrivacyPolicy: PropTypes.bool
    },

    getDefaultProps: function () {
        var emptyFunction = function() {};
        return {
            showCustomerFields: true,
            hasOverrideAgeConstraints: false,
            onUpdateOverrideAgeConstraints: emptyFunction,
            isAgentBooking: false,
            onUpdateIsAgentBooking: emptyFunction,
            agentName: null,
            onUpdateAgentName: emptyFunction,
            agentId: null,
            onUpdateAgentId: emptyFunction,
            areFinancialsVisibleToCustomer: true,
            onUpdateAreFinancialsVisibleToCustomer: emptyFunction,
            skipTermsAndConditions: false,
            skipPrivacyPolicy: false
        }
    },

    getInitialState: function () {
        return {
            isPotentialAlumni: document.cookie.match(/^(.*;)?\s*AlumniDiscount\s*=\s*[^;]+(.*)?$/)
        }
    },

    updateTermsAndConditions: function (e) {
        this.props.onUpdateTermsAndConditions(e.target.checked);
    },

    updatePrivacyPolicy: function(e) {
        this.props.onUpdatePrivacyPolicy(e.target.checked);
    },

    render: function () {
        var marketing = (
            <div className="customer-details-checkbox-container">
                <label>
                    <input type="checkbox" name="marketing" onChange={this.props.onUpdateIsOptedInToMarketing} checked={this.props.isOptedInToMarketing} />
                    <div className="checkbox-info">
                        <p>{this.props.isWebAdminUser || this.props.agentId
                            ? global.OraWidgets.Options.checkoutMarketingOptInTextAdmin
                            : <span>{global.OraWidgets.Options.checkoutMarketingOptInText}</span>}</p>
                    </div>
                </label>
            </div>
        );

        var termsAndConditions = false;
        var privacyPolicy = false;
        if (!this.props.isWebAdminUser && !this.props.agentId) {
            if (this.props.termsAndConditions === false) {
                termsAndConditions = (
                    <div className="customer-details-checkbox-container">
                        <div className="checkbox-info">
                            <em>Calculating applicable terms and conditions ...</em>
                        </div>
                    </div>
                );
            } else {
                var links = map(this.props.termsAndConditions, function (termsAndConditionsDocument, idx) {
                    var url = WidgetOptions.apiEndpoint() + '/TermsAndConditions/' + termsAndConditionsDocument.DocumentId;
                    var joinText = idx === this.props.termsAndConditions.length - 1 ? '' : ' and the ';
                    return <span key={termsAndConditionsDocument.DocumentId}><a href={url} target="_blank" className="light-link">{termsAndConditionsDocument.Name}</a>{joinText}</span>;
                }.bind(this));
                

                var validation = this.props.errorState
                    && this.props.errorState.termsAndConditionsState
                    && this.props.errorState.termsAndConditionsState === 'invalid'
                    ? <div className="error">{isArray(this.props.errorState.termsAndConditionsErrors) ? (this.props.errorState.termsAndConditionsErrors.length ? this.props.errorState.termsAndConditionsErrors[0] : '') : this.props.errorState.termsAndConditionsErrors}</div>
                    : false;
                if (!this.props.skipPrivacyPolicy) {
                    privacyPolicy =(
                        <div className="customer-details-checkbox-container">
                            <label>
                                <input type="checkbox" name="terms" onChange={this.updatePrivacyPolicy} checked={this.props.hasAcceptedPrivacyPolicy} />
                                <div className="checkbox-info">
                                    <div className="" dangerouslySetInnerHTML={{ __html: global.OraWidgets.Options.checkoutPrivacyPolicy }}></div>
                                    {links.length ? false : <div className=""><p>{validation}</p></div>}
                                </div>
                            </label>
                        </div>
                    );
                }
                
                if (links.length && !this.props.skipTermsAndConditions) {
                    termsAndConditions = (
                        <div className="customer-details-checkbox-container">
                            <label>
                                <input type="checkbox" name="terms" onClick={this.updateTermsAndConditions} checked={this.props.hasAcceptedTermsAndConditions} />
                                <div className="checkbox-info">
                                    <p>I accept the {links}</p>
                                    {validation}
                                </div>
                            </label>
                        </div>
                    );
                }
            }
        }

        var thirdParty = [];
        if (this.props.isWebAdminUser) {
            thirdParty.push(
                <div className="customer-details-checkbox-container">
                    <label>
                        <input type="checkbox" name="terms" onChange={this.props.onUpdateOverrideAgeConstraints} checked={this.props.hasOverrideAgeConstraints} />
                        <div className="checkbox-info">
                            <span>Override Age Constraints?</span>
                        </div>
                    </label>
                </div>
            );
        }

        if (this.props.isWebAdminUser) {
            thirdParty.push(<AgentForm
                isWebAdminUser={this.props.isWebAdminUser}
                isAgentBooking={this.props.isAgentBooking}
                onUpdateIsAgentBooking={this.props.onUpdateIsAgentBooking}
                agentId={this.props.agentId}
                onUpdateAgentId={this.props.onUpdateAgentId}
                agentName={this.props.agentName}
                onUpdateAgentName={this.props.onUpdateAgentName}
                onUpdateAreFinancialsVisibleToCustomer={this.props.onUpdateAreFinancialsVisibleToCustomer}
                areFinancialsVisibleToCustomer={this.props.areFinancialsVisibleToCustomer} />);
        } else if (this.props.agentId) {
            //TODO - make this a component
            thirdParty.push(
                <div className="customer-details-checkbox-container">
                    <label>
                        <input type="checkbox" onChange={this.props.onUpdateAreFinancialsVisibleToCustomer} checked={this.props.areFinancialsVisibleToCustomer} />
                        <div className="checkbox-info">
                            <span>If you, {UserStore.getUser().Name}, are not the customer on this booking, would you like the customer to be able to see the financial details (total fees, invoices, outstanding balance etc)?</span>
                        </div>
                    </label>
                </div>);
        }
        
        var alumniMessage = "It looks like you've booked with us before. Your Alumni discount will be applied once you click 'Pay' and will appear in the Portal. Any problems just get in contact with us.";

        return (
            <div>
                {
                    this.props.showCustomerFields
                        ?
                        <div className="checkout-step-header row">
                            <div className="col col-md-6">
                                <h3 className="checkout-step-header-title">2. Customer Details</h3>
                            </div>
                        </div>
                        : false
                }
                {this.state.isPotentialAlumni ? (<p>{alumniMessage}</p>) : false}
                <CustomerForm
                    showCustomerFields={this.props.showCustomerFields}
                    countries={this.props.countries}
                    courses={this.props.courses}
                    customer={this.props.customer}
                    isThirdParty={this.props.isWebAdminUser}
                    errorState={this.props.errorState}
                    onUpdate={this.props.onUpdate} />
                {
                    thirdParty.length
                        ? <div><hr /><div className="customer-details-checkboxes checkout-slim">{thirdParty}</div><hr /></div>
                        : false
                }
                <div className="customer-details-checkboxes checkout-slim">
                    {marketing}
                    {privacyPolicy}
                    {termsAndConditions}
                </div>
        </div>);
    }
});

export default CheckoutCustomer;