import options from './WidgetOptions.js';
import http from './Http.js';

export default {
    makeReservation: function (customer, lineItems, isOptingOutOfMarketing, acceptedTermsAndConditions, isOraReservation, overrideAgeConstraints, isAgentBooking, agentId, agentName, financesVisibleToCustomer, sendPaymentEmail, isOnlyPayingDeposit, basketIdentifier) {
        var data = {
            title: customer.Title,
            firstName: customer.FirstName,
            lastName: customer.LastName,
            email: customer.Email,
            mobile: customer.Mobile,
            country: customer.Country,
            billingAddressLine1: customer.BillingLine1,
            billingAddressLine2: customer.BillingLine2,
            billingCity: customer.BillingCity,
            billingCounty: customer.BillingCounty,
            billingCountry: customer.BillingCountry,
            billingPostCode : customer.BillingPostcode,
            lineItems: lineItems,
            acceptedTermsAndConditions: [],
            IsOptingOutOfMarketing: isOptingOutOfMarketing,
            AcceptedTermsAndConditions: acceptedTermsAndConditions,
            IsOraReservation: isOraReservation,
            OverrideAgeConstraints: isOraReservation && overrideAgeConstraints,
            IsAgentBooking: isAgentBooking,
            SendPaymentEmail: sendPaymentEmail,
            IsOnlyPayingDeposit: isOnlyPayingDeposit,
            BasketIdentifier: basketIdentifier
        };

        if (isAgentBooking) {
            if (agentId) {
                data.AgentId = agentId;
            } else {
                data.AgentName = agentName;
            }

            data.FinancesVisibleToCustomer = financesVisibleToCustomer;
        }

        return http.post(options.apiEndpoint() + '/Reservation', data);
    },

    completeBooking: function(reservationId, sendPaymentEmail) {
        return http.post(options.apiEndpoint() + '/Order', {
            Reservation: { ReservationId: reservationId },
            SendPaymentEmail: !!sendPaymentEmail,
            IsOraOrder: true,
            IsDraftOrder: true
        });
    },

    getPaymentIntent: function (reservationId, paymentIntentId, orderId) {
        if (!!orderId) {
            return http.get(options.apiEndpoint() + '/Flywire/PaymentIntent?orderId=' + orderId + '&paymentIntentId=' + paymentIntentId);
        } else {
            return http.get(options.apiEndpoint() + '/Flywire/PaymentIntent?reservationId=' + reservationId + '&paymentIntentId=' + paymentIntentId);
        }
    },

    createPaymentIntentForOrder: function (orderId, transactionIds) {
        return http.post(options.apiEndpoint() + '/Order/' + orderId + '/PaymentIntent',
            {
                Ids: transactionIds
            });
    }
};