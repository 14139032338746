import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import BasketStore from '../stores/BasketStore.js';
import BasketService from '../utilities/BasketService.js';

function getOptions(lineItem) {
    return BasketService.getUpsellOptions(lineItem || BasketStore.getCourses());
}

function error(response) {
    var responseJson = response.responseJSON;
    if (!responseJson) {
        try {
            responseJson = jQuery.parseJSON(response.responseText);
        } catch (e) {
            responseJson = { Message: 'There was an unknown error: ' + response.responseText };
        }
    }
    Dispatcher.dispatch({
        type: ActionTypes.SEND_UPSELL_REQUEST_FAILURE,
        response: responseJson
    });
}

export default {
    getOptions: function (lineItem) {
        // issue the request to the api for making a reservation
        Dispatcher.dispatch({
            type: ActionTypes.SEND_UPSELL_REQUEST_BEGIN
        });

        getOptions({ "dummy": lineItem })
            .done(function (options) {
                Dispatcher.dispatch({
                    type: ActionTypes.SEND_UPSELL_REQUEST_SUCCESS,
                    options: { itemId: lineItem.ItemId, data: options }
                });
            })
            .fail(error);
    }
};