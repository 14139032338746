import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import create from 'lodash/create';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isarray';
import Store from './Store.js';
import uri from 'urijs';
import query from '../utilities/QueryStringParser.js';

var multipleFilters = ['selectedRegionIds', 'selectedCourseTypeIds'];
var state = {};
var courseTypes = [];
var sites = [];

// handle initial load and setting filters from the url
maybeAddFilterFromUrl('subject');
maybeAddFilterFromUrl('regionid', 'selectedRegionIds', true);
maybeAddFilterFromUrl('age', 'selectedAge');
maybeAddFilterFromUrl('coursetypeid', 'selectedCourseTypeIds', true);
maybeAddFilterFromUrl('month', 'selectedMonth');

function maybeAddFilterFromUrl(queryName, stateKey, isNumeric) {
    stateKey = stateKey || queryName;
    if (query.hasOwnProperty(queryName)) {
        var value = query[queryName];
        if (indexOf(multipleFilters, stateKey) > -1) {
            if (isArray(value)) {
                if (isNumeric) {
                    for (var i = 0; i < value.length; i++) {
                        value[i] = value[i] * 1.0;
                    }
                }

                state[stateKey] = value;
            } else {
                if (isNumeric) {
                    value = value * 1.0;
                }

                state[stateKey] = [value];
            }
        } else {
            if (isNumeric) {
                value = value * 1.0;
            }

            state[stateKey] = value;
        }
    }
}

function syncUrl() {
    var lookup = {
        selectedRegionIds: 'regionid',
        selectedAge: 'age',
        selectedCourseTypeIds: 'coursetypeid',
        selectedMonth: 'month',
        subject: 'subject'
    };
    if (window.history) {
        var newUrl = uri(location);
        for (var key in lookup) {
            if (lookup.hasOwnProperty(key)) {
                newUrl.removeSearch(lookup[key]);
            }
        }

        for (var key in state) {
            if (state.hasOwnProperty(key)) {
                var queryName = lookup[key];
                var value = state[key];
                if (isArray(value)) {
                    for (var i = 0; i < value.length; i++) {
                        newUrl.addSearch(queryName, value[i]);
                    }
                } else {
                    newUrl.addSearch(queryName, value);
                }
            }
        }

        window.history.replaceState({}, '', newUrl);
    }
}

function FiltersStore() {
    Store.call(this);
}

FiltersStore.prototype = create(Store.prototype, {
    'constructor': FiltersStore,

    get: function (key) {
        if (state.hasOwnProperty(key)) {
            return state[key];
        }

        return indexOf(multipleFilters, key) > -1 ? [] : null;
    },

    getCourseTypes: function () {
        return courseTypes;
    },

    getSites: function() {
        return sites;
    }
});

var filtersStore = new FiltersStore();
filtersStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.UPDATE_FILTER:
            state[payload.key] = payload.value;
            break;

        case ActionTypes.CLEAR_FILTERS:
            state = {};
            break;

        case ActionTypes.GET_ALL_COURSE_TYPES:
            courseTypes = payload.courseTypes;
            break;

        case ActionTypes.GET_ALL_SITES:
            sites = payload.sites;
            break;

        default:
            return;
    }

    syncUrl();
    filtersStore.emitChange();
});

export default filtersStore;