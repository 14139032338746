import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import map from 'lodash/map';

const circleBaseCss = " before:w-[0.85rem] before:h-[0.85rem] before:z-20 before:rounded-[50%] before:transition-all before:ease-linear before:content-[''] ";
const circleSelectedCss = " before:scale-125 before:bg-navy-700 ";
const circleFutureCss = " before:bg-white ";
const circleCompletedCss = " before:bg-navy-700 ";

const textBaseCss = " rounded-0 indent-[-82.5rem] relative m-0 cursor-default border-none bg-transparent px-1.5 pb-0.5 pt-5 text-[#344767] outline-none transition-all ease-linear before:absolute before:top-0 before:left-1/2 before:z-30 before:box-border before:block before:-translate-x-1/2 before:border-2 before:border-solid before:border-current sm:indent-0 ";

const lineBaseCss = " after:top-[5px]  after:absolute after:left-[calc(-50%-13px/2)] after:z-10 after:block after:w-full  after:transition-all after:ease-linear after:content-[''] ";
const lineFutureCss = " after:bg-gray-400 after:h-0.5 ";
const lineCompletedCss = " after:bg-navy-700 after:h-1 ";

var StepIndicator = createReactClass({
    props: {
        props: {
            wizard: PropTypes.object
        },
    },
    render: function() {

        var steps = ['Customer', 'Student', 'Programme', 'Enrol'];
        var currentComponentDisplayName = this.props.wizard.state.CurrentComponentDisplayName;
        var currentStepIdx = currentComponentDisplayName === 'Customer'
            ? 0 
            : currentComponentDisplayName === 'Student' ? 1
            : ['WhatNext', 'Billing', 'Flywire', 'End'].indexOf(currentComponentDisplayName) > -1 ? 3
            : 2; 

        return <div className="grid grid-cols-4 md:mb-4 max-w-3xl mx-auto -mb-[1.5rem] sm:mb-0">
            {map(steps, function (stepName, idx) {
                var circleCss = circleBaseCss;
                var textCss;
                if (currentStepIdx == idx) {
                    circleCss += circleSelectedCss;
                    textCss = ' text-navy-700 font-semibold ';
                } else if (currentStepIdx > idx) {
                    circleCss += circleCompletedCss;
                    textCss = ' text-gray-400 ';
                } else {
                    circleCss += circleFutureCss;
                    textCss = ' text-gray-400 ';
                }

                var lineCss = '';
                if (idx > 0) {
                    lineCss = lineBaseCss;
                    if (currentStepIdx > idx - 1) {
                        lineCss += lineCompletedCss;
                    } else {
                        lineCss += lineFutureCss;
                    }
                }

                var css = circleCss + textBaseCss + lineCss;
                return <button aria-controls={stepName}
                    key={stepName}
                    type="button"
                    className={css}
                    title={stepName}
                    ><span className={textCss}>{stepName}</span></button>
            })}
        </div>;
    }
});

export default StepIndicator;