import keyMirror from "keymirror";

export const DATE_FORMAT = "DD/MM/YYYY";
export const ActionTypes = keyMirror({
  // Sites
  GET_ALL_SITES: null,

  // Courses
  GET_COURSE: null,
  GET_COURSES: null,

  // Course Types
  GET_ALL_COURSE_TYPES: null,

  // Countries
  GET_ALL_COUNTRIES: null,

  // Region Session Groups
  GET_REGION_SESSION_GROUPS: null,

  // Terms and Conditions
  GET_ALL_TERMS_AND_CONDITIONS: null,

  // Agents
  GET_ALL_AGENTS: null,

  /* Availability */
  GET_TIMEZONES: null,
  GET_AVAILABILITY: null,
  GET_COURSE_SUGGESTIONS: null,
  BEGIN_COURSE_SUGGESTIONS: null,
  GET_AGENT_AVAILABILITY: null,
  SELECTION_CHOSEN: null,
  SELECTION_CLEARED: null,
  GET_OPTIONS: null,
  CHOOSE_OPTION: null,
  CLEAR_OPTIONS: null,
  DATES_UPDATED: null,
  SELECT_REGION: null,
  DESELECT_REGION: null,

  /* Basket */
  GET_LINEITEM_STARTED: null,
  GET_LINEITEM_FAILURE: null,
  GET_LINEITEM_OPTIONS_SUCCESS: null,
  GET_LINEITEM_DATA_SUCCESS: null,
  ADD_COURSE: null,
  REMOVE_COURSE: null,
  REPLACE_COURSE: null,
  UPDATE_STUDENT: null,
  UPDATE_CUSTOMER: null,
  CHOOSE_STUDENT: null,
  CHOOSE_CUSTOMER: null,
  LOAD_BASKET: null,
  CLEAR_BASKET: null,
  ADDED_UPSELL: null,
  DISCOUNTS_FETCHED: null,

  // Filters
  UPDATE_FILTER: null,
  CLEAR_FILTERS: null,

  // Search
  SEARCH_BEGIN: null,
  SEARCH_SUCCESS: null,

  // Reservations
  MAKE_RESERVATION_STARTED: null,
  MAKE_RESERVATION_SUCCESS: null,
  MAKE_RESERVATION_FAILURE: null,
  MAKE_BOOKING_STARTED: null,
  MAKE_BOOKING_SUCCESS: null,

  // Enquiries
  BEGIN_ENQUIRY: null,
  SEND_ENQUIRY_START: null,
  SEND_ENQUIRY_SENT: null,

  // upsells
  SEND_UPSELL_REQUEST_BEGIN: null,
  SEND_UPSELL_REQUEST_SUCCESS: null,
  SEND_UPSELL_REQUEST_FAILURE: null,

  // USERS
  WHO_AM_I_SUCCESS: null,

  // Payment
  SEND_PAYMENT_STARTED: null,
  SEND_PAYMENT_SUCCESS: null,
  SEND_PAYMENT_FAILURE: null,

  POLL_PAYMENT_START: null,
  POLL_PAYMENT_FINISHED: null,

  CREATE_PAYMENT_INTENT_SUCCESS: null,
});
