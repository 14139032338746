import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import extend from 'lodash/extend';
import create from 'lodash/create';
import Store from './Store.js';

var courseAvailability = {};
var courseSuggestions = [];
var agentAvailability = {};
var availabilitySelections = {};
var dateSelections = {};
var enquiryRequests = {};
var regionSessionGroups = {};

function AvailabilityStore() {
    Store.call(this);
}

AvailabilityStore.prototype = create(Store.prototype, {
    'constructor': AvailabilityStore,

    getCourseAvailability: function (courseId, agentId) {
        var key = courseId + '-' + agentId;
        if (courseAvailability.hasOwnProperty(key)) {
            return courseAvailability[key];
        }

        return false;
    },

    getCourseSuggestions: function () {
        return courseSuggestions;
    },

    getAgentAvailability: function (courseId, agentId) {
        var key = courseId + '-' + agentId;
        if (agentAvailability.hasOwnProperty(key)) {
            return agentAvailability[key];
        }

        return false;
    },

    getSelection: function(context) {
        var key = JSON.stringify(context);
        if (availabilitySelections.hasOwnProperty(key)) {
            return availabilitySelections[key];
        }

        return false;
    },

    getDatesState: function(context) {
        var key = JSON.stringify(context);
        if (dateSelections.hasOwnProperty(key)) {
            return dateSelections[key];
        }

        return {
            date: '',
            weeks: undefined,
            hours: undefined
        };
    },

    getEnquiryRequest: function(context) {
        var key = JSON.stringify(context);
        if (enquiryRequests.hasOwnProperty(key)) {
            return enquiryRequests[key];
        }

        return false;
    },

    getRegionSessionGroups: function() {
        return regionSessionGroups;
    }
});

var availabilityStore = new AvailabilityStore();
availabilityStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.GET_AVAILABILITY:
            var courseAvailabilityKey = payload.courseId + '-' + payload.agentId;
            courseAvailability[courseAvailabilityKey] = payload.availability;
            break;

        case ActionTypes.GET_COURSE_SUGGESTIONS:
            courseSuggestions = payload.suggestions;
            break;

        case ActionTypes.BEGIN_COURSE_SUGGESTIONS:
            courseSuggestions = [];
            break;

        case ActionTypes.GET_AGENT_AVAILABILITY:
            var agentAvailabilityKey = payload.courseId + '-' + payload.agentId;
            agentAvailability[agentAvailabilityKey] = payload.agentAvailability;
            break;

        case ActionTypes.SEARCH_SUCCESS:
            // TODO think about caching results from the search results in here (as they may be the same)
            break;

        case ActionTypes.SELECTION_CHOSEN:
            availabilitySelections[JSON.stringify(payload.context)] = {
                course: payload.course,
                intensity: payload.intensity,
                dates: payload.dates
            };
            break;

        case ActionTypes.SELECTION_CLEARED:
            var key = JSON.stringify(payload.context);
            delete availabilitySelections[key];
            break;

        case ActionTypes.DATES_UPDATED:
            dateSelections[JSON.stringify(payload.context)] = {
                date: payload.date,
                weeks: payload.weeks,
                hours: payload.hours
            };
            break;

        case ActionTypes.BEGIN_ENQUIRY:
            enquiryRequests[JSON.stringify(payload.context)] = payload;
            break;

        case ActionTypes.SEND_ENQUIRY_START:
            enquiryRequests[JSON.stringify(payload.context)].isInTransit = true;
            break;

        case ActionTypes.SEND_ENQUIRY_SENT:
            enquiryRequests[JSON.stringify(payload.context)].isSent = true;
            break;

        case ActionTypes.GET_REGION_SESSION_GROUPS:
            regionSessionGroups = payload.regionSessionGroups;
            break;

        default:
            return;
    }

    availabilityStore.emitChange(payload.context);
});

export default availabilityStore;