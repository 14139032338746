import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Loading from '../common/loading.jsx';
import TermsAndConditionsCreators from '../../actions/TermsAndConditionsCreators.js';
import FlywireForm from '../flywire/flywire-form.jsx';
import baseFlywireContainer from '../common/BaseCheckoutFlywireContainer.js';
import CountdownTimer from '../flywire/countdown-timer.jsx';
import BasketStore from '../../stores/BasketStore.js';
import assign from 'lodash/assign';

var Flywire = createReactClass(assign({}, baseFlywireContainer, {
    displayName: 'Flywire',
    props: {
        wizard: PropTypes.object
    },
    
    getInitialState: function () {
        return {
            customer: BasketStore.getCustomer(),
            isReserving: BasketStore.isReserving(),
            reservationFailureResponse: BasketStore.getReservationFailureResponse(),
            reservation: BasketStore.getReservation(),
            bookingCompleteUrl: this.props.bookingCompleteUrl,
            
            isPollingPayment: false,
            hasPaymentSucceeded: false,
            hasPaymentFailed: false,
            fatalPaymentError: false,

            hasClickedSendPayment: false,

            shouldClosePaymentModal: false
        };
    },

    returnToStepBeforePayment: function (errorMessage) {
        this.props.wizard.set({ PaymentError: errorMessage });
        this.props.wizard.previousQuestion();
    },

    onCountdownComplete: function () {
        this.setState({ shouldClosePaymentModal: true });
        this.returnToStepBeforePayment('Your reservation has expired. Please try again.');
    },

    onPaymentCancelled: function() {
        this.props.wizard.previousQuestion();
    },

    onInvalidInput: function (errors) {
        if (!errors || !errors.length) {
            errors = [{ msg: 'There was an unexpected error. Please refresh the page and try again' }];
        }

        this.props.wizard.set({ PaymentError: errors[0].msg });
        this.props.wizard.previousQuestion();
    },

    componentWillMount: function() {
        if (!this.state.isReserving && !this.state.reservation) {
            this.props.wizard.previousQuestion();
        }
    },

    componentDidMount: function () {
        BasketStore.addChangeListener(this.changeBasket);
        BasketStore.addChangeListener(this.handleBasketChangedFlywire);
        TermsAndConditionsCreators.getAll();
    },

    componentWillUnmount: function () {
        BasketStore.removeChangeListener(this.changeBasket);
        BasketStore.removeChangeListener(this.handleBasketChangedFlywire);
    },

    changeBasket: function () {
        var reservationFailureResponse = BasketStore.getReservationFailureResponse();
        var reservation = BasketStore.getReservation();

        var data = {
            customer: BasketStore.getCustomer(),
            isReserving: BasketStore.isReserving(),
            reservationFailureResponse: reservationFailureResponse,
            reservation: reservation
        };

        if (!this.state.reservationFailureResponse && reservationFailureResponse) {
            this.props.wizard.previousQuestion();
        }

        this.setState(data);
    },
    
    render: function () {
        if (!this.state.isReserving && !this.state.reservation) {
            // happens when the state is reserialized from localstorage
            // our componentWillMount takes us back to the previous question but the render still executes
            return <div></div>;
        }

        if (this.state.hasPaymentSucceeded) {
            return <Loading loadingMessage="Thank you, your booking has been successful. Please wait while we transfer you to the ORA Portal, where you can manage your booking."></Loading>;
        }

        if (this.state.isPollingPayment) {
            return <Loading loadingMessage="Please wait while Flywire processes your payment..."></Loading>;
        }

        if (this.state.isReserving) {
            return <Loading loadingMessage="Please wait while we reserve your beds and class spaces..." />;
        }

        var timer = <CountdownTimer
            expiryTime={this.state.reservation.ReservedUntil}
            onCountdownComplete={this.onCountdownComplete} />;
        var amountToPay = this.props.wizard.state.hasClickedPayDeposit
            ? this.state.reservation.Deposit
            : this.state.reservation.Total;

        return <div className="payment-container" ref={this.scrollToTop}>
            <div className="checkout-slim">
                <div className="payment-instructions">
                    {timer && <p className="payment-instruction-line"><span className="text-bold">Reservation held for:</span> {timer}</p>}
                </div>
                <FlywireForm
                    amountToPay={amountToPay * 100} //Flywire works in Pence
                    firstName={this.state.customer.FirstName}
                    lastName={this.state.customer.LastName}
                    email={this.state.customer.Email}
                    mobile={this.state.customer.Mobile}
                    billingLine1={this.state.customer.BillingLine1}
                    billingLine2={this.state.customer.BillingLine2}
                    billingCity={this.state.customer.BillingCity}
                    billingPostCode={this.state.customer.BillingPostcode}
                    callbackId={this.state.reservation.PaymentIntentId}
                    country={this.state.customer.BillingCountry}
                    shouldCloseModal={this.state.shouldClosePaymentModal}
                    flywireModalClosedHandler={this.returnToStepBeforePayment}
                    onInvalidInput={this.onInvalidInput}
                />
            </div>
        </div>;
    },

    isValid: function() {
        return true;
    }
}));

export default Flywire;