import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

var Loading = createReactClass({
    render: function () {
        var cubeStyle = this.props.useDarkTheme ? { backgroundColor: 'white' } : {};
        var cubes = [];
        for (var i = 1; i <= 9; i++) {
            cubes.push(<div className={'sk-cube sk-cube' + i} style={cubeStyle}></div>);
        }

        return <div>
                <div>
                    <div className="sk-cube-grid">
                        {cubes}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '-90px', marginBottom: '90px' }}>{this.props.loadingMessage || 'Loading...'}</div>
                </div>
                <div>{this.props.children}</div>
            </div>;
    }
});

export default Loading;