// [ prodPk, name, iso2, iso3 ]
var countries = [
[1, "Afghanistan", "AF", "AFG"],
[2, "Albania", "AL", "ALB"],
[3, "Algeria", "DZ", "DZA"],
[4, "American Samoa", "AS", "ASM"],
[5, "Andorra", "AD", "AND"],
[6, "Angola", "AO", "AGO"],
[7, "Anguilla", "AI", "AIA"],
[8, "Antarctica", "AQ", "ATA"],
[9, "Antigua and Barbuda", "AG", "ATG"],
[10, "Argentina", "AR", "ARG"],
[11, "Armenia", "AM", "ARM"],
[12, "Aruba", "AW", "ABW"],
[13, "Australia", "AU", "AUS"],
[14, "Austria", "AT", "AUT"],
[15, "Azerbaijan", "AZ", "AZE"],
[16, "Bahamas", "BS", "BHS"],
[17, "Bahrain", "BH", "BHR"],
[18, "Bangladesh", "BD", "BGD"],
[19, "Barbados", "BB", "BRB"],
[20, "Belarus", "BY", "BLR"],
[21, "Belgium", "BE", "BEL"],
[22, "Belize", "BZ", "BLZ"],
[23, "Benin", "BJ", "BEN"],
[24, "Bermuda", "BM", "BMU"],
[25, "Bhutan", "BT", "BTN"],
[26, "Bolivia", "BO", "BOL"],
[27, "Bosnia and Herzegovina", "BA", "BIH"],
[28, "Botswana", "BW", "BWA"],
[29, "Bouvet Island", "BV", "BVT"],
[30, "Brazil", "BR", "BRA"],
[31, "British Indian Ocean Territory", "IO", "IOT"],
[32, "Brunei Darussalam", "BN", "BRN"],
[33, "Bulgaria", "BG", "BGR"],
[34, "Burkina Faso", "BF", "BFA"],
[35, "Burundi", "BI", "BDI"],
[36, "Cambodia", "KH", "KHM"],
[37, "Cameroon", "CM", "CMR"],
[38, "Canada", "CA", "CAN"],
[39, "Cape Verde", "CV", "CPV"],
[40, "Cayman Islands", "KY", "CYM"],
[41, "Central African Republic", "CF", "CAF"],
[42, "Chad", "TD", "TCD"],
[43, "Chile", "CL", "CHL"],
[44, "China", "CN", "CHN"],
[45, "Christmas Island", "CX", "CXR"],
[46, "Cocos (Keeling) Islands", "CC", "CCK"],
[47, "Colombia", "CO", "COL"],
[48, "Comoros", "KM", "COM"],
[49, "Congo", "CG", "COG"],
[50, "Cook Islands", "CK", "COK"],
[51, "Costa Rica", "CR", "CRI"],
[52, "Cote D'Ivoire", "CI", "CIV"],
[53, "Croatia", "HR", "HRV"],
[54, "Cuba", "CU", "CUB"],
[55, "Cyprus", "CY", "CYP"],
[56, "Czech Republic", "CZ", "CZE"],
[57, "Denmark", "DK", "DNK"],
[58, "Djibouti", "DJ", "DJI"],
[59, "Dominica", "DM", "DMA"],
[60, "Dominican Republic", "DO", "DOM"],
[61, "East Timor", "TP", "TMP"],
[62, "Ecuador", "EC", "ECU"],
[63, "Egypt", "EG", "EGY"],
[64, "El Salvador", "SV", "SLV"],
[65, "Equatorial Guinea", "GQ", "GNQ"],
[66, "Eritrea", "ER", "ERI"],
[67, "Estonia", "EE", "EST"],
[68, "Ethiopia", "ET", "ETH"],
[69, "Falkland Islands (Malvinas)", "FK", "FLK"],
[70, "Faroe Islands", "FO", "FRO"],
[71, "Fiji", "FJ", "FJI"],
[72, "Finland", "FI", "FIN"],
[73, "France", "FR", "FRA"],
[74, "France, Metropolitan", "FX", "FXX"],
[75, "French Guiana", "GF", "GUF"],
[76, "French Polynesia", "PF", "PYF"],
[77, "French Southern Territories", "TF", "ATF"],
[78, "Gabon", "GA", "GAB"],
[79, "Gambia", "GM", "GMB"],
[80, "Georgia", "GE", "GEO"],
[81, "Germany", "DE", "DEU"],
[82, "Ghana", "GH", "GHA"],
[83, "Gibraltar", "GI", "GIB"],
[84, "Greece", "GR", "GRC"],
[85, "Greenland", "GL", "GRL"],
[86, "Grenada", "GD", "GRD"],
[87, "Guadeloupe", "GP", "GLP"],
[88, "Guam", "GU", "GUM"],
[89, "Guatemala", "GT", "GTM"],
[90, "Guinea", "GN", "GIN"],
[91, "Guinea-bissau", "GW", "GNB"],
[92, "Guyana", "GY", "GUY"],
[93, "Haiti", "HT", "HTI"],
[94, "Heard and Mc Donald Islands", "HM", "HMD"],
[95, "Honduras", "HN", "HND"],
[96, "Hong Kong", "HK", "HKG"],
[97, "Hungary", "HU", "HUN"],
[98, "Iceland", "IS", "ISL"],
[99, "India", "IN", "IND"],
[100, "Indonesia", "ID", "IDN"],
[101, "Iran (Islamic Republic of)", "IR", "IRN"],
[102, "Iraq", "IQ", "IRQ"],
[103, "Ireland", "IE", "IRL"],
[104, "Israel", "IL", "ISR"],
[105, "Italy", "IT", "ITA"],
[106, "Jamaica", "JM", "JAM"],
[107, "Japan", "JP", "JPN"],
[108, "Jordan", "JO", "JOR"],
[109, "Kazakhstan", "KZ", "KAZ"],
[110, "Kenya", "KE", "KEN"],
[111, "Kiribati", "KI", "KIR"],
[112, "Korea, Democratic People's Republic of", "KP", "PRK"],
[113, "Korea, Republic of", "KR", "KOR"],
[114, "Kuwait", "KW", "KWT"],
[115, "Kyrgyzstan", "KG", "KGZ"],
[116, "Lao People's Democratic Republic", "LA", "LAO"],
[117, "Latvia", "LV", "LVA"],
[118, "Lebanon", "LB", "LBN"],
[119, "Lesotho", "LS", "LSO"],
[120, "Liberia", "LR", "LBR"],
[121, "Libya", "LY", "LBY"],
[122, "Liechtenstein", "LI", "LIE"],
[123, "Lithuania", "LT", "LTU"],
[124, "Luxembourg", "LU", "LUX"],
[125, "Macau", "MO", "MAC"],
[126, "Macedonia", "MK", "MKD"],
[127, "Madagascar", "MG", "MDG"],
[128, "Malawi", "MW", "MWI"],
[129, "Malaysia", "MY", "MYS"],
[130, "Maldives", "MV", "MDV"],
[131, "Mali", "ML", "MLI"],
[132, "Malta", "MT", "MLT"],
[133, "Marshall Islands", "MH", "MHL"],
[134, "Martinique", "MQ", "MTQ"],
[135, "Mauritania", "MR", "MRT"],
[136, "Mauritius", "MU", "MUS"],
[137, "Mayotte", "YT", "MYT"],
[138, "Mexico", "MX", "MEX"],
[139, "Micronesia, Federated States of", "FM", "FSM"],
[140, "Moldova, Republic of", "MD", "MDA"],
[141, "Monaco", "MC", "MCO"],
[142, "Mongolia", "MN", "MNG"],
[143, "Montserrat", "MS", "MSR"],
[144, "Morocco", "MA", "MAR"],
[145, "Mozambique", "MZ", "MOZ"],
[146, "Myanmar", "MM", "MMR"],
[147, "Namibia", "NA", "NAM"],
[148, "Nauru", "NR", "NRU"],
[149, "Nepal", "NP", "NPL"],
[150, "Netherlands", "NL", "NLD"],
[151, "Netherlands Antilles", "AN", "ANT"],
[152, "New Caledonia", "NC", "NCL"],
[153, "New Zealand", "NZ", "NZL"],
[154, "Nicaragua", "NI", "NIC"],
[155, "Niger", "NE", "NER"],
[156, "Nigeria", "NG", "NGA"],
[157, "Niue", "NU", "NIU"],
[158, "Norfolk Island", "NF", "NFK"],
[159, "Northern Mariana Islands", "MP", "MNP"],
[160, "Norway", "NO", "NOR"],
[161, "Oman", "OM", "OMN"],
[162, "Pakistan", "PK", "PAK"],
[163, "Palau", "PW", "PLW"],
[164, "Panama", "PA", "PAN"],
[165, "Papua New Guinea", "PG", "PNG"],
[166, "Paraguay", "PY", "PRY"],
[167, "Peru", "PE", "PER"],
[168, "Philippines", "PH", "PHL"],
[169, "Pitcairn", "PN", "PCN"],
[170, "Poland", "PL", "POL"],
[171, "Portugal", "PT", "PRT"],
[172, "Puerto Rico", "PR", "PRI"],
[173, "Qatar", "QA", "QAT"],
[174, "Reunion", "RE", "REU"],
[175, "Romania", "RO", "ROM"],
[176, "Russian Federation", "RU", "RUS"],
[177, "Rwanda", "RW", "RWA"],
[178, "Saint Kitts and Nevis", "KN", "KNA"],
[179, "Saint Lucia", "LC", "LCA"],
[180, "Saint Vincent and the Grenadines", "VC", "VCT"],
[181, "Samoa", "WS", "WSM"],
[182, "San Marino", "SM", "SMR"],
[183, "Sao Tome and Principe", "ST", "STP"],
[184, "Saudi Arabia", "SA", "SAU"],
[185, "Senegal", "SN", "SEN"],
[186, "Seychelles", "SC", "SYC"],
[187, "Sierra Leone", "SL", "SLE"],
[188, "Singapore", "SG", "SGP"],
[189, "Slovakia (Slovak Republic)", "SK", "SVK"],
[190, "Slovenia", "SI", "SVN"],
[191, "Solomon Islands", "SB", "SLB"],
[192, "Somalia", "SO", "SOM"],
[193, "South Africa", "ZA", "ZAF"],
[194, "South Georgia and the South Sandwich Islands", "GS", "SGS"],
[195, "Spain", "ES", "ESP"],
[196, "Sri Lanka", "LK", "LKA"],
[197, "St. Helena", "SH", "SHN"],
[198, "St. Pierre and Miquelon", "PM", "SPM"],
[199, "Sudan", "SD", "SDN"],
[200, "Suriname", "SR", "SUR"],
[201, "Svalbard and Jan Mayen Islands", "SJ", "SJM"],
[202, "Swaziland", "SZ", "SWZ"],
[203, "Sweden", "SE", "SWE"],
[204, "Switzerland", "CH", "CHE"],
[205, "Syrian Arab Republic", "SY", "SYR"],
[206, "Taiwan", "TW", "TWN"],
[207, "Tajikistan", "TJ", "TJK"],
[208, "Tanzania, United Republic of", "TZ", "TZA"],
[209, "Thailand", "TH", "THA"],
[210, "Togo", "TG", "TGO"],
[211, "Tokelau", "TK", "TKL"],
[212, "Tonga", "TO", "TON"],
[213, "Trinidad and Tobago", "TT", "TTO"],
[214, "Tunisia", "TN", "TUN"],
[215, "Turkey", "TR", "TUR"],
[216, "Turkmenistan", "TM", "TKM"],
[217, "Turks and Caicos Islands", "TC", "TCA"],
[218, "Tuvalu", "TV", "TUV"],
[219, "Uganda", "UG", "UGA"],
[220, "Ukraine", "UA", "UKR"],
[221, "United Arab Emirates", "AE", "ARE"],
[222, "United Kingdom", "GB", "GBR"],
[223, "United States", "US", "USA"],
[224, "United States Minor Outlying Islands", "UM", "UMI"],
[225, "Uruguay", "UY", "URY"],
[226, "Uzbekistan", "UZ", "UZB"],
[227, "Vanuatu", "VU", "VUT"],
[228, "Vatican City State (Holy See)", "VA", "VAT"],
[229, "Venezuela", "VE", "VEN"],
[230, "Viet Nam", "VN", "VNM"],
[231, "Virgin Islands (British)", "VG", "VGB"],
[232, "Virgin Islands (U.S.)", "VI", "VIR"],
[233, "Wallis and Futuna Islands", "WF", "WLF"],
[234, "Western Sahara", "EH", "ESH"],
[235, "Yemen", "YE", "YEM"],
[236, "Yugoslavia", "YU", "YUG"],
[237, "Zaire", "ZR", "ZAR"],
[238, "Zambia", "ZM", "ZMB"],
[239, "Zimbabwe", "ZW", "ZWE"],
[240, "Serbia", "RS", "SRB"],
[241, "Palestine", "PS", "PST"],
[242, "Montenegro", "ME", "MNE"]
];

// e.g { gb: {CountryId: 222, Name : "Great Britain", Iso2: etc }} ...
var countriesDictionary = countries.map(function(c, i) {
         return { CountryId: c[0], Name: c[1], Iso2: c[2].toLowerCase(), Iso3: c[3].toLowerCase() }
    })
    .reduce(function(acc, country) {
        acc[country.Iso2] = country;
        return acc;
    }, {});

export default countriesDictionary;