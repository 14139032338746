import dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import create from 'lodash/create';
import map from 'lodash/map';
import Store from './Store.js';

var timeZonesLookup = {};

function TimeZonesStore() {
    Store.call(this);
};

TimeZonesStore.prototype = create(Store.prototype, {
    'constructor': TimeZonesStore,

    getTimeZones: function (course, sessions) {
        var key = this.getKey(course, sessions);
        if (timeZonesLookup.hasOwnProperty(key)) {
            return timeZonesLookup[key];
        }

        return [];
    },
    
    getKey: function (course, sessions) {
        var key = course.CourseId + '|';
        key += map(sessions, 'SessionId').join(',');
        return key;
    }
});

var timeZonesStore = new TimeZonesStore();
timeZonesStore.dispatchToken = dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.GET_TIMEZONES:
            // take the list of course availabilities and map in to a sensible structure for displaying options
            var key = timeZonesStore.getKey(
                payload.course,
                payload.sessions);
            timeZonesLookup[key] = payload.timeZones;

            break;
        
        default:
            return;
    }

    timeZonesStore.emitChange();
});

export default timeZonesStore;