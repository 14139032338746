import options from './WidgetOptions.js';
import http from './Http.js';

export default {
    getById: function(courseId) {
        return http.get(options.apiEndpoint() + '/Course/' + courseId);
    },

    getCourses: function() {
        return http.get(options.apiEndpoint() + global.OraWidgets.Options.coursesPath);
    }
};