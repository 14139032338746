import options from './WidgetOptions.js';
import http from './Http.js';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import storage from '../utilities/LocalStorage.js';

function resolvedPromise() {
    var dfd = global.jQuery.Deferred();
    dfd.resolve(null); // basket store checks against null as to whether to update basket identifier
    return dfd.promise();
}

function getRequestData(customer, courses, basketIdentifier) {
    var data = {
        CustomerFirstName: customer.FirstName,
        CustomerSurname: customer.LastName,
        CustomerEmailAddress: customer.Email,
        CustomerMobile: customer.Mobile,
        CustomerTitle: customer.Title,
        SerializedBasket: JSON.stringify({
            version: '2.0.0',
            customer: customer,
            courses: courses
        })
    };

    if (basketIdentifier) {
        data.BasketIdentifier = basketIdentifier;
    }

    return data;
}

var lastBasketData = storage.getItem('__ora_widget_lastbasket') || '';
var lastDiscountRequestData = storage.getItem('__ora_widget_lastdiscountrequestdata') || '';

export default {
    getDiscounts: function (customer, courses, basketIdentifier) {
        var coursesAsJson = JSON.stringify(courses);
        if ((lastDiscountRequestData.length === 0 && isEmpty(courses))
            || (coursesAsJson === lastDiscountRequestData)) {
             // no change
            return resolvedPromise();
        }
        
        lastDiscountRequestData = coursesAsJson;
        if (global.localStorage) {
            storage.setItem('__ora_widget_lastdiscountrequestdata', lastDiscountRequestData);
        }

        var requestData = getRequestData(customer, courses, basketIdentifier);
        return http.postQ(options.apiEndpoint() + '/Basket/Discounts', requestData);
    },

    getUpsellOptions: function (courses) {
        var requestData = getRequestData({}, courses);
        return http.post(options.apiEndpoint() + '/Upsell/Options', requestData);
    },

    saveBasket: function (customer, courses, basketId, basketIdentifier) {
        if (lastBasketData.length === 0 && isEmpty(customer) && isEmpty(courses)) {
            // no change
            return resolvedPromise();
        }

        var requestData = getRequestData(customer, courses, basketIdentifier);
        var requestDataAsJson = JSON.stringify(requestData);
        if (requestDataAsJson === lastBasketData) {
            return resolvedPromise();
        }

        lastBasketData = requestDataAsJson;
        if (global.localStorage) {
            storage.setItem('__ora_widget_lastbasket', lastBasketData);
        }

        // MOVED to basket identifier now so no need to worry about basketid as well
        if (basketId) {
            requestData.BasketId = basketId;
        }

        if (global.OraWidgets.Options.isAgentBasket) {
            requestData.IsAgentBasket = true;
        }

        return http.postQ(options.apiEndpoint() + '/Basket', requestData);
    },

    getLineItemData: function (courseId, sessionIds, regionId) {
        var query = 'courseId=' + courseId;
        if (regionId) {
            query += '&regionId=' + regionId;
        }

        query += '&' + map(sessionIds,
            function(sessionId) {
                return 'sessionIds=' + sessionId;
            }).join('&');
        return http.get(options.apiEndpoint() + '/availability/item?' + query);
    }
};