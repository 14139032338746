import isArray from 'lodash/isarray';
import uri from 'urijs';

var search = global.location.search;
var params = {};
if (search) {
    search = search.substring(1);
    var parts = search.split('&');
    for (var i = 0; i < parts.length; i++) {
        var bits = parts[i].split('=');
        var key = bits[0].toLowerCase();
        var value = bits.length > 1 ? tryParseValue(bits[1]) : true;
        if (params.hasOwnProperty(key)) {
            if (isArray(params[key])) {
                params[key].push(value);
            } else {
                var newValue = [params[key]];
                newValue.push(value);
                params[key] = newValue;
            }
        } else {
            params[key] = value;
        }
    }
}

function tryParseValue(value) {
    if (value) {
        switch (value.toLowerCase()) {
            case 'true':
            case 'on':
            case 'yes':
                value = true;
                break;

            case 'false':
            case 'off':
            case 'no':
                value = false;
                break;

            default:
                value = uri.decodeQuery(value);
                break;
        }
    }

    return value;
}

export default params;