import min from 'lodash/min';
import max from 'lodash/max';
import moment from 'moment';
import flatten from 'lodash/flatten';
import { DATE_FORMAT } from '../constants/Constants.js';
import map from 'lodash/map';

function datesFromSessions(sessions) {
    var from = moment.utc(min(sessions, function (session) {
        return moment.utc(session.From).unix();
    }).From);
    var to = moment.utc(max(sessions, function (session) {
        return moment.utc(session.To).unix();
    }).To);

    var dates;
    if (from.format('YY') != to.format('YY')) {
        dates = from.format('Do MMM \'YY') + ' - ' + to.format('Do MMM \'YY');
    }
    else if (from.format('MMM') != to.format('MMM')) {
        dates = from.format('Do MMM') + ' - ' + to.format('Do MMM \'YY');
    }
    else {
        dates = from.format('Do') + ' - ' + to.format('Do MMM \'YY');
    }

    return dates;
}

function datesFromCourseItem(courseItem) {
    var description = '';
    if (courseItem.Course.CourseType.AvailabilityModel === 1) {
        description = moment.utc(courseItem.Options[0].StartDate).format(DATE_FORMAT);
        if (courseItem.Intensity.Weeks && courseItem.Intensity.Hours) {
            description += ' - ' + courseItem.Intensity.Weeks + ' week' + (courseItem.Intensity.Weeks > 1 ? 's' : '') + ', ' +
                courseItem.Intensity.Hours + ' hour' + (courseItem.Intensity.Hours > 1 ? 's' : '');
        } else if (courseItem.Intensity.Weeks) {
            description += ' - ' + courseItem.Intensity.Weeks + ' week' + (courseItem.Intensity.Weeks > 1 ? 's' : '');
        } else if (courseItem.Intensity.Hours) {
            description += ' - ' + courseItem.Intensity.Hours + ' hour' + (courseItem.Intensity.Hours > 1 ? 's' : '');
        }
    } else if (courseItem.Course.CourseType.AvailabilityModel === 0) {
        description = datesFromSessions(map(courseItem.Options, function (option) { return option.Session; }));
    } else {
        if (courseItem.Intensity.Weeks && courseItem.Intensity.Hours) {
            description = courseItem.intensity.Weeks + ' week' + (courseItem.Intensity.Weeks > 1 ? 's' : '') + ', ' +
                courseItem.Intensity.Hours + ' hour' + (courseItem.Intensity.Hours > 1 ? 's' : '');
        } else if (courseItem.Intensity.Weeks) {
            description = courseItem.Intensity.Weeks + ' week' + (courseItem.Intensity.Weeks > 1 ? 's' : '');
        } else if (courseItem.Intensity.Hours) {
            description = courseItem.Intensity.Hours + ' hour' + (courseItem.Intensity.Hours > 1 ? 's' : '');
        }
    }

    return description;
}

function descriptionFromCourseItem(courseItem) {
    var description = datesFromCourseItem(courseItem);
    if (courseItem.Course.HasCourseBreakdown) {
        if (description.length) {
            description += '<br/>';
        }

        description += flatten(map(courseItem.Options, function (option) {
            return map(option.Options, function (slotOption) {
                return slotOption.CourseOption.Name;
            });
        })).join(', ');
    }

    return description;
}

function to2Dp(number, precision) {
    // first bit does rounding
    var multiplier = Math.pow(10, precision + 1),
        wholeNumber = Math.floor(number * multiplier);
    var num = Math.round(wholeNumber / 10) * 10 / multiplier;

    // this bit converts to string with guaranteed 2dp
    return num.toFixed(2).toString();
}

function formatWithCommas(x) {
    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript

    var str = x.toString();
    var bits = str.split('.');
    if (bits.length === 2) {
        var formatted = maybeFormat2Dp(str);
        var formattedBits = formatted.split('.');
        if (formattedBits.length === 1) {
            return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return formattedBits[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + formattedBits[1];
    }

    return maybeFormat2Dp(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

function maybeFormat2Dp(str) {
    var bits = str.split('.');
    if (bits.length === 2) {
        return Number(str).toFixed(2);
    }

    return str;
}

export default {
    datesFromSessions: datesFromSessions,
    datesFromCourseItem: datesFromCourseItem,
    descriptionFromCourseItem: descriptionFromCourseItem,
    to2Dp: to2Dp,
    formatWithCommas: formatWithCommas,
    maybeFormat2Dp: maybeFormat2Dp
};