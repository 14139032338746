import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import BasketStore from '../../stores/BasketStore.js';
import partial from 'lodash/partial';
import ActionCreators from '../../actions/CheckoutActionCreators.js';
import map from 'lodash/map';
import Loading from '../common/loading.jsx';

var FlyWireForm = createReactClass({
    displayName: "FlywireForm",
    propTypes: {
        destination: PropTypes.string,
        country: PropTypes.string,
        amountToPay: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        mobile: PropTypes.string,
        studentFirstName: PropTypes.string,
        studentLastName: PropTypes.string,
        handleBasketChanged: PropTypes.func,
        billingLine1: PropTypes.string,
        billingLine2: PropTypes.string,
        billingCity: PropTypes.string,
        billingCounty: PropTypes.string,
        billingPostCode: PropTypes.string,
        callbackId: PropTypes.string,
        orderId: PropTypes.number,
        shouldCloseModal: PropTypes.bool,
        flywireModalClosedHandler: PropTypes.func,
        onInvalidInput: PropTypes.func,
        showErrorMessage: PropTypes.bool
    },

    getDefaultProps: function() {
        return {
            destination: global.OraWidgets.Options.defaultFlywireDestination,
            country: 'GB',
            billingLine1: '',
            billingLine2: '',
            billingCity: '',
            billingCounty: '',
            billingPostCode: '',
            shouldCloseModal: false,
            isDraftOrder: false,
            onInvalidInput: function() {},
            showErrorMessage: false
        };
    },

    getInitialState: function() {
        return {
            flywireModal: {},
            showPollingMessage: false,
            showPendingMessage: false,
            showErrorMessage: false
        };
    },

    componentDidMount: function() {
        this.initFlywire();
        this.tryShowPaymentInstructions();
    },

    removeCountdownTimers: function (parentElement) {
        var topLevelElement = !!parentElement ? parentElement : document;

        // remove the countdown timer(s)
        var countdownElements = topLevelElement.getElementsByClassName('payment-instructions');

        if (countdownElements.length < 1) {
            return;
        }

        // remove elements in reverse
        for (var i = countdownElements.length - 1; i >= 0; i--) {
            countdownElements[i].remove();
        }
    },

    closeFlywireModal: function () {
        this.removeCountdownTimers();
        // finally close the modal
        this.state.flywireModal.close(function() {}); // flywire has asked us to pass an empty function for now
    },

    shouldComponentUpdate: function(nextProps, nextState) {
        if (!this.props.shouldCloseModal && nextProps.shouldCloseModal) {
            this.closeFlywireModal();
        }

        return true;
    },

    onPaymentComplete: function (args) { //TODO: maybe move this to the parent component(s) if we need to add "rejected"/"cancelled" messages, or to let them handle the success messages?
        if (args.status === 'success') {
            ActionCreators.beginPolling(this.props.callbackId, this.props.orderId); // this will forward us on as necessary at the end
            this.setState({ showPollingMessage: true });
        } else if (args.status === 'pending') {
            this.setState({ showPendingMessage: true });
        } else {
            this.setState({ showErrorMessage: true });
        }
    },

    onInvalidInput: function(errors) {
        this.closeFlywireModal();
        this.props.onInvalidInput(errors);
    },

    initFlywire: function() {
        var flywireModal = global.FlywirePayment.initiate({
            env: global.OraWidgets.Options.paymentEnvironment,
            recipientCode: this.props.destination,
            amount: (this.props.amountToPay / 100),
            country: this.props.country,
            address: this.props.billingLine1,
            city: this.props.billingCity,
            email: this.props.email,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            phone: this.props.mobile,
            callbackUrl: global.OraWidgets.Options.callBackUrl,
            callbackId: this.props.callbackId,
            read_only: "amount",
            //theme: {
            //    brandColor: "#3498db",
            //    chat: false,
            //    footer: false
            //}
            recipientFields: {
                web_admin_payment_id: this.props.callbackId
            },
            onCompleteCallback: this.onPaymentComplete,
            onCancel: this.props.flywireModalClosedHandler,
            onInvalidInput: this.onInvalidInput
        });

        this.setState({ flywireModal: flywireModal });

        flywireModal.render();
    },

    tryShowPaymentInstructions: function () {
        var flywireForm = this;

        window.setTimeout(function () {
            var elements = document.getElementsByName('fwp.window');
            var iframe;

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].nodeName === 'IFRAME') {
                    iframe = elements[i];
                    break;
                }
            }

            if (!iframe) {
                return;
            }

            // remove existing countdown elements
            flywireForm.removeCountdownTimers(iframe.parentElement);

            var countdownElements = document.getElementsByClassName('payment-instructions');

            if (countdownElements.length < 1) {
                return;
            }

            iframe.parentElement.prepend(countdownElements[0]);
        }, 3000);
    },

    render: function () {
        if (!this.props.callbackId) {
            return false;
        }

        if (this.state.showPollingMessage) {
            return <div><Loading loadingMessage="Please wait while Flywire processes your payment..."></Loading></div>;
        }

        var showErrorMessage = this.state.showErrorMessage || this.props.showErrorMessage;
        var title, text;
        if (this.state.showPendingMessage) {
            title = global.OraWidgets.Options.flywirePendingMessageTitle;
            text = global.OraWidgets.Options.flywirePendingMessageText;
        } else if (showErrorMessage) {
            title = global.OraWidgets.Options.flywireErrorMessageTitle;
            text = global.OraWidgets.Options.flywireErrorMessageText;
        }
            
        return (
            <div>{!!title ?
                <div className="booking-success-container">
                    <h4 className="booking-success-title">{title}</h4>
                    {!!text && text.length ? (
                        <div>
                            <hr className="short-separator" />
                            {map(text, function (message) {
                                return <p className="booking-success-description">{message}</p>;
                            })}
                        </div>) : false}
                    { showErrorMessage ? <p>Alternatively, <a href="javascript:location.reload(false);">refresh the page</a> to try the payment again</p> : false}
                </div>
                : false}
            </div>
);
}
});

export default FlyWireForm;