import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';
import CourseStore from '../../stores/CourseStore.js';
import BasketStore from '../../stores/BasketStore.js';
import Loading from '../common/loading.jsx';
import FormGroup from '../common/form-group.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import RegionPrettyNameLookup from './region-pretty-name-lookup';
import any from 'lodash/some';
import map from 'lodash/map';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy.js';
import partial from 'lodash/partial';
import BasketUtilities from '../../utilities/BasketUtilities.js';

var WhatRegion = createReactClass({
    displayName: "WhatRegion",
    props: {
        wizard: PropTypes.object
    },
    getInitialState: function () {
        var wizard = this.props.wizard;
        var isAdditionalCourse = any(BasketStore.getCourses(), function (course) {
            return BasketUtilities.studentEquals(course.Student, wizard.state.Student);
        });

        return {
            courses: CourseStore.getCourses(),
            isAdditionalCourse: isAdditionalCourse
        }
    },

    componentDidMount: function () {
        CourseStore.addChangeListener(this.updateCourses);
        this.skipIfPreselected();
    },

    componentWillUnmount: function () {
        CourseStore.removeChangeListener(this.updateCourses);
    },

    updateCourses: function () {
        var courses = CourseStore.getCourses();
        this.setState({
            courses: courses,
        }, this.skipIfPreselected)
    },

    updateRegion: function (region) {
        this.props.wizard.set({ Region: region }, this.props.wizard.nextQuestion);
    },


    skipIfPreselected: function () {
        // if a region has been preselected, then we can skip this step, 
        // but we want to be able to visit this step later, so unset the preselection flag
        const wizard = this.props.wizard;
        const region = wizard.state.Region;
        if (region && region.IsPreselected) {
            region.IsPreselected = false;
            this.updateRegion(region)
        }
    },

    render: function () {
        var allCourses = this.state.courses;
        var loading = !any(allCourses);
        if (loading) return <Loading />;

        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;

        var isAfterSeptember = moment.utc().month() >= 8;
        var studentAgeInSummer =
            isAfterSeptember
                ? moment([moment.utc().year() + 1, 7, 1]).diff(wizard.state.Student.DateOfBirth, 'years')
                : moment([moment.utc().year(), 7, 1]).diff(wizard.state.Student.DateOfBirth, 'years');

        var regionsForStudentAge = getRegions(allCourses, wizard.state.Student.DateOfBirth);

        return <React.Fragment><div>
            <h2>Great. {is3rdPerson
                ? "On which campus would " + wizard.state.Student.FirstName + " like to study?"
                : "On which campus would you like to study?"}
            </h2>
            <FormGroup state={this.state.hasError ? "invalid" : "valid"}>
                <div className="md:grid md:grid-cols-2 md:gap-6">
                    {map(regionsForStudentAge, function (region) {
                        var isSelected = wizard.state.Region && wizard.state.Region.RegionId === region.RegionId;
                        var regionName = RegionPrettyNameLookup.hasOwnProperty(region.RegionName)
                            ? RegionPrettyNameLookup[region.RegionName]
                            : region.RegionName;

                        var classNames = "h-full border border-solid cursor-pointer shadow hover:shadow-lg mb-4 md:mb-0 lg:mb-2 p-2" +
                            " " + (isSelected ? "border-blue-300 ring-2 ring-blue-300" : "border-gray-300");

                        return <div key={region.RegionId} className=''>
                            <div className={classNames} onClick={partial(this.updateRegion, region)}>
                                {/* <a className="bg-brand-primary text-white block font-bold mb-3 py-3 text-3xl text-center text-lg uppercase no-underline hover:text-gray-100">{regionName}</a> */}
                                <img className="w-full mx-auto aspect-[2] object-cover object-center" src={region.RegionThumbnailUrl} />
                                <p className="pt-4 uppercase font-bold font-sans text-center lg:text-xl text-primary-800">{regionName}</p>
                            </div>
                        </div>;
                    }.bind(this))}
                </div>
            </FormGroup>
            <p className="mb-4">If you would like to study at more than one campus, you can come back and add another after completing this booking</p>
        </div><NavigationButtons wizard={this.props.wizard}
            hideNext={true}
            hideBack={this.state.isAdditionalCourse}
        ></NavigationButtons></React.Fragment>;
    },
    isValid: function () {
        var wizard = this.props.wizard;
        var hasError = false;

        if (!this.state.courses.length) {
            hasError = true;
        }
        else if (!wizard.state.Region) {
            hasError = true;

        } else {
            var allowedRegions = getRegions(this.state.courses, wizard.state.Student.DateOfBirth);

            // if Region Id isn't in the list
            if (!any(allowedRegions, function (r) { return r.RegionId === wizard.state.Region.RegionId })) {
                hasError = true;
            }
        }

        this.setState({ hasError: hasError });
        return !hasError;
    }
});

function getRegions(courses, studentDob) {
    var coursesInAgeGroup = filter(courses, function (c) {
        return any(c.Dates, function (cd) {
            var studentAgeOnDate = moment.utc(cd.From).diff(studentDob, 'years');

            function isStudentAgeEligible(ag) {
                return (!ag.LowerBound || ag.LowerBound <= studentAgeOnDate) && (!ag.UpperBound || studentAgeOnDate <= ag.UpperBound);
            }

            return any(c.AgeGroups, isStudentAgeEligible);
        });
    });

    return sortBy(regionsFromCourses(coursesInAgeGroup), function (r) { return r.RegionDisplayOrder; });


    function regionsFromCourses(courses) {
        return uniqBy(flatten(map(courses, function (c) { return c.Sites || [] })), function (r) { return r.RegionId });
    }
}

export default WhatRegion;