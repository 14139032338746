import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import BasketStore from '../../stores/BasketStore.js';
import ApplySummary from './apply-summary.jsx';
import pick from 'lodash/pick';
import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import partial from 'lodash/partial';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import Ui from '../../utilities/Ui.js';
import Linkify from 'react-linkify';

var WhatNext = createReactClass({
    displayName: 'WhatNext',
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function() {
        return {
            countries: BasketStore.getCountries(),
            discounts: BasketStore.getDiscounts(),
            hasBookings: BasketStore.numberOfItems() > 0,
            courses: BasketStore.getCourses()
        }
    },

    componentDidMount: function () {
        BasketStore.addChangeListener(this.changeBasket);
    },

    componentWillUnmount: function () {
        BasketStore.removeChangeListener(this.changeBasket);
    },

    changeBasket: function () {
        var newCountries = BasketStore.getCountries();
        var update = {};
        if (newCountries !== this.state.countries) {
            update.countries = newCountries;
        }

        var newHasBookings = BasketStore.numberOfItems() > 0;
        if (newHasBookings != this.state.hasBookings) {
            update.hasBookings = newHasBookings;
        }

        var newCourses = BasketStore.getCourses();
        if (newCourses !== this.state.courses) {
            update.courses = newCourses;
        }

        var newDiscounts = BasketStore.getDiscounts();
        if (newDiscounts !== this.state.discounts) {
            update.discounts = newDiscounts;
        }

        this.setState(update);
    },
    
    handleReserve: function () {
        this.props.wizard.jumpToBilling();
    },

    handleAnotherBookingForSameStudent: function(itemId) {
        this.props.wizard.newWizardForStudent(itemId);
    },

    handleAnotherBookingForDiffStudent: function() {
        // if the user is a student, and they're adding another student, then they ARE actually the customer sooo....
        if (!this.props.wizard.state.IsUserCustomer) {
            var student = this.props.wizard.state.Student;
            var newCustomer = pick(student, "FirstName", "LastName", "Email", "Mobile");
            newCustomer.Country = find(this.state.countries, function(c) { return c.CountryId === student.Nationality.CountryId });
            var wizState = {
                IsUserCustomer: true,
                Customer: newCustomer
            }

            this.props.wizard.set(wizState, this.props.wizard.newWizardForNewStudent);
            return;
        }
        this.props.wizard.newWizardForNewStudent();
    },

    handleChooseProgramme: function () {
        this.props.wizard.resetCourse();
        this.props.wizard.jump('Student');
    },

    render: function() {
        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;
        
        var totals = BasketStore.getBasketTotals();
        var total = totals.totalIncludingBasketDiscounts;
        var discountReason = this.state.discounts ? uniq(
            filter(
            map(this.state.discounts, function (discount) {
                return discount.Discount.DisplayName
            }),
            function (displayName) {
                return !!displayName
            })) : null;

        return <div>
                <h2>Please check this summary of your application, then pay to confirm your place.</h2>
            <div className="what-next flex flex-col md:flex-row gap-24">

            <div className="md:w-2/3">
                <ApplySummary wizard={this.props.wizard} />

                {this.state.hasBookings ? false : <button className="btn btn-primary mt-8" onClick={this.handleChooseProgramme}>Choose your Programme</button>}
            </div>
                {
                    this.state.hasBookings
                    ?   <div className="md:w-1/3">
                            <div className="">
                                <div className='flex flex-row justify-between mb-6'>
                                    <div>
                                        <p className="uppercase text-navy-700 text-xs">Total fees</p>
                                        <p className='whitespace-nowrap text-3xl font-bold'>&pound;{Ui.formatWithCommas(total)}</p>
                                    </div>
                                    <div>
                                        <p className="uppercase text-navy-700 text-xs text-right">Due now</p>
                                        <p className='whitespace-nowrap text-3xl font-bold'>&pound;{Ui.formatWithCommas(totals.deposit)}</p>
                                    </div>
                                </div>
                                {
                                    discountReason ? <p className="my-2 text-sm"><Linkify 
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}>
                                        {discountReason}
                                    </Linkify></p> : false
                                }
                                <div className="space-y-6 bottom-4">
                                    <button className="btn btn-primary rounded bg-gold-400 w-full py-4 place-content-center uppercase" onClick={this.handleReserve}>Reserve and Pay Now</button>
                                </div>

                                <p className="mt-12 mb-2">Alternatively, you can:</p>

                                {
                                    values(map(
                                        groupBy(values(this.state.courses), function (booking) {
                                            var student = booking.Student;
                                            return student.FirstName + "|" + student.LastName + "|" + moment.utc(student.DateOfBirth).format("YYYYMMDD") + "|" + student.Gender;
                                        }), function (bookings, identifier) {
                                            var student = bookings[0].Student;
                                            return <p key={identifier}>
                                                <button className="block btn btn-link text-left" onClick={partial(this.handleAnotherBookingForSameStudent, bookings[0].ItemId)}>Add another booking for {student.FirstName}</button>
                                            </p>
                                    }.bind(this)))
                                }

                                <p className='mb-8'>
                                    <button className="block btn btn-link text-left " onClick={this.handleAnotherBookingForDiffStudent}>Add a booking for another student</button>
                                </p>

                                <p className="mt-12">Or, if you'd like to, you can:</p>
                                <p className=''><a className='btn btn-link' onClick={() => { if (confirm('Are you sure you want to remove all details and start again?')) { localStorage.clear(); location.reload(); }}}>Start again</a></p>
                            </div>
                        </div>
                    : false
                }
                
                </div>
        </div>;
    },

    isValid: function() {
        return true;
    }
});

export default WhatNext;