import React from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import BasketActionCreators from '../../../actions/BasketActionCreators.js';
import BasketStore from '../../../stores/BasketStore.js';

export default {
    props: {
        wizard: PropTypes.object
    }, 
    getInitialState: function() {
        return {
            firstInput: React.createRef()
        };
    },

    componentDidMount: function() {
        if (this.state.firstInput && this.state.firstInput.current) this.state.firstInput.current.focus();
    },

    updateStudent: function(event) {
        this.updateStudentProp(event.target.name, event.target.value);
    },

    updateStudentProp: function(prop, value, callback) {
        var student = clone(this.props.wizard.state.Student);
        student[prop] = value;
        this.props.wizard.set({ Student: student }, callback);
        if (this.state.hasValidated) {
            this.isValid();
        }

        // sync changes to the customer basket so that abandoned baskets and opps work as expected
        if (!this.props.wizard.state.IsUserCustomer
            && ['FirstName', 'LastName', 'Mobile', 'Email'].indexOf(prop) > -1
            && isEmpty(BasketStore.getCourses())) {
            BasketActionCreators.updateCustomer(prop, value);
        }
    },

    isNonEmptyProp: function(prop) {
        return !!this.props.wizard.state.Student[prop];
    }
};