import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import Store from './Store.js';
import create from 'lodash/create';

var individualCourseLookup = {};
var courses = [];

function CourseStore() {
    Store.call(this);
}

CourseStore.prototype = create(Store.prototype, {
    'constructor': CourseStore,
    getById: function (courseId) {
        return individualCourseLookup[courseId];
    },

    getCourses: function() {
        return courses;
    }
});

var courseStore = new CourseStore();
courseStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.GET_COURSE:
            individualCourseLookup[payload.course.CourseId] = payload.course;
            break;

        case ActionTypes.GET_COURSES:
            courses = payload.courses;
            break;
            
        default:
            return;
    }

    courseStore.emitChange();
});

export default courseStore;