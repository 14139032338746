import React from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash/clone';
import BasketActionCreators from '../../../actions/BasketActionCreators.js';

export default {
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function() {
        return {
            firstInput: React.createRef()
        }
    },

    componentDidMount: function() {
        if (this.state.firstInput.current) this.state.firstInput.current.focus();
    },

    updateCustomer: function(event) {
        this.updateCustomerProp(event.target.name, event.target.value);
    },

    updateCustomerProp: function(prop, value) {
        var customer = clone(this.props.wizard.state.Customer);
        customer[prop] = value;
        this.props.wizard.set({ Customer: customer });
        if (this.state.hasValidated) {
            this.isValid();
        }

        // sync changes to the customer basket so that abandoned baskets and opps work as expected
        if (['FirstName', 'LastName', 'Mobile', 'Email'].indexOf(prop) > -1) {
            BasketActionCreators.updateCustomer(prop, value);
        }
    },

    isNonEmptyProp: function(prop) {
        return !!this.props.wizard.state.Customer[prop];
    },

    hasEnteredProp: function(prop) {
        return this.props.wizard.state.Customer.hasOwnProperty(prop);
    }
};