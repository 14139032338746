import React from 'react';
import createReactClass from 'create-react-class';
import CustomerBase from './customer-base';
import PressEnter from '../press-enter-to-continue.jsx';
import FormGroup from '../../common/form-group.jsx';
import TelephoneInput from '../../common/react-telephone-input.jsx';
import NavigationButtons  from '../navigation-buttons.jsx';

import Loading from '../../common/loading.jsx';
import guessUserCountry from '../../common/guess-user-country.js';
import BasketStore from '../../../stores/BasketStore.js';
import BasketActionCreators from '../../../actions/BasketActionCreators.js';

import validateNumber from '../../../validators/Telephone.js';

import isEmail from 'validator/es/lib/isEmail';
import map from 'lodash/map';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy.js';

var Customer = createReactClass({ 
    displayName: 'Customer',
    mixins: [CustomerBase],

    getInitialState: function() {
        return {
            hasValidated: false,
            firstNameError: false,
            lastNameError: false,
            emailErrors: [],
            countryError: false,
            countries: BasketStore.getCountries(),
            isEditMode: BasketStore.numberOfItems() > 0,
            mobileErrors: [],
            TelephoneInput: React.createRef(),
        }
    },

    componentDidMount: function () {
        BasketStore.addChangeListener(this.changeBasket);
    },

    componentWillUnmount: function () {
        BasketStore.removeChangeListener(this.changeBasket);
    },

    changeBasket: function () {
        var update = {};
        var newCountries = BasketStore.getCountries();
        if (newCountries !== this.state.countries) {
            update.countries = newCountries;
        }

        var newIsEditMode = BasketStore.numberOfItems() > 0;
        if (this.state.isEditMode !== newIsEditMode) {
            update.isEditMode = newIsEditMode;
        }

        this.setState(update);
    },

    render: function() {
        var wizard = this.props.wizard;
        var usersCountry = guessUserCountry();
        return <React.Fragment>
            <div>
            
            <h2 className="!mb-2">To get started, we'll need some information <br /> about the person paying the fees.</h2>
            <h3 className="mb-8 text-gray-600 font-sans">This person will be our main point of contact, and must be over the age of 18.</h3>
            <label>Name</label>
            <div className="md:flex md:space-x-4 lg:space-x-8 max-w-screen-sm">
                <div className="md:flex-1">
                    <FormGroup state={this.state.firstNameError ? "invalid" : "valid" }>
                        <input className="form-control" type="text" name="FirstName" onChange={this.updateCustomer} value={wizard.state.Customer.FirstName || ''} placeholder="First Name" ref={this.state.firstInput}/>
                    </FormGroup>
                </div>
                <div className="md:flex-1">
                    <FormGroup state={this.state.lastNameError ? "invalid" : "valid" }>
                        <input className="form-control" type="text" name="LastName" onChange={this.updateCustomer} value={wizard.state.Customer.LastName || ''} placeholder="Last Name" />
                    </FormGroup>
                </div>
            </div>

            <label>Email address</label>
            <FormGroup state={this.state.emailErrors.length ? "invalid" : "valid"} validationMessage={this.state.errors}>
                <div className="">
                    <input className="form-control max-w-lg" type="email" placeholder="e.g. joe@acme.com" onChange={this.updateCustomer} name="Email" value={wizard.state.Customer.Email || ''} />
                </div>
            </FormGroup>

            <label>Country of residence</label>
            <FormGroup state={this.state.countryError ? "invalid" : "valid" } validationMessage="Please choose country from the list">
                {(!this.state.countries.length 
                    ? <Loading loadingMessage="Loading countries..."></Loading> 
                    : 
                        <div className="">
                            <select className="form-control max-w-lg" name="Country" onChange={this.updateCountry} defaultValue={wizard.state.Customer.Country ? wizard.state.Customer.Country.CountryId : 0}>
                                <option key="0" value={false}>Select</option>
                                {map(sortBy(this.state.countries, function (country) {
                                    return usersCountry && country.Iso2 === usersCountry.Iso2.toUpperCase() ? 0 : 1
                                }), function (country) {
                                    return <option key={country.CountryId} value={country.CountryId}>{country.Name}</option>;
                                })}
                            </select>
                        </div>
                        
                )}
            </FormGroup>

            <label>Phone number</label>
            <FormGroup state={this.state.mobileErrors.length ? "invalid" : "valid"} validationMessage={this.state.mobileErrors} >
               <div className="ora-widget">
                    <TelephoneInput onChange={this.updateMobile} 
                        showValidation={this.state.mobileErrors.length}
                        name="Mobile" 
                        value={wizard.state.Customer.Mobile} 
                        defaultCountry={wizard.state.Customer.Country && wizard.state.Customer.Country.Iso2 ? wizard.state.Customer.Country.Iso2.toLowerCase() : usersCountry ? usersCountry.Iso2.toLowerCase() : "gb" } 
                        flagsImagePath="https://oracdn.blob.core.windows.net/cdn/react-flags.png" 
                        ref={this.state.TelephoneInput} />
               </div>
           </FormGroup>

            <PressEnter wizard={wizard} />
        </div>
        <NavigationButtons wizard={this.props.wizard} 
            hideBack={this.state.isEditMode ? true : undefined} 
            hideCancel={this.state.isEditMode ? true : undefined} 
            nextText={this.state.isEditMode ? "Save" : undefined}
            nextClass={this.state.isEditMode ? "" : undefined}
            ></NavigationButtons>
        </React.Fragment>;
    },

    onSubmit: function () {
        var wizard = this.props.wizard;
        BasketActionCreators.chooseCustomer(wizard.state.Customer);
        if (this.state.isEditMode) {
            wizard.jumpToWhatNext();
            return false;
        }

        return true;
    },

    isValid: function() {
        let firstNameError = !this.isNonEmptyProp("FirstName");
        let lastNameError  = !this.isNonEmptyProp("LastName");
        let emailErrors = [];
        let mobileErrors = [];

        if (!this.isNonEmptyProp("Email")) {
            emailErrors.push("Your email address is required");
        }
        else if (!isEmail(this.props.wizard.state.Customer.Email)) {
            emailErrors.push("Please enter a valid email address");
        }

        let countryError = !this.isNonEmptyProp("Country");
        
        if (!this.isNonEmptyProp("Mobile")) {
            mobileErrors.push("Please enter a contact number");
        } 
        else if (!validateNumber(this.props.wizard.state.Customer.Mobile.replace(/\D/g, ''))) {
            mobileErrors.push("Please enter a valid contact number");
        }

        this.setState({ 
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            emailErrors: emailErrors,
            countryError: countryError,
            mobileErrors: mobileErrors,
            hasValidated: true
        });

        return !firstNameError && !lastNameError && !emailErrors.length && !countryError && !mobileErrors.length;
    },
    
    updateCountry: function(event) {
        var countryId = event.target.value * 1;
        var country = null;
        if (countryId) {
            country = find(this.state.countries,
                function(country) {
                    return country.CountryId == countryId;
                });
        }

        this.updateCustomerProp('Country', country);
        if (country && country.Iso2) {
            this.state.TelephoneInput.current.updateCountry(country.Iso2.toLowerCase());
        }
    },

    updateMobile: function (value) {
        this.updateCustomerProp('Mobile', value);
    },
});

export default Customer;