import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import AvailabilityService from '../utilities/AvailabilityService.js';
import AvailabilityStore from '../stores/AvailabilityStore.js';

export default {
    getAvailability: function (courseId, agentId, context) {
        // does the store already have this availability?
        var cachedAvailability = AvailabilityStore.getCourseAvailability(courseId, agentId);
        if (cachedAvailability) {
            // yes, issue another dispatch so that any views get notified of the new request finishing
            setTimeout(function() {
                dispatchGetAvailability(cachedAvailability, context, agentId, courseId);
            }, 1);
        } else {
            // doesn't have it, go to the web api
            AvailabilityService.getCourseAvailability(courseId, agentId).done(function (availability) {
                dispatchGetAvailability(availability, context, agentId, courseId);
            });
        }
    },

    getAgentAvailability: function(courseId, agentId, context) {
        var cached = AvailabilityStore.getAgentAvailability(courseId, agentId);
        if (!cached) {
            AvailabilityService
                .getAgentAvailability(courseId, agentId)
                .done(function(result) {
                        Dispatcher.dispatch({
                            type: ActionTypes.GET_AGENT_AVAILABILITY,
                            agentAvailability: result,
                            courseId: courseId,
                            agentId: agentId,
                            context: context
                        });
                });
        }
    },

    getTimeZones: function(course, sessions) {
        AvailabilityService.getTimeZones(course, sessions).then(function(d) {
            Dispatcher.dispatch({
                type: ActionTypes.GET_TIMEZONES,
                course: course,
                sessions: sessions,
                timeZones: d
            });
        });
    },

    getOptions: function (course, intensity, region, sessions, startDate) {
        AvailabilityService.getOptions(course, sessions, region.RegionId).then(function (d) {
            Dispatcher.dispatch({
                type: ActionTypes.GET_OPTIONS,
                lineItem: d,
                course: course,
                intensity: intensity,
                region: region,
                sessions: sessions,
                startDate: startDate
            });
        });
    },

    makeSelection: function (course, intensity, dates, context) {
        Dispatcher.dispatch({ type: ActionTypes.SELECTION_CHOSEN, course: course, intensity: intensity, dates: dates, context: context });
    },

    clearSelection: function(context) {
        Dispatcher.dispatch({ type: ActionTypes.SELECTION_CLEARED, context: context });
    },

    updateDates: function(date, weeks, hours, context) {
        Dispatcher.dispatch({ type: ActionTypes.DATES_UPDATED, context: context, date: date, weeks: weeks, hours: hours });
    },

    goToEnquiry: function(course, intensity, options, context) {
        Dispatcher.dispatch({ type: ActionTypes.BEGIN_ENQUIRY, course: course, intensity: intensity, options: options, context: context });
    },

    sendEnquiry: function (customer, course, intensity, options, message, context) {
        //var selectedRegions = OptionsStore.getSelectedRegions(context);
        //Dispatcher.dispatch({ type: ActionTypes.SEND_ENQUIRY_START, context: context });
        //AvailabilityService.sendEnquiry(customer, course, intensity, options, message, selectedRegions).done(function () {
        //    Dispatcher.dispatch({ type: ActionTypes.SEND_ENQUIRY_SENT, context: context });
        //});
    },

    sendNotifyRequest: function (course, intensity, options, region, emailAddress, phoneNumber, studentName, studentGender) {
        Dispatcher.dispatch({
            type: ActionTypes.BEGIN_COURSE_SUGGESTIONS
        });
        AvailabilityService.sendNotifyRequest(course, intensity, options, region, emailAddress, phoneNumber, studentName, studentGender).done(function (suggestions) {
            Dispatcher.dispatch({
                type: ActionTypes.GET_COURSE_SUGGESTIONS,
                suggestions: suggestions
            });
        });
    },

    selectRegion: function (region, context) {
        Dispatcher.dispatch({ type: ActionTypes.SELECT_REGION, region: region, context: context });
    },

    deselectRegion: function (region, context) {
        Dispatcher.dispatch({ type: ActionTypes.DESELECT_REGION, region: region, context: context });
    }
};

function dispatchGetAvailability(availability, context, agentId, courseId) {
    Dispatcher.dispatch({
        type: ActionTypes.GET_AVAILABILITY,
        availability: availability,
        courseId: courseId,
        agentId: agentId,
        context: context
    });
}