import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import moment from 'moment';

var secondsInOneMinute = 60;

var Timer = createReactClass({
    propTypes: {
        expiryTime: PropTypes.object,
        onCountdownComplete: PropTypes.func
    },

    getInitialState: function () {
        return {
            secondsRemaining: this.getRemainingSeconds()
        };
    },

    getRemainingSeconds: function() {
        return moment(this.props.expiryTime).diff(moment.utc(), 'seconds');
    },

    componentDidMount: function () {
        this.timerId = setInterval(this.countDown, 1000);
    },

    componentWillUnmount: function () {
        clearInterval(this.timerId);
    },
    
    countDown: function() {
        // Remove one second, set state so a re-render happens.
        var seconds = this.getRemainingSeconds();
        this.setState({
            secondsRemaining: seconds
        });

        // Check if we're at zero.
        if (seconds <= 0) {
            this.props.onCountdownComplete();
        }
    },

    render: function () {
        var classes = 'countdown-timer' + (this.state.secondsRemaining < secondsInOneMinute ? ' timer-danger' : '');
        var secsPart = this.state.secondsRemaining % secondsInOneMinute;
        var minsPart = (this.state.secondsRemaining - secsPart) / secondsInOneMinute;
        
        return (
            <span className={classes}>{minsPart + ' minute' + (minsPart !== 1 ? 's' : '') + ' and ' + secsPart + ' second' + (secsPart !== 1 ? 's' : '')}</span>
        );
    }
});

export default Timer;