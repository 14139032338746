import sortBy from 'lodash/sortBy';

// NationalityId, CountryId, Name
var nationalities = [
[1, 1, "Afghan"],
[2, 2, "Albanian"],
[3, 3, "Algerian"],
[4, 223, "American"],
[5, 5, "Andorran"],
[6, 6, "Angolan"],
[7, 9, "Antiguans"],
[8, 10, "Argentinean"],
[9, 11, "Armenian"],
[10, 13, "Australian"],
[11, 14, "Austrian"],
[12, 15, "Azerbaijani"],
[13, 16, "Bahamian"],
[14, 17, "Bahraini"],
[15, 18, "Bangladeshi"],
[16, 19, "Barbadian"],
[17, 9, "Barbudans"],
[18, 28, "Botswana"],
[19, 20, "Belarusian"],
[20, 21, "Belgian"],
[21, 22, "Belizean"],
[22, 23, "Beninese"],
[23, 25, "Bhutanese"],
[24, 26, "Bolivian"],
[25, 27, "Bosnian"],
[26, 30, "Brazilian"],
[27, 222, "British"],
[28, 32, "Bruneian"],
[29, 33, "Bulgarian"],
[30, 34, "Burkinabe"],
[31, 146, "Burmese"],
[32, 35, "Burundian"],
[33, 36, "Cambodian"],
[34, 37, "Cameroonian"],
[35, 38, "Canadian"],
[36, 39, "Cape Verdean"],
[37, 41, "Central African"],
[38, 42, "Chadian"],
[39, 43, "Chilean"],
[40, 44, "Chinese"],
[41, 47, "Colombian"],
[42, 48, "Comoran"],
[43, 49, "Congolese"],
[44, 51, "Costa Rican"],
[45, 53, "Croatian"],
[46, 54, "Cuban"],
[47, 55, "Cypriot"],
[48, 56, "Czech"],
[49, 57, "Danish"],
[50, 58, "Djibouti"],
[51, 60, "Dominican"],
[52, 150, "Dutch"],
[53, 61, "East Timorese"],
[54, 62, "Ecuadorean"],
[55, 63, "Egyptian"],
[56, 221, "Emirian"],
[57, 65, "Equatorial Guinean"],
[58, 66, "Eritrean"],
[59, 67, "Estonian"],
[60, 68, "Ethiopian"],
[61, 71, "Fijian"],
[62, 168, "Filipino"],
[63, 72, "Finnish"],
[64, 73, "French"],
[65, 78, "Gabonese"],
[66, 79, "Gambian"],
[67, 80, "Georgian"],
[68, 81, "German"],
[69, 82, "Ghanaian"],
[70, 84, "Greek"],
[71, 86, "Grenadian"],
[72, 89, "Guatemalan"],
[73, 91, "Guinea-Bissauan"],
[74, 90, "Guinean"],
[75, 92, "Guyanese"],
[76, 93, "Haitian"],
[77, 27, "Herzegovinian"],
[78, 95, "Honduran"],
[79, 97, "Hungarian"],
[80, 111, "I-Kiribati"],
[81, 98, "Icelander"],
[82, 99, "Indian"],
[83, 100, "Indonesian"],
[84, 101, "Iranian"],
[85, 102, "Iraqi"],
[86, 103, "Irish"],
[87, 104, "Israeli"],
[88, 105, "Italian"],
[89, 52, "Ivorian"],
[90, 106, "Jamaican"],
[91, 107, "Japanese"],
[92, 108, "Jordanian"],
[93, 109, "Kazakhstani"],
[94, 110, "Kenyan"],
[95, 178, "Kittian and Nevisian"],
[96, 114, "Kuwaiti"],
[97, 115, "Kyrgyz"],
[98, 116, "Laotian"],
[99, 117, "Latvian"],
[100, 118, "Lebanese"],
[101, 120, "Liberian"],
[102, 121, "Libyan"],
[103, 122, "Liechtensteiner"],
[104, 123, "Lithuanian"],
[105, 124, "Luxembourger"],
[106, 126, "Macedonian"],
[107, 127, "Malagasy"],
[108, 128, "Malawian"],
[109, 129, "Malaysian"],
[110, 130, "Maldivan"],
[111, 131, "Malian"],
[112, 132, "Maltese"],
[113, 133, "Marshallese"],
[114, 135, "Mauritanian"],
[115, 136, "Mauritian"],
[116, 138, "Mexican"],
[117, 139, "Micronesian"],
[118, 140, "Moldovan"],
[119, 141, "Monacan"],
[120, 142, "Mongolian"],
[121, 144, "Moroccan"],
[122, 119, "Mosotho"],
[123, 28, "Motswana"],
[124, 145, "Mozambican"],
[125, 147, "Namibian"],
[126, 148, "Nauruan"],
[127, 149, "Nepalese"],
[128, 153, "New Zealander"],
[129, 154, "Nicaraguan"],
[130, 156, "Nigerian"],
[131, 155, "Nigerien"],
[132, 112, "North Korean"],
[133, 222, "Northern Irish"],
[134, 160, "Norwegian"],
[135, 161, "Omani"],
[136, 162, "Pakistani"],
[137, 163, "Palauan"],
[138, 164, "Panamanian"],
[139, 165, "Papua New Guinean"],
[140, 166, "Paraguayan"],
[141, 167, "Peruvian"],
[142, 170, "Polish"],
[143, 171, "Portuguese"],
[144, 173, "Qatari"],
[145, 175, "Romanian"],
[146, 176, "Russian"],
[147, 177, "Rwandan"],
[148, 179, "Saint Lucian"],
[149, 64, "Salvadoran"],
[150, 181, "Samoan"],
[151, 182, "San Marinese"],
[152, 183, "Sao Tomean"],
[153, 184, "Saudi"],
[154, 222, "Scottish"],
[155, 185, "Senegalese"],
[156, 240, "Serbian"],
[157, 186, "Seychellois"],
[158, 187, "Sierra Leonean"],
[159, 188, "Singaporean"],
[160, 189, "Slovakian"],
[161, 190, "Slovenian"],
[162, 191, "Solomon Islander"],
[163, 192, "Somali"],
[164, 193, "South African"],
[165, 113, "South Korean"],
[166, 195, "Spanish"],
[167, 196, "Sri Lankan"],
[168, 199, "Sudanese"],
[169, 200, "Surinamer"],
[170, 202, "Swazi"],
[171, 203, "Swedish"],
[172, 204, "Swiss"],
[173, 205, "Syrian"],
[174, 206, "Taiwanese"],
[175, 207, "Tajik"],
[176, 208, "Tanzanian"],
[177, 209, "Thai"],
[178, 210, "Togolese"],
[179, 212, "Tongan"],
[180, 213, "Trinidadian or Tobagonian"],
[181, 214, "Tunisian"],
[182, 215, "Turkish"],
[183, 218, "Tuvaluan"],
[184, 219, "Ugandan"],
[185, 220, "Ukrainian"],
[186, 225, "Uruguayan"],
[187, 226, "Uzbekistani"],
[188, 229, "Venezuelan"],
[189, 230, "Vietnamese"],
[190, 222, "Welsh"],
[191, 235, "Yemenite"],
[192, 238, "Zambian"],
[193, 239, "Zimbabwean"],
[194, 96, "Hongkonger"],
[195, 241, "Palestinian"],
[196, 216, "Turkmenistan"],
[197, 172, "Puerto Rico"],
[199, 28, "Batswana"],
[200, 242, "Montenegrin"],
[201, 24, "Bermudian"],
[202, 40, "Caymanian"],
[203, 180, "Saint Vincentian"]
];

// e.g [ { Nationality: "American"... }]
export default sortBy(nationalities.map(function(n) {
        return {
            CountryId: n[1],
            NationalityId: n[0],
            Name: n[2]
        };
    }),
    function(n) {
        return n.Name;
    });