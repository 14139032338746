import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Ui from '../../utilities/Ui.js';

var CheckoutPay = createReactClass({
    propTypes: {
        isAgentAllowedToCreateDraftOrder: PropTypes.bool,
        isWebAdminUser: PropTypes.bool,
        onPayDeposit: PropTypes.func,
        onPayTotal: PropTypes.func,
        onConfirm: PropTypes.func,
        deposit: PropTypes.number,
        total: PropTypes.number
    },

    renderButton: function (text, rightText, onClick, btnClass) {
        return <div className="checkout-pay-button-container">
            <button className={'button button--lg button-block button--continue btn btn-primary w-full inline-flex justify-between ' + btnClass} onClick={onClick}><span>{text}</span><span className="">{rightText}</span></button>
        </div>;
    },

    render: function() {
        return (
            <div className="ora-widget-checkout-pay">
                        {
                            this.props.isWebAdminUser || this.props.isAgentAllowedToCreateDraftOrder
                                ? this.renderButton('Create Draft Order',
                                '',
                                    this.props.onConfirm,
                                    '')
                                : false
                        }
                        {this.props.deposit
                            ? this.renderButton('Pay enrolment fee: ',
                                '£' + Ui.formatWithCommas(this.props.deposit),
                                this.props.onPayDeposit,
                                '')
                            : false}
                        {this.renderButton('Pay total: ',
                            '£' + Ui.formatWithCommas(this.props.total),
                            this.props.onPayTotal,
                            '')}
            </div>);
    }
});

export default CheckoutPay;