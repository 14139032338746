import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import BasketActionCreators from '../../actions/BasketActionCreators.js';
import FormGroup from '../common/form-group.jsx';
import TelephoneInput from '../common/react-telephone-input.jsx';
import map from 'lodash/map';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import partial from 'lodash/partial';
import min from 'lodash/min';
import max from 'lodash/max';

function getStudentKey(student) {
    return student.FirstName + '|' + student.LastName + '|' + student.DateOfBirth + '|' + student.Gender;
}

var CustomerForm = createReactClass({
    propTypes: {
        showCustomerFields: PropTypes.bool,
        customer: PropTypes.object,
        errorState: PropTypes.object,
        onUpdate: PropTypes.func, // called whenever a field is blurred or changed considerably (for validation purposes), not called when text is being input for example
        courses: PropTypes.object,
        isThirdParty: PropTypes.bool,
        countries: PropTypes.array,
    },

    getDefaultProps: function () {
        return {
            countries: [],
        }
    },

    onChange: function (e) {
        BasketActionCreators.updateCustomer(e.target.name, e.target.value);
        if (this.props.isThirdParty) {
            if (e.target.name === 'Email') {
                BasketActionCreators.updateCustomer('ConfirmEmail', e.target.value);
            }
        }
    },

    onChangePhoneNumber: function (number) {
        BasketActionCreators.updateCustomer('Mobile', number);
    },

    onChooseStudent: function (student) {
        BasketActionCreators.chooseCustomer(student);
    },

    render: function () {
        var confirm = !this.props.isThirdParty ? <div className="col col-md-6">
            <FormGroup label="Confirm Email*" state={this.props.errorState.ConfirmEmailState} validationMessage={this.props.errorState.ConfirmEmailErrors}>
                <input type="email" className="form-control form-input" onChange={this.onChange} onBlur={this.props.onUpdate} value={this.props.customer.ConfirmEmail} name="ConfirmEmail" />
            </FormGroup>
        </div> : false;

        var mobile = <div className="col col-md-6">
            <FormGroup label="Mobile Number*" state={this.props.errorState.MobileState} validationMessage={this.props.errorState.MobileErrors}>
                <TelephoneInput defaultCountry="gb" flagsImagePath="https://oracdn.blob.core.windows.net/cdn/react-flags.png" onChange={this.onChangePhoneNumber} value={this.props.customer.Mobile} />
            </FormGroup>
        </div>;

        var adultStudents = uniq(
            map(
                filter(
                    this.props.courses,
                    function (lineItem) {
                        // find min of items in the age groups
                        var minAge = (min(lineItem.Course.AgeGroups, function (ageGroup) {
                            return ageGroup.LowerBound;
                        })).LowerBound;

                        return lineItem.Student
                            && lineItem.Student.FirstName
                            && lineItem.Student.LastName
                            && lineItem.Student.DateOfBirth
                            && lineItem.Student.Gender
                            && minAge > 18;
                    }
                ),
                function (lineItem) {
                    return lineItem.Student;
                }
            ),
            getStudentKey);

        return (
            <div className="customer-details-inputs checkout-slim">
                {
                    adultStudents.length && this.props.showCustomerFields
                        ? <div className="row">
                              <div className="col col-md-12">
                                  <ul className="list-unstyled">
                                      {map(adultStudents,
                                    function (student) {
                                        return <li key={getStudentKey(student)}>
                                        <input type="checkbox" onClick={partial(this.onChooseStudent, student)} /> Use details of {student.FirstName} {student.LastName}
                                    </li>;
                                    }.bind(this))}
                                  </ul>
                              </div>
                          </div>
                        : false
                }
                {
                    this.props.showCustomerFields
                        ? <div>
                            <div className="row">
                                <h4 className="payment-subsection-title col col-md-12">Name and Contact Details:</h4>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <FormGroup label="Title*" state={this.props.errorState.TitleState} validationMessage={this.props.errorState.TitleErrors}>
                                        <input type="text" className="form-control form-input" onChange={this.onChange} onBlur={this.props.onUpdate} placeholder="Mr, Mrs, Ms, M., Mme, Dr, ..." value={this.props.customer.Title} name="Title"/>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <FormGroup label="First Name*" state={this.props.errorState.FirstNameState} validationMessage={this.props.errorState.FirstNameErrors}>
                                        <input type="text" className="form-control form-input" onChange={this.onChange} onBlur={this.props.onUpdate} value={this.props.customer.FirstName} name="FirstName"/>
                                    </FormGroup>
                                </div>
                                <div className="col col-md-6">
                                    <FormGroup label="Last Name*" state={this.props.errorState.LastNameState} validationMessage={this.props.errorState.LastNameErrors}>
                                        <input type="text" className="form-control form-input" onChange={this.onChange} onBlur={this.props.onUpdate} value={this.props.customer.LastName} name="LastName"/>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <FormGroup label="Email*" state={this.props.errorState.EmailState} validationMessage={this.props.errorState.EmailErrors}>
                                        <input type="email" className="form-control form-input" onChange={this.onChange} onBlur={this.props.onUpdate} value={this.props.customer.Email} name="Email"/>
                                    </FormGroup>
                                </div>
                                {this.props.isThirdParty ? mobile : confirm}
                            </div>
                            {!this.props.isThirdParty
                                ? <div className="row">{mobile}</div>
                                : false}
                        </div>
                        : false
                }

                { this.props.showCustomerFields ?
                    <div className="row">
                        <h4 className="payment-subsection-title col col-md-12" style={{ marginTop: '30px' }}>Billing Address:</h4>
                    </div>
                    : false}
                <div className="row flow-root">
                    <div className="col col-md-6">
                        <FormGroup label="Line 1*" state={this.props.errorState.BillingLine1State} validationMessage={this.props.errorState.BillingLine1Errors}>
                            <input
                                name="BillingLine1"
                                className="form-control form-input"
                                type="text"
                                autoComplete='address-line1'
                                onChange={this.onChange}
                                onBlur={this.props.onUpdate}
                                value={this.props.customer.BillingLine1} />
                        </FormGroup>
                    </div>

                    <div className="col col-md-6">
                        <FormGroup label="Line 2" state={this.props.errorState.BillingLine2State}>
                            <input
                                name="BillingLine2"
                                className="form-control form-input"
                                type="text"
                                autoComplete='address-line2'
                                onChange={this.onChange}
                                onBlur={this.props.onUpdate}
                                value={this.props.customer.BillingLine2} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row flow-root">
                    <div className="col col-md-6">
                        <FormGroup label="Town/City*" state={this.props.errorState.BillingCityState} validationMessage={this.props.errorState.BillingCityErrors}>
                            <input
                                name="BillingCity"
                                className="form-control form-input"
                                type="text"
                                autoComplete='address-level2'
                                onChange={this.onChange}
                                onBlur={this.props.onUpdate}
                                value={this.props.customer.BillingCity} />
                        </FormGroup>
                    </div>
                    <div className="col col-md-6">
                        <FormGroup label="County/State" state={this.props.errorState.BillingCountyState}>
                            <input
                                name="BillingCounty"
                                className="form-control form-input"
                                type="text"
                                autoComplete='address-level1'
                                onChange={this.onChange}
                                onBlur={this.props.onUpdate}
                                value={this.props.customer.BillingCounty} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row flow-root">
                    <div className="col col-md-6">
                        <FormGroup label="Country*" state={this.props.errorState.BillingCountryState} validationMessage={this.props.errorState.BillingCountryErrors}>
                            <select value={this.props.customer.BillingCountry} name="BillingCountry" className="form-control form-input dont-select2-me" onBlur={this.props.onUpdate} onChange={this.onChange}>
                                <option value=''>Select...</option>
                                {map(this.props.countries,
                                    function (country) {
                                        return (
                                            <option key={country.Iso3} value={country.Iso2}>
                                                {country.Name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </FormGroup>
                    </div>

                    <div className="col col-md-6">
                        <FormGroup label="Postal/Zip Code" state={this.props.errorState.BillingPostcodeState}>
                            <input
                                name="BillingPostcode"
                                className="form-control form-input"
                                type="text"
                                autoComplete='postal-code'
                                onChange={this.onChange}
                                onBlur={this.props.onUpdate}
                                value={this.props.customer.BillingPostcode} />
                        </FormGroup>
                    </div>
                </div>
            </div>
        );
    }
});

export default CustomerForm;