import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import CheckoutPay from '../checkout/checkout-pay.jsx';
import CheckoutCustomer from '../checkout/checkout-customer.jsx';
import CardIcons from '../checkout/card-icons.jsx';
import Loading from '../common/loading.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import TermsAndConditionsCreators from '../../actions/TermsAndConditionsCreators.js';
import CheckoutActionCreators from '../../actions/CheckoutActionCreators.js';
import AdManagerService from '../../utilities/AdManagerService.js';
import BasketStore from '../../stores/BasketStore.js';
import CustomerValidator from '../../validators/Customer.js';
import Ui from '../../utilities/Ui.js';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import moment from 'moment';

var Billing = createReactClass({
    displayName: 'Billing',
    props: {
        wizard: PropTypes.object
    },
    
    getInitialState: function () {
        return {
            customer: BasketStore.getCustomer(),
            courses: BasketStore.getCourses(),
            countries: BasketStore.getCountries(),
            discounts: BasketStore.getDiscounts(),
            isCustomerFormValidationEnabled: false,
            customerErrors: {},
            hasCompletedForm: false,
            isReserving: BasketStore.isReserving(),
            reservationFailureResponse: BasketStore.getReservationFailureResponse(),
            reservation: BasketStore.getReservation(),
            hasAcceptedTermsAndConditions: false,
            hasAcceptedPrivacyPolicy: false,
            termsAndConditions: BasketStore.getApplicableTermsAndConditions(),
            bookingCompleteUrl: this.props.bookingCompleteUrl,
            isOptedInToMarketing: false
        };
    },

    componentDidMount: function () {
        BasketStore.addChangeListener(this.changeBasket);
        TermsAndConditionsCreators.getAll();

        var totals = BasketStore.getBasketTotals();
        var total = totals.totalIncludingBasketDiscounts;
        var applyTotalDiscount = totals.deposit > 0 && totals.deposit < total && global.OraWidgets.Options.payTotalDiscount > 0;
        var discount = 0;
        if (applyTotalDiscount) {
            discount = Number(Math.round(total * global.OraWidgets.Options.payTotalDiscount) + 'e-2');
            total -= discount;
        }

        AdManagerService.beginCheckoutTag(total, this.state.courses);
    },

    componentWillUnmount: function () {
        BasketStore.removeChangeListener(this.changeBasket);
    },

    changeBasket: function () {
        var reservationFailureResponse = BasketStore.getReservationFailureResponse();
        var reservation = BasketStore.getReservation();

        var data = {
            customer: BasketStore.getCustomer(),
            courses: BasketStore.getCourses(),
            countries: BasketStore.getCountries(),
            discounts: BasketStore.getDiscounts(),
            isReserving: BasketStore.isReserving(),
            reservationFailureResponse: reservationFailureResponse,
            reservation: reservation,
            termsAndConditions: BasketStore.getApplicableTermsAndConditions()
        };

        if (!this.state.reservationFailureResponse && reservationFailureResponse) {
            data.currentStage = this.stages[2];
        }

        this.setState(data);
    },

    onUpdateTermsAndConditions: function (confirmed) {
        this.setState({ hasAcceptedTermsAndConditions: confirmed }, this.onUpdate);
    },

    onUpdatePrivacyPolicy: function (confirmed) {
        this.setState({ hasAcceptedPrivacyPolicy: confirmed }, this.onUpdate);
    },

    onUpdateIsOptedInToMarketing: function (e) {
        this.setState({ isOptedInToMarketing: e.target.checked });
    },

    payDeposit: function () {
        this.reserve(true, true);
    },

    payTotal: function () {
        this.reserve(false, true);
    },

    reserve: function (deposit, doPay) {
        if (!this.isValid()) {
            return;
        }

        this.setState({
            hasClickedPay: true
        });

        CheckoutActionCreators.makeReservation(!this.state.isOptedInToMarketing, this.state.isWebAdminUser, this.state.hasOverrideAgeConstraints, this.state.isAgentBooking || !!this.props.agentId, this.state.agentId !== null ? this.state.agentId : this.props.agentId, this.state.agentName, this.state.areFinancialsVisibleToCustomer, doPay, deposit);
        this.props.wizard.set({ PaymentError: false, hasClickedPayDeposit: deposit });
        this.props.wizard.nextQuestion();
    },

    getErrorMessage: function () {
        var errorMessage = false;
        if (this.state.reservationFailureResponse) {
            errorMessage = this.state.reservationFailureResponse.Message;
        } else if (this.state.termsAndConditions === false
            && this.state.hasClickedPay) {
            errorMessage = "Apologies, we've been unable to download the terms and conditions. Please refresh the page to try again; your data has been saved.";
        } else if ((((this.state.termsAndConditions === false
                        || (this.state.termsAndConditions.length
                            && !this.state.hasAcceptedTermsAndConditions)))
                || (!this.state.hasAcceptedPrivacyPolicy))
            && this.state.hasClickedPay) {
            errorMessage = "Please indicate that you have accepted the Terms and Conditions and the Privacy Policy";
        } else if (this.state.hasPaymentFailed) {
            errorMessage = this.state.paymentFailureReason;
        } else if (this.props.wizard.state.PaymentError) {
            errorMessage = this.props.wizard.state.PaymentError;
        }

        var errorContainer = errorMessage ? <div className="-mt-2 bg-red-100 p-2 px-4 mb-4 text-red-700 border-2 border-red-700 rounded" role="alert">{errorMessage}</div> : false;

        return errorContainer;
    },

    contactUs: function() {
        if (global.$zopim) {
            global.$zopim.livechat.window.show();
        } else {
            global.location.assign('https://www.oxford-royale.com/contact-us/');
        }
    },

    render: function () {
        if (!this.state.countries.length) {
            return <Loading loadingMessage="Loading countries..." />;
        }

        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;
        // TODO DRY THIS UP
        var totals = BasketStore.getBasketTotals();
        var total = totals.totalIncludingBasketDiscounts;
        var applyTotalDiscount = totals.deposit > 0 && totals.deposit < total && global.OraWidgets.Options.payTotalDiscount > 0;
        var discount = 0;
        if (applyTotalDiscount) {
            discount = Number(Math.round(total * global.OraWidgets.Options.payTotalDiscount) + 'e-2');
            total -= discount;
        }

        var hasBookingLessThan90DaysAway = some(this.state.courses, function (course) {
            return course.Options && some(course.Options, function (option) {
                return option.Session && option.Session.From && moment(option.Session.From).isBefore(moment().add(90, 'days'));
            })
        });

        var errorMessage = this.getErrorMessage();
        return <React.Fragment><div className="billing">
            <h2>Reserve and Pay</h2>
                   {errorMessage}
            <p className="mb-2">Please enter your billing address in order to start your payment</p>
            <CheckoutCustomer showCustomerFields={false}
                customer={this.state.customer}
                courses={this.state.courses}
                countries={this.state.countries}
                isWebAdminUser={false}
                termsAndConditions={this.state.termsAndConditions}
                hasAcceptedTermsAndConditions={this.state.hasAcceptedTermsAndConditions}
                hasAcceptedPrivacyPolicy={this.state.hasAcceptedPrivacyPolicy}
                onUpdateTermsAndConditions={this.onUpdateTermsAndConditions}
                onUpdatePrivacyPolicy={this.onUpdatePrivacyPolicy}
                isOptedInToMarketing={this.state.isOptedInToMarketing}
                onUpdateIsOptedInToMarketing={this.onUpdateIsOptedInToMarketing}
                errorState={this.state.isCustomerFormValidationEnabled ? this.state.customerErrors : {}}
                onUpdate={ this.onUpdate } />

            <CheckoutPay isAgentAllowedToCreateDraftOrder={false}
                isWebAdminUser={false}
                deposit={totals.deposit}
                total={total}
                onPayDeposit={this.payDeposit}
                onPayTotal={this.payTotal}
                onConfirm={this.confirmBooking} />
            
            {hasBookingLessThan90DaysAway 
            ? <div className="flex flex-row gap-4 mt-2 mb-4">
                    <div className="text-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-info-circle h-5 w-5" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                    </div>
                    <div>
                        <p className="text-sm"><span className="font-semibold">Full Fees.</span> As you are enrolling less than 90 days before your programme, the full fees must be paid immediately after your enrolment is completed. If you elect to pay just the Enrolment Fee now, please settle the balance immediately afterwards via the Oxford Royale Portal. You may receive a reminder from our friendly team via telephone and/or email.</p>
                    </div>
                </div>
            : false
            }

            <CardIcons />

            {applyTotalDiscount
                ? <p className="text-left -mt-1.5">Pay in full now for a saving of GBP {Ui.maybeFormat2Dp(discount.toFixed(2))}</p>
                : false
            }
            <p className="my-4">
                Prefer to pay by Bank Transfer or another payment method? <a href="javascript:void(0)" onClick={this.contactUs}>Click to Contact Us</a>
            </p>

        </div>
        <NavigationButtons wizard={this.props.wizard} hideNext={true}></NavigationButtons></React.Fragment>;
    },

    onUpdate: function () {
        if (this.state.isCustomerFormValidationEnabled) {
            this.isValid();
        }
    },

    isValid: function() {
        // if validation isn't enabled, it is now
        if (!this.state.isCustomerFormValidationEnabled) {
            this.setState({ isCustomerFormValidationEnabled: true });
        }

        //validate
        var customerErrorState = CustomerValidator(this.state.customer, true);
        if (!this.state.isWebAdminUser
            && !this.props.agentId
            && ((!this.props.skipTermsAndConditions
                    && (this.state.termsAndConditions === false || (this.state.termsAndConditions.length && !this.state.hasAcceptedTermsAndConditions)))
                || (!this.props.skipPrivacyPolicy && !this.state.hasAcceptedPrivacyPolicy))) {
            customerErrorState.termsAndConditionsState = 'invalid';
            customerErrorState.termsAndConditionsErrors = 'Please indicate that you have accepted the Terms and Conditions and the Privacy Policy';
        }

        // update our state
        if (!isEmpty(customerErrorState) || !isEmpty(this.state.customerErrors)) {
            this.setState({ customerErrors: customerErrorState });
        }

        return isEmpty(customerErrorState);
    }
});

export default Billing;
