import assign from 'lodash/assign.js';
import find from 'lodash/find';
import filter from 'lodash/filter';
import map from 'lodash/map';
import some from 'lodash/some';
import maxBy from 'lodash/maxBy';
import flatMap from 'lodash/flatMap';
import uniqBy from 'lodash/uniqBy';
import sumBy from 'lodash/sumBy';

import BasketStore from '../../stores/BasketStore';

function parseDeepLinks(courses, wizardState) {
    // ignore if we're already in a basket (they're adding a second course)
    if (BasketStore.numberOfItems() > 0) return {};

    // don't override if they've chosen their accommodation
    if (wizardState.Accommodation && Object.keys(wizardState.Accommodation).length > 0) return {};

    var deepLinks = getDeepLinkedCourseSlugs();
    if (!deepLinks) return {};

    var regionSlugs = deepLinks[0].split("-");
    var courseSlugs = deepLinks[1] ? deepLinks[1].split("-") : [];

    var courseCandidates = search(courses, regionSlugs, courseSlugs, deepLinks[0], deepLinks[1]);
    if (!courseCandidates) {
        return {};
    }

    var result = {};
    if (courseCandidates.course) {
        result.Course = assign({ IsPreselected: true }, courseCandidates.course);
    }
    
    if (courseCandidates.region) {
        result.Region = assign({ IsPreselected: true }, courseCandidates.region);
    }

    return result;
}

function getDeepLinkedCourseSlugs() {
    var intent = new URL(location).searchParams.get("intent");
    if (!intent) return false;

    return intent.replace(/^\/+/, '').split("/").filter(s => s);
}

function search(courses, regionSlugs, courseSlugs, regionSlug, courseSlug) {

    
    // find the courses that have at least one site with a name like in the slug
    
    var bestRegionMatch = maxBy(
        filter(
            map(
                uniqBy(
                    filter (
                        flatMap(courses, function (course) { return course.Sites; }),
                        function (site) { return site; }
                    ),
                    function (site) { return site.RegionId; }
                ),
                function (site) { return { site: site, score: sumBy(regionSlugs, slug => site.RegionName.toLowerCase().includes(slug) ? 1 : 0)} }
            ),
            function (regionPair) { return regionPair.score > 0; }
        ),
        function (regionPair) { return regionPair.score; }
    );

    if (!bestRegionMatch) {
        return false;
    }

    if (!some(courseSlugs)) {
        return {
            region: bestRegionMatch.site
        }
    }
    
    // hand match their slugs
    var slugsToCourse = {
        'architecture-design': ['Architecture & Design']
        , 'business-innovation-and-entrepreneurship-summer-school-in-london': ['Inventing the Future: Business, Innovation & Entrepreneurship']
        , 'business-innovation-entrepreneurship-16-18': ['Inventing the Future: Business, Innovation & Entrepreneurship']
        , 'business-innovation-entrepreneurship-13-15': ['Explore Business, Innovation & Entrepreneurship']
        , 'business-innovation-and-entrepreneurship-summer-school-in-berkeley': ['Inventing the Future: Business, Innovation & Entrepreneurship']
        , 'explore-business-innovation-and-entrepreneurship-summer-school-in-yale': ['Explore Business, Innovation & Entrepreneurship']
        , 'business-enterprise': ['Inventing the Future: Business, Innovation & Entrepreneurship']
        , 'curing-the-future-medicine': ['Curing the Future: Medicine & Disease']
        , 'engineering': ['Designing Tomorrow: Engineering & Technology', 'Explore Engineering & Technology']
        , 'explore-engineering': ['Explore Engineering & Technology']
        , 'explore-medicine': ['Explore Medicine']
        , 'medicine-13-15': ['Explore Medicine']
        , 'medicine-16-18': ['Curing the Future: Medicine & Disease']
        , 'film-academy-13-18': ['Film Academy: Oxford Through The Lens for ages 13-15', 'Film Academy: Oxford Through The Lens for ages 16-18']
        , 'law': ['Law & Politics', 'Law & Trial Advocacy Academy']
        , 'learn-english': ['English as a Foreign Language (ages 13-15)', 'English as a Foreign Language (ages 16-18)']
        , 'mathematics': ['Mathematics']
        , 'philosophy-literature-and-modern-history': ['Philosophy, Literature and Modern History']
        , 'racing-extinction': ['Racing Extinction: Climate, Politics & Global Leadership for 13-15', 'Racing Extinction: Climate, Politics & Global Leadership for ages 16-18']
    };

    var possibleCourses = slugsToCourse.hasOwnProperty(courseSlug) ? slugsToCourse[courseSlug] : null;
    if (!possibleCourses) {
        possibleCourses = find(slugsToCourse, function (val, key) { return key.indexOf(courseSlug) === 0; });        
    }

    var bestCourseMatch;
    if (possibleCourses) {
        bestCourseMatch = find(courses, function (c) { return some(possibleCourses, function (courseName) { return c.Name === courseName; })});
        if (bestCourseMatch) {
            return {
                region: bestRegionMatch.site,
                course: bestCourseMatch
            };
        }
    }        

    // OR find the course with the closest name in the same region
    bestCourseMatch = maxBy(
        filter(
            map(
                filter(
                    courses, 
                    function (course) { return some(course.Sites, site => site.RegionId === bestRegionMatch.site.RegionId); }
                ),
                function (course) { return { 
                        course: course, 
                        score: sumBy(courseSlugs, function (slug) {
                            if (course.Name.toLowerCase().includes(slug)) {
                                if (/\d+/.test(slug)) { // e.g. 13 in Ages 13-15
                                    return 1;
                                } else {
                                    return 3; // prioritise 1 word match over 2 number matches
                                }
                            }
                            return 0;
                        })
                    } 
                }
            ),
            function(courseMatch) { return courseMatch.score > 0; }
        ),
        function (courseMatch) { return courseMatch.score; }
    );

    return {
        region: bestRegionMatch.site,
        course: bestCourseMatch ? bestCourseMatch.course : false,
    }
}

export default parseDeepLinks;