import React from 'react';
import createReactClass from 'create-react-class';

var Begin = createReactClass({ 
    displayName: 'Begin',
    render: function() {
        var intentParams = new URL(location).searchParams.get("intent"); // if exists, should be oxford-summer-school/course-slug
        var intentSplashImage = intentParams 
            ? 'url("/images/covers/' +  intentParams.replace(/^\/+/, '').split("/")[0] + '.jpg")'
            : 'url("/images/covers/default.jpg")';      

        return <div className=" md:mx-auto">

            <div className="absolute inset-0 bg-cover bg-center bg-no-repeat" style={{backgroundImage:  intentSplashImage }} 
                aria-label="image" 
                alt="Image of campus location">
                    <div className="absolute inset-0 bg-gradient-to-br md:bg-gradient-to-r from-white via-white/90 to-white/10"></div>
            </div>
            <div className=" z-10 relative p-4 pr-8 md:p-8 max-w-xl">
                <h1 className="mb-8 mt-4 lg:mt-8 text-gray-700 text-4xl md:text-5xl ">Welcome! </h1>
                <h2 className="mb-8 mt-4 lg:mt-8 text-gray-700 text-2xl md:text-3xl ">You're just a few questions away from a <span className="text-gold-400 md:text-4xl italic pr-2">life-changing</span> educational experience.</h2>
                <p className="mb-0 text-gray-700 font-normal md:text-xl ">Complete our application form<br className="md:hidden"/> to secure your place</p>
                <p className="mb-6 text-gray-700 font-normal text-sm md:text-base"> (takes 5 minutes to complete)</p>
                <p className=" mb-8 ">
                    <button className="btn btn-lg btn-primary" onClick={this.handleClick}>Start</button>
                </p>
            </div>
        </div>;
    },
    handleClick: function() {
        this.props.wizard.set({
            HasStartedWizard: true,
        });
        this.props.wizard.nextQuestion();
    },
    isValid: function() {
        return true;
    }
});

export default Begin;