import map from 'lodash/map';
import forEach from 'lodash/forEach';
import Cookies from './Cookies.js';

function injectCJPixel(slimOrder) {
    if (!global.OraWidgets.Options.cjEnabled) {
        return;
    }

    var src = 'https://www.emjcd.com/u?';
    src += 'TYPE=' + global.OraWidgets.Options.cjType;
    src += '&CID=' + global.OraWidgets.Options.cjCID;
    src += '&OID=' + slimOrder.OrderId;
    src += '&CURRENCY=GBP';
    src += '&METHOD=IMG';
    var cjEvent = Cookies.get('cj');
    if (cjEvent) {
        src += '&CJEVENT=' + cjEvent;
    }

    forEach(slimOrder.Products,
        function(product, idx) {
            var queryIdx = idx + 1;
            src += '&ITEM' + queryIdx + '=' + product.Id;
            src += '&AMT' + queryIdx + '=' + product.Deposit;
            src += '&QTY' + queryIdx + '=1';
        });

    var img = '<img height="1" width="20" src="' + src + '" />';
    window.jQuery(document.body).append(img);
}

function sendGTMPurchaseTag(slimOrder, redirectUri) {
    var tagToPush = {
        'event': 'purchase',
        'enhanced_conversion_data': {
            "email": slimOrder.CustomerEmailAddress
        },
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': slimOrder.OrderId + '',
                    'affiliation': location.href.indexOf('portal.oxford-royale.co.uk') > -1 || location.href.indexOf('portal.oxford-royale.com') > -1 ? 'Draft Order' : 'Online',
                    'revenue': slimOrder.Total + '',
                    'tax': slimOrder.VatAmount + ''
                },
                'products': map(slimOrder.Products,
                    function(product) {
                        return {
                            'name': product.Name,
                            'id': product.Id,
                            'price': product.Price,
                            'brand': product.Brand,
                            'category': product.Category,
                            'variant': product.Variant,
                            'quantity': product.Quantity
                        };
                    })
            }
        }
    };

    // regardless we redirect away in 5 seconds (assumes there's something informative for the user to read)
    setTimeout(function () {
        if (global.localStorage) {
            // now we clear the basket, set this to true so the basket store doesn't deserialize (and therefore re-render everything and get fooked)
            global.OraWidgets.Options.IsStorageDisabled = true;
            global.localStorage.clear();
        }

        global.location.href = redirectUri;
    }, 5000);

    window.dataLayer = window.dataLayer || [];
    dataLayer.push(tagToPush);
}


export default {
    purchaseTag: function (slimOrder, redirectUri) {
        injectCJPixel(slimOrder);
        sendGTMPurchaseTag(slimOrder, redirectUri);
    },

    //productImpressionsTag: function (event, category, action, label, products) {
    //    window.dataLayer = window.dataLayer || [];
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label,
    //        'ecommerce': {
    //            'currencyCode': 'GBP',
    //            'impressions': []
    //        }
    //    };

    //    products.forEach(function (value, index, array) {
    //        tagToPush.ecommerce.impressions.push({
    //            'name': value.name,
    //            'id': value.id,
    //            'category': value.category,
    //            'variant': value.variant, 
    //            'list': 'Search Results', 
    //            'position': value.positionInSearch 
    //        });
    //    });

    //    dataLayer.push(tagToPush);
    //},


    //productClicksTag: function (event, category, action, label, actionField, product, positionInSearch) {
    //    window.dataLayer = window.dataLayer || [];
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label,
    //        'ecommerce': {
    //            'click': {
    //                'actionField': { 'list': actionField },
    //                'products': [{
    //                    'name': product.name,
    //                    'id': product.id,
    //                    'category': product.category,
    //                    'variant': product.variant, 
    //                    'position': positionInSearch 
    //                }]
    //            }
    //        }
    //    };

    //    dataLayer.push(tagToPush);
    //},

    sessionOptionsTag: function(courseId, courseName, courseTypeName, region) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'view_item_list',
            item_list_id: 'apply_session_options',
            item_list_name: 'Apply Session Options',
            items: [
                {
                    item_id: courseId,
                    item_name: courseName,
                    affiliation: 'Online',
                    item_category: courseTypeName,
                    item_list_id: 'apply_session_options',
                    item_list_name: 'Apply Session Options',
                    item_category2: region
                }
            ]
        });
    },

    bookTag: function(courseId, courseName, courseTypeName, region, intensity, price, sessions) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'add_to_cart',
            currency: 'GBP',
            value: price,
            items: [
                {
                    item_id: courseId,
                    item_name: courseName,
                    affiliation: 'Online',
                    item_category: courseTypeName,
                    item_category2: region,
                    item_category3: intensity,
                    item_category4: sessions,
                    currency: 'GBP',
                    price: price
                }
            ]
        });
    },

    beginCheckoutTag: function(price, courses) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'begin_checkout',
            currency: 'GBP',
            value: price,
            items: [map(courses,
                function(course) {
                    return {
                        item_id: course.Course.CourseId,
                        item_name: course.Course.Name,
                        affiliation: 'Online',
                        item_category: course.Course.CourseType.Name,
                        item_category2: course.Region ? course.Region.RegionName : null,
                        item_category3: course.Intensity ? course.Intensity.Name : null,
                        item_category4: course.Options && course.Options.length && course.Options[0].Session
                            ? map(course.Options, function (o) { return o.Session.Name; }).join(',')
                            : null,
                        currency: 'GBP',
                        price: course.PriceIncludingDiscount
                    };
                })]
            });
    },


    //productDetailsTag: function (event, category, action, label, product) {
    //    window.dataLayer = window.dataLayer || [];
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label,
    //        'ecommerce': {
    //            'detail': {
    //                'products': [{
    //                    'name': product.name, 
    //                    'id': product.id, 
    //                    'category': product.category, 
    //                    'variant': product.variant 
    //                }]
    //            }
    //        }
    //    };

    //    dataLayer.push(tagToPush);
    //},


    //addToCartTag: function (event, category, action, label, product, callback) {
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label,
    //        'ecommerce': {
    //            'currencyCode': 'GBP',
    //            'add': {
    //                'products': [{
    //                    'name': product.name,
    //                    'id': product.id,
    //                    'price': product.price,
    //                    'brand': product.options,
    //                    'category': product.category,
    //                    'variant': product.variant,
    //                    'quantity': product.quantity
    //                }]
    //            }
    //        }
    //    };

    //    var gtmLoaded = window.hasOwnProperty('google_tag_manager');
    //    if (!gtmLoaded) {
    //        if (callback) {
    //            callback();
    //        }
    //    } else {
    //        tagToPush['eventCallback'] = callback;
    //        tagToPush['eventTimeout'] = 1000;
    //    }

    //    window.dataLayer = window.dataLayer || [];
    //    dataLayer.push(tagToPush);
    //},


    removeFromCartTag: function (event, category, action, label, product) {
        window.dataLayer = window.dataLayer || [];
        var tagToPush = {
            'event': event,
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label,
            'ecommerce': {
                'remove': {
                    'products': [{
                        'name': product.name,
                        'id': product.id, 
                        'price': product.price,
                        'brand': product.options, 
                        'category': product.category, 
                        'variant': product.variant, 
                        'quantity': product.quantity
                    }]
                }
            }
        };

        dataLayer.push(tagToPush);
    },


    //checkoutTag: function (event, category, action, label, gaVpv, actionField, products) {
    //    window.dataLayer = window.dataLayer || [];
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label,
    //        'enhanced': 1,
    //        'ga_vpv': {
    //            'page': gaVpv.page,
    //            'title': gaVpv.title
    //        },
    //        'ecommerce': {
    //            'currencyCode': 'GBP',
    //            'checkout': {
    //                'actionField': {
    //                    'step': actionField.step,
    //                    'option': actionField.option   //Omit or set as empty string if not needed
    //                },
    //                'products': []
    //            }
    //        }
    //    };

    //    products.forEach(function (value, index, array) {
    //        tagToPush.ecommerce.checkout.products.push({
    //            'name': value.name, 
    //            'id': value.id, 
    //            'price': value.price, 
    //            'brand': value.options, 
    //            'category': value.category, 
    //            'variant': value.variant, 
    //            'quantity': value.quantity 
    //        });
    //    });

    //    dataLayer.push(tagToPush);
    //},


    //searchResultsTag: function (event, category, action, label) {
    //    window.dataLayer = window.dataLayer || [];
    //    var tagToPush = {
    //        'event': event,
    //        'eventCategory': category,
    //        'eventAction': action,
    //        'eventLabel': label
    //    };

    //    dataLayer.push(tagToPush);
    //}
};