// check for jQuery jQuery
if (!jQuery) {
    throw "Ora Widgets require jQuery to be defined";
}

// make sure credentials are sent with requests
jQuery.ajaxPrefilter(function (options, originalOptions, jqXhr) {
    if (options.url.indexOf(global.OraWidgets.Options.apiEndpoint) > -1) {
        if (options.xhrFields) {
            options.xhrFields.withCredentials = true;
        } else {
            options.xhrFields = { withCredentials: true };
        }
    }
});

function get(url, dataType) {
    return ajax({
        url: url,
        method: 'GET',
        dataType: dataType
    });
}

function post(url, data, dataType) {
    return ajax({
        url: url,
        method: 'POST',
        data: data,
        dataType: dataType
    });
}

function put(url, data, dataType) {
    return ajax({
        url: url,
        method: 'PUT',
        data: data,
        dataType: dataType
    });
}

function del(url) {
    return ajax({
        url: url,
        method: 'DELETE'
    });
}

function ajax(options) {
    standardiseRequest(options);
    return jQuery.ajax(options).fail(handleError);
}

function getQ(url, dataType) {
    return queue({
        url: url,
        method: 'GET',
        dataType: dataType
    });
}

function postQ(url, data, dataType) {
    return queue({
        url: url,
        method: 'POST',
        data: data,
        dataType: dataType
    });
}

function putQ(url, data, dataType) {
    return queue({
        url: url,
        method: 'PUT',
        data: data,
        dataType: dataType
    });
}

function delQ(url) {
    return queue({
        url: url,
        method: 'DELETE'
    });
}

// inspired  (COPIED) from https://github.com/Foliotek/AjaxQ/blob/master/ajaxq.js
var queues = {};
var activeRequests = {};

function queue(options, queueName) {
    if (!queueName) {
        queueName = '__default__';
    }

    standardiseRequest(options);
    var deferred = jQuery.Deferred(),
        promise = deferred.promise();

    promise.success = promise.done;
    promise.error = promise.fail;
    promise.complete = promise.always;

    // Check whether options are to be evaluated at call time or not.
    var deferredOpts = typeof options === 'function';

    // Create a deep copy of the arguments, and enqueue this request.
    var clonedOptions = !deferredOpts ? jQuery.extend(true, {}, options) : null;
    enqueue(function () {
        // Send off the ajax request now that the item has been removed from the queue
        var jqXhr = jQuery.ajax.apply(global, [deferredOpts ? options() : clonedOptions]);

        // set a reasonable timeout
        if (jqXhr.timeout)
            jqXhr.timeout(10000);

        // Notify the returned deferred object with the correct context when the jqXHR is done or fails
        // Note that 'always' will automatically be fired once one of these are called: http://api.jquery.com/category/deferred-object/.
        jqXhr.done(function () {
            deferred.resolve.apply(this, arguments);
        });

        jqXhr.fail(function () {
            deferred.reject.apply(this, arguments);
        });

        jqXhr.always(dequeue); // make sure to dequeue the next request AFTER the done and fail callbacks are fired

        return jqXhr;
    });

    return promise.fail(handleError);

    // If there is no queue, create an empty one and instantly process this item.
    // Otherwise, just add this item onto it for later processing.
    function enqueue(cb) {
        if (!queues[queueName]) {
            queues[queueName] = [];
            var xhr = cb();
            activeRequests[queueName] = xhr;
        }
        else {
            queues[queueName].push(cb);
        }
    }

    // Remove the next callback from the queue and fire it off.
    // If the queue was empty (this was the last item), delete it from memory so the next one can be instantly processed.
    function dequeue() {
        if (!queues[queueName]) {
            return;
        }
        var nextCallback = queues[queueName].shift();
        if (nextCallback) {
            var xhr = nextCallback();
            activeRequests[queueName] = xhr;
        }
        else {
            delete queues[queueName];
            delete activeRequests[queueName];
        }
    }
}

function standardiseRequest(options) {
    if (!options || !options.hasOwnProperty('dataType') || !options.dataType) {
        options.dataType = 'json';
    }
}

var lastError;
function handleError(response) {
    if (response.status == 401 && window.location.href.indexOf('/Opportunity')) {
        return; // swallow these as we allow unauthed access
    }

    if (!lastError || (new Date()).getTime() - lastError.getTime() > 10000) {
        lastError = new Date();
        console.error('There was an un-anticipated error. Please refresh the browser and check that your recent edits have worked');
    }
}

var isQueueRunning = function (qname) {
    return (queues.hasOwnProperty(qname) && queues[qname].length > 0) || activeRequests.hasOwnProperty(qname);
};


var isAnyQueueRunning = function () {
    for (var i in queues) {
        if (queues.hasOwnProperty(i)) {
            if (isQueueRunning(i)) return true;
        }
    }
    return false;
};

jQuery(global).on('beforeunload', function () {
    if ((jQuery.active || isAnyQueueRunning()) && window.location.href.indexOf('www.oxford-royale.co.uk') === -1 && window.location.href.indexOf('www.oxford-royale.com') === -1) {
        return 'There are items being saved. Please click cancel and wait a few seconds before trying to leave the page';
    }
});

export default {
    get: get,
    post: post,
    put: put,
    'delete': del,
    ajax: ajax,
    getQ: getQ,
    postQ: postQ,
    putQ: putQ,
    deleteQ: delQ,
    queue: queue
};