import Dispatcher from "../utilities/OraDispatcher.js";
import { ActionTypes } from "../constants/Constants.js";
import CountryService from "../utilities/CountryService.js";

export function getAll() {
  CountryService.getAll().done(function (countries) {
    // normal API returns paged result. public API returns a list.
    if (countries.Items) {
      countries = countries.Items;
    }

    Dispatcher.dispatch({
      type: ActionTypes.GET_ALL_COUNTRIES,
      countries: countries,
    });
  });
}
