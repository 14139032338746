import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import BasketStore from '../stores/BasketStore.js';
import AvailabilityService from '../utilities/AvailabilityService.js';
import AdManagerService from '../utilities/AdManagerService.js';
import BasketService from '../utilities/BasketService.js';
import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';

function addCourse(course, intensity, options, region, student, priceExcludingDiscount, priceIncludingDiscount, deposit, timeZone, itemId, parentItemId) {
    Dispatcher.dispatch({
        type: ActionTypes.ADD_COURSE,
        lineItem: {
            Course: course,
            Intensity: intensity,
            Options: options,
            Region: region,
            PriceExcludingDiscount: priceExcludingDiscount,
            PriceIncludingDiscount: priceIncludingDiscount,
            Deposit: deposit,
            TimeZone: timeZone
        },
        student: student,
        itemId: itemId,
        parentItemId: parentItemId
    });
}

export default {
    addCourseWithOptions: function(options, courseOptionsData) {
        courseOptionsData.lineItem.Options = options;
        Dispatcher.dispatch({
            type: ActionTypes.ADD_COURSE,
            lineItem: courseOptionsData.lineItem,
            context: courseOptionsData.context
        });

        if (courseOptionsData.callback) {
            courseOptionsData.callback();
        }
    },

    addCourse: addCourse,

    addCourseWithTimeZone: function (timezone, courseOptionsData) {
        courseOptionsData.lineItem.TimeZone = timezone;
        Dispatcher.dispatch({
            type: ActionTypes.ADD_COURSE,
            lineItem: courseOptionsData.lineItem,
            context: courseOptionsData.context
        });

        if (courseOptionsData.callback) {
            courseOptionsData.callback();
        }
    },

    addCourseUsingIds: function(courseId, sessionIds, regionId, student, context, callback) {
        //var isCurrentlyEmpty = BasketStore.numberOfItems() === 0;
        Dispatcher.dispatch({
            type: ActionTypes.GET_LINEITEM_STARTED
        });

        BasketService.getLineItemData(courseId, sessionIds, regionId)
            .done(function (response) {
                response.Course.Type = response.Course.CourseType; // some things refer to Type
                if (response.Course.HasCourseBreakdown || response.Course.CourseType.StudyMethod === 1) {
                    // we're going to let them choose their options
                    Dispatcher.dispatch({
                        type: ActionTypes.GET_LINEITEM_OPTIONS_SUCCESS,
                        lineItem: response,
                        student: student,
                        context: context,
                        callback: callback
                    });
                } else {
                    // add the course straight away
                    Dispatcher.dispatch({
                        type: ActionTypes.ADD_COURSE,
                        lineItem: response,
                        student: student
                    });
                }

                (callback || function () { })();
            })
            .fail(function () {
                Dispatcher.dispatch({
                    type: ActionTypes.GET_LINEITEM_FAILURE,
                    student: student,
                    callback: callback
                });
            });

        //if (isCurrentlyEmpty) {
        //    BasketService.recordNewBasket();
        //}

        //// GTM
        //var event = 'addToCart';
        //var eventCategory = 'ecommerce';
        //var eventAction = 'add to cart';
        //var productVariant = regions && regions.length ? regions[0].Name : null;
        //var productOptions = flatten(map(options,
        //    function (sessionOption) {
        //        if (!sessionOption.Options) {
        //            return [];
        //        }

        //        return map(sessionOption.Options,
        //            function(option) {
        //                return option.CourseOption.Name;
        //            });
        //    })).join('|');
        //var product = { id: course.CourseId, name: course.Name, price: null, options: productOptions, category: course.Type.Name, variant: productVariant, quantity: 1 };

        //AdManagerService.addToCartTag(event, eventCategory, eventAction, product.name, product, callback);
    },

    removeCourse: function (itemId) {
        var item = BasketStore.getCourseByItemId(itemId);
        var courseType, course, courseId;
        if (item) {
            courseType = item.Course.CourseType.Name;
            course = item.Course.Name;
            courseId = item.Course.CourseId;
        }

        Dispatcher.dispatch({
            type: ActionTypes.REMOVE_COURSE,
            itemId: itemId
        });

        // Log GTM
        var event = 'removeFromCart';
        var eventCategory = 'ecommerce';
        var eventAction = 'remove from cart';
        var productVariant = item.Region ? item.Region.Name : null;
        var productOptions = flatten(map(item.Options,
            function (sessionOption) {
                if (!sessionOption.Options) {
                    return [];
                }

                return map(sessionOption.Options,
                    function (option) {
                        return option.CourseOption.Name;
                    });
            })).join('|');

        var product = {
            id: courseId,
            name: course,
            price: null,
            options: productOptions,
            category: courseType,
            variant: productVariant,
            quantity: 1
        };
        
        AdManagerService.removeFromCartTag(event, eventCategory, eventAction, product.name, product);
    },

    removeSession: function (itemId, lineItem, sessionOption) {
        var options =
            filter(
                lineItem.Options,
                function (otherSessionOption) {
                    return sessionOption !== otherSessionOption;
                });

        var sessionIds = map(
            options,
            function (sessionOption) {
                return sessionOption.Session.SessionId;
            });

        BasketService.getLineItemData(
                lineItem.Course.CourseId,
                sessionIds,
                lineItem.Region.RegionId)
            .done(function (response) {
                Dispatcher.dispatch({
                    type: ActionTypes.REPLACE_COURSE,
                    itemId: itemId,
                    lineItem: {
                        Course: response.Course,
                        Intensity: response.Intensity,
                        Options: options,
                        Region: response.Region,
                        PriceExcludingDiscount: response.PriceExcludingDiscount,
                        PriceIncludingDiscount: response.PriceIncludingDiscount,
                        Deposit: response.Deposit
                    },
                    student: lineItem.Student
                });
            });
    },

    getLineItemData: function (courseId, sessionIds, regionId) {
        var cachedLineItem = BasketStore.getLineItem(courseId, sessionIds, regionId);
        if (cachedLineItem) {
            // yes, issue another dispatch so that any views get notified of the new request finishing
            setTimeout(function () {
                    dispatchedGetLineItemDataSuccess(cachedLineItem);
                },
                1);
        } else {
            BasketService.getLineItemData(courseId, sessionIds, regionId)
                .done(function (response) {
                    response.Course.Type = response.Course.CourseType;
                    dispatchedGetLineItemDataSuccess(response);
                });
        }


        function dispatchedGetLineItemDataSuccess(lineItem) {
            Dispatcher.dispatch({
                type: ActionTypes.GET_LINEITEM_DATA_SUCCESS,
                lineItem: lineItem,
                courseId: courseId,
                sessionIds: sessionIds,
                regionId: regionId
            });
        }
    },

    updateStudent: function (itemId, property, value) {
        Dispatcher.dispatch({
            type: ActionTypes.UPDATE_STUDENT,
            itemId: itemId,
            property: property,
            value: value
        });
    },

    chooseStudent: function (itemId, person) {
        Dispatcher.dispatch({
            type: ActionTypes.CHOOSE_STUDENT,
            person: person,
            itemId: itemId
        });
    },

    updateCustomer: function (property, value) {
        Dispatcher.dispatch({
            type: ActionTypes.UPDATE_CUSTOMER,
            property: property,
            value: value
        });
    },

    chooseCustomer: function (person) {
        Dispatcher.dispatch({
            type: ActionTypes.CHOOSE_CUSTOMER,
            person: person
        });
    },

    loadBasket: function (customer, courses, basketIdentifier) {
        Dispatcher.dispatch({
            type: ActionTypes.LOAD_BASKET,
            customer: customer,
            basketIdentifier: basketIdentifier
        });

        forOwn(courses, function (course) {
            var region = course.Region;
            if (!region && course.SelectedRegions && course.SelectedRegions.length) {
                region = course.SelectedRegions[0];
            }

            addCourse(course.Course, course.Intensity, course.Options, region, course.Student, course.PriceExcludingDiscount, course.PriceIncludingDiscount, course.Deposit, course.TimeZone, course.ItemId, course.ParentItemId);
        });
    },

    clearBasket: function() {
        Dispatcher.dispatch({
            type: ActionTypes.CLEAR_BASKET
        });
    },

    recordExtension: function (lineItemOption) {
        Dispatcher.dispatch({
            type: ActionTypes.ADDED_UPSELL,
            lineItemOption: lineItemOption
        });
    }
};

