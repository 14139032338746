import BasketStore from '../../stores/BasketStore.js';
import ActionCreators from '../../actions/CheckoutActionCreators.js';
import AdManagerService from '../../utilities/AdManagerService.js';
import scrollToElement from 'scroll-to-element';

export default {
    returnToStepBeforePayment: function (errorMessage) {
        this.setState({
            fatalPaymentError: errorMessage
        });

        this.previous();
    },

    scrollToTop: function (refParam) {
        if (!!refParam) {
            scrollToElement('body');
        }
    },

    handleBasketChangedFlywire: function () {
        // seems unnecessary given that checkout.jsx has a changeBasket as well
        var countries = BasketStore.getCountries();
        if (countries !== this.state.countries) {
            this.setState({ countries: countries });
        }
        
        var paymentIntentId = BasketStore.getPaymentIntentId();
        if (paymentIntentId !== this.state.callbackId) {
            this.setState({ callbackId: paymentIntentId });
        }

        if (BasketStore.hasPaymentPollingStarted()) {
            if (BasketStore.isPaymentCreated()) {
                this.setState({
                    isPollingPayment: true,
                    hasPaymentSucceeded: false,
                    hasPaymentFailed: false
                });
            } else if (BasketStore.isPaymentSucceeded()) {
                this.setState({
                    isPollingPayment: false,
                    hasPaymentSucceeded: true
                });

                if (!!this.stages && this.stages.length) {
                    this.setState({
                        currentStage: this.stages[4]
                    });
                }

                // redirect
                var order = BasketStore.getOrder();
                var redirectUri = BasketStore.getRedirectUri();
                if (order.HasTriggeredAnalyticsPurchaseEvent) {
                    global.location.href = redirectUri;
                } else {
                    AdManagerService.purchaseTag(order, redirectUri);
                }
            } else if (BasketStore.isPaymentFailed()) {
                var failureReason = BasketStore.getPaymentFailureReason();
                this.setState({
                    isPollingPayment: false,
                    hasPaymentFailed: true,
                    paymentFailureReason: failureReason
                });

                this.onPaymentCancelled();
            }
        }
    },

    onPaymentCancelled: function () { } // gets overridden in derived classes
};