import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import moment from 'moment';

import CourseStore from '../../stores/CourseStore.js';
import Loading from '../common/loading.jsx';
import RegionPrettyNameLookup from './region-pretty-name-lookup';
import FormGroup from '../common/form-group.jsx';
import PressEnter from './press-enter-to-continue.jsx';
import NavigationButtons from './navigation-buttons.jsx';

import CourseSessionPicker from './course-session-picker.jsx';

import any from 'lodash/some';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import partial from 'lodash/partial';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';

var WhatCourse = createReactClass({
    displayName: "WhatCourse",
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function () {
        return {
            courses: CourseStore.getCourses()
        };
    },

    componentDidMount: function () {
        CourseStore.addChangeListener(this.change);
    },

    componentWillUnmount: function () {
        CourseStore.removeChangeListener(this.change);
    },

    change: function () {
        var courses = CourseStore.getCourses();
        this.setState({
            courses: courses,
        });
    },


    updateRegion: function (region) {
        this.props.wizard.set({ Region: region });
    },

    resetCourse: function() {
        this.props.wizard.set({Course: null});
    },

    render: function () {
        var isLoading = !this.state.courses.length;
        if (isLoading) {
            return <Loading />;
        }

        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;
        var student = wizard.state.Student;

        var allCourses = this.state.courses;
        var region = false;
        var excludeCoursesByStudyMethodAndAgeRange = function (c) {
            // does the selected study method match the course type?
            return c.CourseType.StudyMethod === (wizard.state.StudyMethod === "onsite" ? 0 : 1)
                // are there any sessions for this course where the student will be within the age bound?
                && any(c.Dates, function (s) {
                    var studentAge = moment.utc(s.From).diff(student.DateOfBirth, 'years');
                    return any(c.AgeGroups, function (ag) {
                        return (!ag.LowerBound || ag.LowerBound <= studentAge)
                            && (!ag.UpperBound || studentAge <= ag.UpperBound);
                    });
                });
        }
        var courses = filter(allCourses, excludeCoursesByStudyMethodAndAgeRange);
        if (wizard.state.Region) {
            region = this.props.wizard.state.Region;

            var isSelectedRegion = function (s) { return s.RegionId === wizard.state.Region.RegionId };
            courses = filter(courses, function (c) { return any(c.Sites, isSelectedRegion) });
        }

        var regionsForStudentAge = getRegions(allCourses, wizard.state.Student.DateOfBirth);

        courses = sortBy(courses,
            (course) => wizard.state.Course && wizard.state.Course.CourseId === course.CourseId ? 0 : 1,
            (course) => !course.HasCourseBreakdown,
            (course) => course.Name);

        // maybe we have preselected an incompatible course, if so, don't be preselected!
        var isPreselected = wizard.state.Course && wizard.state.Course.IsPreselected && some(courses, c => c.CourseId === wizard.state.Course.CourseId);
        if (isPreselected) {
            courses = filter(courses, c => c.CourseId === wizard.state.Course.CourseId);
        }

        return <React.Fragment>
            <div>
                <h2>
                    {isPreselected
                        ? "Which session would " + student.FirstName + " like to attend?"
                        : "Which Programme would " + student.FirstName + " like to study?"
                    }
                </h2>

                {isPreselected // don't show mini region select if preselected
                    ? false
                    :
                    <div className="hidden md:flex flex-wrap gap-4 my-8">
                        {map(regionsForStudentAge, function (region) {
                            var isSelected = wizard.state.Region && wizard.state.Region.RegionId === region.RegionId;
                            var containerClassNames = "border border-brand-primary rounded-md text-brand-primary cursor-pointer shadow hover:shadow-lg hover:bg-brand-primary hover:text-white focus:outline-2 focus:outline-blue-500"
                                + (isSelected ? " text-white bg-brand-primary" : "");
                            var regionName = GetRegionName(region);
                            var id = "region-" + region.RegionId;

                            return <div id={id} key={region.RegionId} className={containerClassNames} onClick={partial(this.updateRegion, region)}>
                                <div className="flex items-center gap-2 whitespace-nowrap h-12">
                                    <img src={region.RegionThumbnailUrl} className="w-16 rounded-l-md h-full" />
                                    <span className="pr-2 font-bold">{regionName}</span>
                                </div>
                            </div>
                        }.bind(this))}
                    </div>
                }

                <FormGroup state={this.state.hasError ? "invalid" : "valid"}>
                    {(region
                        ? renderCourses.call(this)
                        : false
                    )}
                </FormGroup>

                {isPreselected
                    ? <p className='mb-4'>
                            If you would like {student.FirstName} to study a different programme, please <a className='btn btn-link' onClick={this.resetCourse}>click here</a> to see all our programmes in {GetRegionName(wizard.state.Region)}. Or, to see all our programmes:
                        </p>
                    : false}
                    
                <NavigationButtons wizard={this.props.wizard}
                    hideNext={true}
                    backText={isPreselected || region && region.isPreselected ? "Change campus" : "❮ Back"}
                ></NavigationButtons>

            </div>
        </React.Fragment>

        function renderCourses() {
            return <ul className="flex flex-col gap-4">
                {map(courses, function (c) {
                    return <li key={c.CourseId}>
                        <CourseSessionPicker course={c} wizard={wizard}></CourseSessionPicker>
                    </li>
                }.bind(this))}
            </ul>
        }

        function countCourseOptions(course) {
            return size(groupBy(flatten(map(course.Slots, "Options")), "CourseOptionId"));
        }
    },

    isValid: function () {
        var hasError = false;
        if (!this.state.courses.length || !this.props.wizard.state.Course) {
            hasError = true;
        } else {
            var course = find(this.state.courses, function (c) { return c.CourseId === this.props.wizard.state.Course.CourseId }.bind(this));
            if (!course) {
                hasError = true;
            } else if (this.props.wizard.state.Region) {
                if (!any(course.Sites, function (s) { return s.RegionId === this.props.wizard.state.Region.RegionId }.bind(this))) {
                    hasError = true;
                }
            }
        }

        this.setState({
            hasError: hasError
        });

        return !hasError;
    }
});

function GetRegionName(region) {
    return RegionPrettyNameLookup.hasOwnProperty(region.RegionName)
        ? RegionPrettyNameLookup[region.RegionName]
        : region.RegionName;
}

function isFlagshipCourse(c) {
    return c.Name === "Broadening Horizons" ||
        c.Name === "New Perspectives" ||
        c.Name === "Ivy League Experience";
}

function getRegions(courses, studentDob) {
    var coursesInAgeGroup = filter(courses, function (c) {
        return any(c.Dates, function (cd) {
            var studentAgeOnDate = moment.utc(cd.From).diff(studentDob, 'years');

            function isStudentAgeEligible(ag) {
                return (!ag.LowerBound || ag.LowerBound <= studentAgeOnDate) && (!ag.UpperBound || studentAgeOnDate <= ag.UpperBound);
            }

            return any(c.AgeGroups, isStudentAgeEligible);
        });
    });

    return sortBy(regionsFromCourses(coursesInAgeGroup), function (r) { return r.RegionDisplayOrder; });


    function regionsFromCourses(courses) {
        return uniqBy(flatten(map(courses, function (c) { return c.Sites || [] })), function (r) { return r.RegionId });
    }
}

export default WhatCourse;