
function invalid(object, key, errorMessage, errorObject, fieldNameOverride) {
    errorObject[key + 'State'] = 'invalid';
    var error = errorMessage || 'The ' + (!!fieldNameOverride ? fieldNameOverride : key) + ' is required';
    if (!errorObject[key + 'Errors']) {
        errorObject[key + 'Errors'] = [];
    }

    errorObject[key + 'Errors'].push(error);
}

export default {
    required: function (object, key, errorMessage, errorObject, fieldNameOverride) {
        if (!object.hasOwnProperty(key) || !object[key]) {
            invalid(object, key, errorMessage, errorObject, fieldNameOverride);
            return false;
        }

        return true;
    },

    invalid: invalid
};