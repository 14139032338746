import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import AvailabilityStore from '../../stores/AvailabilityStore.js';
import AvailabilityActionCreators from '../../actions/AvailabilityActionCreators.js';
import OptionsStore from '../../stores/OptionsStore.js';
import OptionsPicker from '../optionspicker/options-picker.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import Loading from '../common/loading.jsx';
import FormGroup from '../common/form-group.jsx';
import PressEnter from './press-enter-to-continue.jsx';
import clone from 'lodash/clone';
import first from 'lodash/first';
import isArray from 'lodash/isarray';

var WhatSessionOptions = createReactClass({
    displayName: "WhatSessionOptions",
    props: {
        wizard: PropTypes.object
    },

    getInitialState: function() {
        var data = this.props.wizard.state;
        return {
            isValid: true,
            options: OptionsStore.getOptions(data.Course, data.Intensity, data.Region, data.Sessions),
            optionsAvailabilities: OptionsStore.getAvailabilities(data.Course, data.Intensity, data.Region, data.Sessions)
        };
    },

    componentDidMount: function() {
        var data = this.props.wizard.state;
        AvailabilityActionCreators.getOptions(data.Course, data.Intensity, data.Region, data.Sessions);
        OptionsStore.addChangeListener(this.updateOptions);
    },

    componentWillUnmount: function() {
        OptionsStore.removeChangeListener(this.updateOptions);
    },

    updateOptions: function() {
        var data = this.props.wizard.state;
        this.setState({
            options: OptionsStore.getOptions(data.Course, data.Intensity, data.Region, data.Sessions),
            optionsAvailabilities: OptionsStore.getAvailabilities(data.Course, data.Intensity, data.Region, data.Sessions)
        });
    },

    render: function() {
        var isLoading = !this.state.options.length;
        if (isLoading) { return <Loading/>; }

        var wizard = this.props.wizard;
        var is3rdPerson = wizard.state.IsUserCustomer;
        var region = clone(wizard.state.Region);
        region.Name = null;
        region.SiteId = null;

        return <React.Fragment><div>
        <h2>Great - just a couple more questions.</h2>
            <label>{wizard.state.Course.Name} requires some subject choices - what would { is3rdPerson ? wizard.state.Student.FirstName : "you" } like to study?</label>
            <FormGroup state={this.state.isValid ? "valid" : "invalid" } validationMessage="All fields are required">
                <OptionsPicker 
                    agentAvailability={[]}
                    course={wizard.state.Course}
                    region={region}
                    intensity={wizard.state.Intensity}
                    availabilities={this.state.optionsAvailabilities}
                    defaultSelectedOptions={wizard.state.CourseOptionSolutions && wizard.state.CourseOptionSolutions.length ? wizard.state.CourseOptionSolutions[0] : []}
                    options={this.state.options}
                    onValid={this.updateCourseOptions}
                    onInvalid={this.updateCourseOnInvalid}
                />
            </FormGroup>

            <PressEnter wizard={wizard} showPressEnter={false} />
        </div>
        <NavigationButtons wizard={this.props.wizard}
            hideCancel={!wizard.state.CurrentItemId}></NavigationButtons></React.Fragment>;  
    },

    updateCourseOptions: function(results) {
        // if there is only one option to choose from, the options picker returns the solution [ { solution }]
        // usually it returns an array of arrays of solutions
        // and elsewhere, we assume the solutions are of the form [ [ {solution A}, ... ], [ {Solution B}, ... ]]
        if (!isArray(first(results))) {
            results = [results];
        }

        this.props.wizard.set({
            CourseOptionSolutions: results
        });
    },

    updateCourseOnInvalid: function() {
        this.props.wizard.set({
            CourseOptionSolutions: []
        });
    },

    isValid: function() {
        var isValid = true;
        if (!this.props.wizard.state.CourseOptionSolutions || !this.props.wizard.state.CourseOptionSolutions.length) { 
            isValid = false;
        }

        this.setState({
            isValid: isValid
        });
        return isValid;
    }
});

export default WhatSessionOptions;