import './options.js';
import "../index.css";
import applyWizard from './applywizard.jsx';
//require('./ai.js');

// load the public facing widgets
//var basket = require('./basket.jsx');
//var applyBasket = require('./applybasket.jsx');

//var checkout = require('./checkout.jsx');
//var checkoutUpsell = require('./checkoutupsell.jsx');
//var stripePaymentForm = require('./stripepaymentform.jsx');
//var flywirePaymentForm = require('./flywirepaymentform.jsx');
//var optionsPicker = require('./optionspicker.jsx');
//var AdManagerService = require('./utilities/AdManagerService.js');
//var CourseStore = require('./stores/CourseStore.js');
//var BasketStore = require('./stores/BasketStore.js');

global.OraWidgets.IsWebsite = true;
//global.OraWidgets.Basket = basket;
//global.OraWidgets.ApplyBasket = applyBasket;
global.OraWidgets.ApplyWizard = applyWizard;
//global.OraWidgets.Checkout = checkout;
//global.OraWidgets.CheckoutUpsell = checkoutUpsell;
//global.OraWidgets.StripePaymentForm = stripePaymentForm;

//global.OraWidgets.FlywirePaymentForm = flywirePaymentForm;

//global.OraWidgets.OptionsPicker = optionsPicker;
//global.OraWidgets.GetBasketIdentifierAndSecret = BasketStore.getBasketIdentifierAndSecret;
//var basketStoreListeners = [];
//global.OraWidgets.LoadBasket = basket.prototype.load;
//global.OraWidgets.GetBasketCount = BasketStore.numberOfItems;
//global.OraWidgets.AddBasketListener = function(listener) {
//    basketStoreListeners.push(listener);
//};

//BasketStore.addChangeListener(function() {
//    for (var i = 0; i < basketStoreListeners.length; i++) {
//        basketStoreListeners[i](BasketStore.getCourses());
//    }
//});

//// check for product details views and product details clicks
//if (jQuery('*[data-ora-course-availability-button]').length) {
//    // we're on a course details page
//    var courseId = jQuery('*[data-ora-course-availability-button]').data('courseid');
//    CourseStore.addChangeListener(sentCourseDetailsGtm);
//}

//function sentCourseDetailsGtm() {
//    var course = CourseStore.getById(courseId);
//    if (course) {
//        var isProductClick = query.hasOwnProperty('r') && query['r'] === 'w';
//        var positionInSearch = query.hasOwnProperty('pis') ? query['pis'] : null;

//        // Find region by looking for data-regionid on availability button and then finding that region within course.Sites
//        var regionId = jQuery('*[data-ora-course-availability-button]').data('regionid');
//        var region = '';
//        if (regionId) {
//            var siteInRegion = find(course.Sites,
//                function (site) {
//                    return site.Region && site.Region.RegionId == regionId;
//                });
//            if (siteInRegion) {
//                region = siteInRegion.Region.Name;
//            }
//        }

//        var product = { id: course.CourseId, name: course.Name, category: course.CourseType, variant: region };

//        AdManagerService.productDetailsTag(
//            'productDetailsView',
//            'ecommerce',
//            'detail view',
//            product.name,
//            product);

//        if (isProductClick) {
//            AdManagerService.productClicksTag(
//                'productClick',
//                'ecommerce',
//                'product click',
//                product.name,
//                positionInSearch ? 'Search Results' : 'Menu',
//                product,
//                positionInSearch);
//        }

//        CourseStore.removeChangeListener(sentCourseDetailsGtm);
//    }
//}