import indexOf from 'lodash/indexOf';

function Store() {
    this.listeners = [];
}

// listener registration
Store.prototype.addChangeListener = function(callback) {
    this.listeners.push(callback);
};

// listener de-registration
Store.prototype.removeChangeListener = function(callback) {
    if (indexOf(this.listeners, callback) !== -1) {
        this.listeners.splice(indexOf(this.listeners, callback), 1);
    }
};

// telling components there's a change with an optional context for those stores that require context
Store.prototype.emitChange = function(context) {
    for (var i = 0; i < this.listeners.length; i++) {
        this.listeners[i](context);
    }
}

export default Store;