import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import FormGroup from '../common/form-group.jsx';
import UserStore from '../../stores/UserStore.js';
import map from 'lodash/map';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import AgentService from '../../utilities/AgentService.js';
import TypeAhead from '../common/typeahead.jsx';

var AgentForm = createReactClass({
    propTypes: {
        isWebAdminUser: PropTypes.bool,
        isAgentBooking: PropTypes.bool,
        onUpdateIsAgentBooking: PropTypes.func,
        agentId: PropTypes.number,
        onUpdateAgentId: PropTypes.func,
        agentName: PropTypes.string,
        onUpdateAgentName: PropTypes.func,
        onUpdateAreFinancialsVisibleToCustomer: PropTypes.func,
        areFinancialsVisibleToCustomer: PropTypes.bool
    },

    getInitialState: function () {
        return {
            agentSearchValue: '',
            areFinancialsVisibleToCustomerAgentDefault: false
        }
    },

    doAgentSearch: function (word, callback) {
        AgentService.searchAgents(word)
            .done(function (response) {
                callback(map(response.Items,
                    function (item) {
                        return {
                            Text: item.Organisation.Name,
                            Agent: item
                        };
                    }));
            });
    },

    agentSearchChange: function(e) {
        this.setState({ agentSearchValue: e.target.value });
    },

    agentSelectionChange: function (e) {
        this.setState({ agentSearchValue: e.Agent.Organisation.Name, areFinancialsVisibleToCustomerAgentDefault: e.Agent.AreFinancialsVisibleToCustomer });
        this.props.onUpdateAgentId(e);
    },

    agentCreateChange: function (e) {
        this.setState({ agentSearchValue: '' });
        this.props.onUpdateAgentName(e);
    },

    render: function () {
        if (this.props.isWebAdminUser) {
            var inner = [
                <div className="customer-details-checkbox-container">
                    <label>
                        <input type="checkbox" onChange={this.props.onUpdateIsAgentBooking} checked={this.props.isAgentBooking} />
                        <div className="checkbox-info">
                            <span>Is this an agent booking?</span>
                        </div>
                    </label>
                </div>];

            if (this.props.isAgentBooking) {
                inner.push(<div className="row">
                    <div className="col-md-6">
                        <FormGroup label="Select an agent">
                            <TypeAhead
                                onSelect={this.agentSelectionChange}
                                onChange={this.agentSearchChange}
                                placeHolder="Search by agent name"
                                autoSuggestEnabled={true}
                                value={this.state.agentSearchValue}
                                func={this.doAgentSearch} />
                        </FormGroup>
                        {this.props.agentId && some(this.state.agents, function (agent) {
                            return agent.AgentId == this.props.agentId && agent.IsUsingCappedAvailabilityModel;
                        }.bind(this)) ? <p className="text-danger">The agent you have selected is on the capped availability model and may have limited or no spaces available for this course</p> : false}
                    </div>
                    <div className="col-md-6">
                        <FormGroup label="Or create a new one">
                            <input type="text" onChange={this.agentCreateChange} value={!this.props.agentId ? this.props.agentName : ''} className="form-control form-input" />
                        </FormGroup>
                    </div>
                </div>);

                inner.push(<div className="row">
                           <div className="col-xs-1 text-right">
                               <input type="checkbox" onChange={this.props.onUpdateAreFinancialsVisibleToCustomer} checked={this.props.areFinancialsVisibleToCustomer} />
                           </div>
                            <div className="col-xs-11">
                        <p>Would you like the customer to be able to see the financial details (total fees, invoices, outstanding balance etc)?</p>
                        {!!this.props.agentId ?
                            (this.props.areFinancialsVisibleToCustomer && !this.state.areFinancialsVisibleToCustomerAgentDefault ?
                                <p className="text-danger">This agent does NOT show financials by default</p> : (this.state.areFinancialsVisibleToCustomerAgentDefault ? <p>This agent shows financials by default</p> : <p>This agent does NOT show financials by default</p>))
                            : false}
                            
                           </div>
                       </div>);
            }

            return <div>{inner}</div>;
        }
    }
});

export default AgentForm;
