 'use strict';

// TODO - fix the onlyContries props. Currently expects that as an array of country object, but users should be able to send in array of country isos

import find from 'lodash/find';

import reduce from 'lodash/reduce';
import map from 'lodash/map';
import filter from 'lodash/filter';
import any from 'lodash/some';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import tail from 'lodash/tail';
import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';

// import lodash string methods
import trim from 'lodash/trim';

import startsWith from 'lodash/startsWith';
import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import onClickOutside from './react-onclickoutside.jsx';
import classNames from 'classnames';
import countryData from './country_mobile_data';
var allCountries = countryData.allCountries;

import validateNumber from '../../validators/Telephone.js';

if (typeof document !== 'undefined') {
    var isModernBrowser = Boolean(document.createElement('input').setSelectionRange);
} else {
    var isModernBrowser = true;
}

var keys = {
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37,
    ENTER: 13,
    ESC: 27,
    PLUS: 43,
    A: 65,
    Z: 90,
    SPACE: 32
};

var ReactTelephoneInput = createReactClass({
    mixins: [onClickOutside],
    getInitialState: function() {
        var inputNumber = this.props.value || '';
        var selectedCountryGuess = this.guessSelectedCountry(inputNumber.replace(/\D/g, ''));
        var selectedCountryGuessIndex = findIndex(allCountries, selectedCountryGuess);
        var formattedNumber = this.formatNumber(inputNumber.replace(/\D/g, ''), selectedCountryGuess ? selectedCountryGuess.format : null);
        var preferredCountries = [];

        preferredCountries = filter(allCountries, function(country) {
            return any(this.props.preferredCountries, function(preferredCountry) {
                return preferredCountry === country.iso2;
            });
        }.bind(this));

        return {
            preferredCountries: preferredCountries,
            selectedCountry: selectedCountryGuess,
            highlightCountryIndex: selectedCountryGuessIndex,
            formattedNumber: formattedNumber,
            showDropDown: false,
            queryString: '',
            freezeSelection: false,
            debouncedQueryStingSearcher: debounce(this.searchCountry, 100)
        };
    },
    propTypes: {
        value: PropTypes.string,
        autoFormat: PropTypes.bool,
        defaultCountry: PropTypes.string,
        onlyCountries: PropTypes.arrayOf(PropTypes.object),
        preferredCountries: PropTypes.arrayOf(PropTypes.object),
        onChange: PropTypes.func,
        onEnterKeyPress: PropTypes.func,
        focusOnLoad: PropTypes.bool
    },
    getDefaultProps: function () {
        return {
            value: '',
            autoFormat: true,
            onlyCountries: allCountries,
            defaultCountry: allCountries[1].iso2,
            showValidation: true,
            isValid: validateNumber,
            flagsImagePath: 'flags.png',
            onEnterKeyPress: function () {}
        };
    },
    getNumber: function () {
        return this.state.formattedNumber !== '+' ? this.state.formattedNumber : '';
    },
    getValue: function () {
        return this.getNumber();
    },
    componentDidMount: function () {
        document.addEventListener('keydown', this.handleKeydown);

        this._cursorToEnd(true);
        //if(typeof this.props.onChange === 'function') {
        //    this.props.onChange(this.state.formattedNumber);
        //}

        if (this.props.focusOnLoad) {
            this.refs.numberInput.focus();
        }
    },
    componentWillUnmount: function () {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    scrollTo: function (country, middle) {
        if(!country) {
            return;
        }

        var container = ReactDOM.findDOMNode(this.refs.flagDropdownList);

        if(!container) {
            return;
        }

        var containerHeight = container.offsetHeight;
        var containerOffset = container.getBoundingClientRect();
        var containerTop = containerOffset.top + document.body.scrollTop;
        var containerBottom = containerTop + containerHeight;

        var element = country;
        var elementOffset = element.getBoundingClientRect();

        var elementHeight = element.offsetHeight;
        var elementTop = elementOffset.top + document.body.scrollTop;
        var elementBottom = elementTop + elementHeight;
        var newScrollTop = elementTop - containerTop + container.scrollTop;
        var middleOffset = (containerHeight / 2) - (elementHeight / 2);

        if (elementTop < containerTop) {
            // scroll up
            if (middle) {
                newScrollTop -= middleOffset;
            }
            container.scrollTop = newScrollTop;
        } else if (elementBottom > containerBottom) {
            // scroll down
            if(middle) {
                newScrollTop += middleOffset;
            }
            var heightDifference = containerHeight - elementHeight;
            container.scrollTop = newScrollTop - heightDifference;
        }
    },
    formatNumber: function (text, pattern) {
        if(!text || text.length === 0) {
            return '+';
        }

        // for all strings with length less than 3, just return it (1, 2 etc.)
        // also return the same text if the selected country has no fixed format
        if((text && text.length < 2) || !pattern || !this.props.autoFormat) {
            return '+' + text;
        }

        var formattedObject = reduce(pattern, function(acc, character) {
            if(acc.remainingText.length === 0) {
                return acc;
            }

            if(character !== '.') {
                return {
                    formattedText: acc.formattedText + character,
                    remainingText: acc.remainingText
                };
            }

            return {
                formattedText: acc.formattedText + first(acc.remainingText),
                remainingText: tail(acc.remainingText)
            };
        }, {formattedText: '', remainingText: text.split('')});
        return formattedObject.formattedText + formattedObject.remainingText.join('');
    },

    // put the cursor to the end of the input (usually after a focus event)
    _cursorToEnd: function (skipFocus) {
        var input = ReactDOM.findDOMNode(this.refs.numberInput);
        if (skipFocus) {
            this.handleInputFocus();
        } else {
            input.focus();

            if (isModernBrowser) {
                var len = input.value.length;
                input.setSelectionRange(len, len);
            }
        }
    },
    // memoize results based on the first 5/6 characters. That is all that matters
    guessSelectedCountry: memoize(function(inputNumber) {
        var secondBestGuess = find(allCountries, function(country) { return country.iso2 == this.props.defaultCountry || country.iso2 == this.props.onlyCountries[0] }.bind(this));
        if(trim(inputNumber) !== '') {
            var bestGuess = reduce(this.props.onlyCountries, function(selectedCountry, country) {
                if(startsWith(inputNumber, country.dialCode)) {
                    if(country.dialCode.length > selectedCountry.dialCode.length) {
                        return country;
                    }
                    if(country.dialCode.length === selectedCountry.dialCode.length && country.priority < selectedCountry.priority) {
                        return country;
                    }
                }

                return selectedCountry;
            }.bind(this), {dialCode: '', priority: 10001});
        } else {
            return secondBestGuess;
        }

        if(!bestGuess.name) {
            return secondBestGuess;
        }

        return bestGuess;
    }),
    getElement: function (index) {
        return ReactDOM.findDOMNode(this.refs['flag_no_' + index]);
    },
    handleFlagDropdownClick: function () {
        // need to put the highlight on the current selected country if the dropdown is going to open up
        this.setState({
            showDropDown: !this.state.showDropDown,
            highlightCountry: find(this.props.onlyCountries, c => c == this.state.selectedCountry),
            highlightCountryIndex: findIndex(this.props.onlyCountries, c => c == this.state.selectedCountry)
        }, function() {
            // only need to scrool if the dropdown list is alive
            if(this.state.showDropDown) {
                this.scrollTo(this.getElement(this.state.highlightCountryIndex + this.state.preferredCountries.length));
            }
        });
    },
    handleInput: function (event) {
        var formattedNumber = '+', newSelectedCountry = this.state.selectedCountry, freezeSelection = this.state.freezeSelection;

        // if the input is the same as before, must be some special key like enter etc.
        if(event.target.value === this.state.formattedNumber) {
            return;
        }

        // ie hack
        if(event.preventDefault) {
            event.preventDefault();
        } else {
            event.returnValue = false;
        }

        if(event.target.value.length > 0) {
            // before entering the number in new format, lets check if the dial code now matches some other country
            var inputNumber = event.target.value.replace(/\D/g, '');

            // we don't need to send the whole number to guess the country... only the first 6 characters are enough
            // the guess country function can then use memoization much more effectively since the set of input it gets has drastically reduced
            if(!this.state.freezeSelection || this.state.selectedCountry.dialCode.length > inputNumber.length) {
                newSelectedCountry = this.guessSelectedCountry(inputNumber.substring(0, 6));
                freezeSelection = false;
            }

            // let us remove all non numerals from the input
            formattedNumber = this.formatNumber(inputNumber, newSelectedCountry ? newSelectedCountry.format : null);
        }

        var caretPosition = event.target.selectionStart;
        var oldFormattedText = this.state.formattedNumber;
        var diff = formattedNumber.length - oldFormattedText.length;

        this.setState({
            formattedNumber: formattedNumber,
            freezeSelection: freezeSelection,
            selectedCountry: newSelectedCountry.dialCode.length > 0 ? newSelectedCountry : this.state.selectedCountry
        }, function() {
            if(isModernBrowser) {
                if(diff > 0) {
                    caretPosition = caretPosition - diff;
                }

                if(caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
                    ReactDOM.findDOMNode(this.refs.numberInput).setSelectionRange(caretPosition, caretPosition);
                }
            }

            if(this.props.onChange) {
                this.props.onChange(this.state.formattedNumber);
            }
        });

    },
    handleInputClick: function () {
        this.setState({showDropDown: false});
    },

    updateCountry: function (countryIso2Code) {
        var currentSelectedCountry = this.state.selectedCountry;
        var nextSelectedCountry = find(this.props.onlyCountries, c => c.iso2 === countryIso2Code);
        if (!nextSelectedCountry) return;

        var newNumber = this.state.formattedNumber.replace(currentSelectedCountry.dialCode, nextSelectedCountry.dialCode);
        var formattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), nextSelectedCountry.format);
        this.setState({
            selectedCountry: nextSelectedCountry,
            formattedNumber: formattedNumber
        });
    },

    handleFlagItemClick: function (country) {
        var currentSelectedCountry = this.state.selectedCountry;
        var nextSelectedCountry = find(this.props.onlyCountries, c => c.iso2 === country.iso2);

        // tiny optimization
        if(currentSelectedCountry.iso2 !== nextSelectedCountry.iso2) {
            // TODO - the below replacement is a bug. It will replace stuff from middle too
            var newNumber = this.state.formattedNumber.replace(currentSelectedCountry.dialCode, nextSelectedCountry.dialCode);
            var formattedNumber = this.formatNumber(newNumber.replace(/\D/g, ''), nextSelectedCountry.format);

            this.setState({
                showDropDown: false,
                selectedCountry: nextSelectedCountry,
                freezeSelection: true,
                formattedNumber: formattedNumber
            }, function() {
                this._cursorToEnd();
                if(this.props.onChange) {
                    this.props.onChange(formattedNumber);
                }
            });
        } else {
            this.setState({showDropDown: false});
        }
    },
    handleInputFocus: function () {
        // if the input is blank, insert dial code of the selected country
        if (ReactDOM.findDOMNode(this.refs.numberInput).value === '+') {
            this.setState({formattedNumber: '+' + this.state.selectedCountry.dialCode});
        }
    },
    _getHighlightCountryIndex: function (direction) {
        // had to write own function because underscore does not have findIndex. lodash has it
        var highlightCountryIndex = this.state.highlightCountryIndex + direction;

        if(highlightCountryIndex < 0
            || highlightCountryIndex >= (this.props.onlyCountries.length + this.state.preferredCountries.length)) {
            return highlightCountryIndex - direction;
        }

        return highlightCountryIndex;
    },
    // memoize search results... caching all the way
    _searchCountry: memoize(function(queryString) {
        if(!queryString || queryString.length === 0) {
            return null;
        }
        // don't include the preferred countries in search
        var probableCountries = filter(this.props.onlyCountries, function(country) {
            return startsWith(country.name.toLowerCase(), queryString.toLowerCase());
        }.bind(this));
        return probableCountries[0];
    }),
    searchCountry: function () {
        var probableCandidate = this._searchCountry(this.state.queryString) || this.props.onlyCountries[0];
        var probableCandidateIndex = findIndex(this.props.onlyCountries, probableCandidate) + this.state.preferredCountries.length;

        this.scrollTo(this.getElement(probableCandidateIndex), true);

        this.setState({
            queryString: '',
            highlightCountryIndex: probableCandidateIndex
        });
    },
    handleKeydown: function (event) {
        if(!this.state.showDropDown) {
            return;
        }

        // ie hack
        if(event.preventDefault) {
            event.preventDefault();
        } else {
            event.returnValue = false;
        }

        var self = this;
        function _moveHighlight(direction) {
            self.setState({
                highlightCountryIndex: self._getHighlightCountryIndex(direction)
            }, function() {
                self.scrollTo(self.getElement(this.state.highlightCountryIndex), true);
            });
        }

        switch(event.which) {
            case keys.DOWN:
                _moveHighlight(1);
                break;
            case keys.UP:
                _moveHighlight(-1);
                break;
            case keys.ENTER:
                this.handleFlagItemClick(this.props.onlyCountries[this.state.highlightCountryIndex], event);
                break;
            case keys.ESC:
                this.setState({showDropDown: false}, this._cursorToEnd);
                break;
            default:
                if((event.which >= keys.A && event.which <= keys.Z) || event.which === keys.SPACE) {
                    this.setState({queryString: this.state.queryString + String.fromCharCode(event.which)},
                        this.state.debouncedQueryStingSearcher);
                }
        }
    },
    handleInputKeyDown: function (event) {
        if(event.which === keys.ENTER) {
            this.props.onEnterKeyPress(event);
        }
    },
    handleClickOutside: function () {
        if(this.state.showDropDown) {
            this.setState({
                showDropDown: false
            });
        }
    },
    getCountryDropDownList: function () {

        var countryDropDownList = map(this.state.preferredCountries.concat(this.props.onlyCountries), function(country, index) {
            var itemClasses = classNames({
                country: true,
                preferred: country.iso2 === 'us' || country.iso2 === 'gb',
                active: country.iso2 === 'us',
                highlight: this.state.highlightCountryIndex === index
            });

            var inputFlagClasses = 'flag ' + country.iso2;

            return (
                <li
                    ref={'flag_no_' + index}
                    key={'flag_no_' + index}
                    data-flag-key={'flag_no_' + index}
                    className={itemClasses}
                    data-dial-code="1"
                    data-country-code={country.iso2}
                    onClick={this.handleFlagItemClick.bind(this, country)}>
                    <div className={inputFlagClasses} style={this.getFlagStyle()} />
                    <span className='country-name'>{country.name}</span>
                    <span className='dial-code'>{'+' + country.dialCode}</span>
                </li>
            );
}.bind(this));

        var dashedLi = (<li key={"dashes"} className="divider" />);
        // let's insert a dashed line in between preffered countries and the rest
        countryDropDownList.splice(this.state.preferredCountries.length, 0, dashedLi);

        var dropDownClasses = classNames({
            'country-list': true,
            'hide': !this.state.showDropDown
});
        return (
            <ul ref="flagDropdownList" className={dropDownClasses}>
{countryDropDownList}
            </ul>
        );
},
    getFlagStyle: function () {
        return {
    width: 16,
    height: 11,
    backgroundImage: 'url(' + this.props.flagsImagePath + ')'
};
},
    render: function () {
        var arrowClasses = classNames({
            'arrow': true,
            'up': this.state.showDropDown
});
        var inputClasses = classNames({
            'form-control': true,
            'invalid-number': this.props.showValidation && !this.props.isValid(this.state.formattedNumber.replace(/\D/g, '')),
            'max-w-lg': true
});

        var flagViewClasses = classNames({
            'flag-dropdown': true,
            'open-dropdown': this.state.showDropDown
});

        var inputFlagClasses = 'flag ' + this.state.selectedCountry.iso2;

        return (
            <div className='react-tel-input intl-tel-input'>
                <input
                    onChange={this.handleInput}
                    onClick={this.handleInputClick}
                    onFocus={this.handleInputFocus}
                    onKeyDown={this.handleInputKeyDown}
                    value={this.state.formattedNumber}
                    ref="numberInput"
                    type="tel"
                    className={inputClasses}
                    autoComplete='tel'
                    placeholder='+1 (702) 123-4567'/>
                <div ref='flagDropDownButton' className={flagViewClasses} onKeyDown={this.handleKeydown} >
                    <div ref='selectedFlag' onClick={this.handleFlagDropdownClick} className='selected-flag' title={this.state.selectedCountry.name + ': + ' + this.state.selectedCountry.dialCode}>
                        <div className={inputFlagClasses} style={this.getFlagStyle()}>
                            <div className={arrowClasses}></div>
                        </div>
                    </div>
{this.state.showDropDown ? this.getCountryDropDownList() : ''}
                </div>
            </div>
        );
}
});

export default ReactTelephoneInput;