import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import create from 'lodash/create';
import map from 'lodash/map';
import Store from './Store.js';

var optionsLookup = {};
var optionsAvailabilitiesLookup = {};

function OptionsStore() {
    Store.call(this);
}

OptionsStore.prototype = create(Store.prototype, {
    'constructor': OptionsStore,

    getOptions: function (course, intensity, region, sessions, startDate) {
        var key = this.getKey(course, intensity, region, sessions, startDate);
        if (optionsLookup.hasOwnProperty(key)) {
            return optionsLookup[key];
        }

        return [];
    },

    getAvailabilities: function(course, intensity, region, sessions, startDate) {
        var key = this.getKey(course, intensity, region, sessions, startDate);
        if (optionsAvailabilitiesLookup.hasOwnProperty(key)) {
            return optionsAvailabilitiesLookup[key];
        }

        return [];
    },

    getKey: function (course, intensity, region, sessions, startDate) {
        var key = course.CourseId + '|' + intensity.IntensityId + '|' + region.RegionId + '|';
        if (startDate) {
            key += startDate;
        } else {
            key += map(sessions, 'SessionId').join(',');
        }

        return key;
    }
});

var optionsStore = new OptionsStore();
optionsStore.dispatchToken = Dispatcher.register(function (payload) {
    switch (payload.type) {
        case ActionTypes.GET_OPTIONS:
            // take the list of course availabilities and map in to a sensible structure for displaying options
            var key = optionsStore.getKey(
                payload.course,
                payload.intensity,
                payload.region,
                payload.sessions,
                payload.startDate);
            if (payload.course.HasCourseBreakdown) {
                optionsLookup[key] = mapOptions(payload);
            }

            optionsAvailabilitiesLookup[key] = payload.lineItem.Availabilities;

            break;

        case ActionTypes.GET_LINEITEM_OPTIONS_SUCCESS:
            optionsLookup[optionsStore.getKey(
                payload.lineItem.Course,
                payload.lineItem.Intensity,
                payload.lineItem.Region,
                payload.lineItem.Sessions)] =
                mapOptions(payload);
            break;

        default:
            return;
    }

    optionsStore.emitChange();
});

function mapOptions(payload) {
    // takes a list of OptionAvailalibity and maps to [{ Session: xxx, Slots: [{ CourseScheduleSlot: yyy, Options: [{ CourseOption: zzz, CourseAvailabilities: [CourseAvailabilities]}]}]}]
    return map(
        groupBy(
            payload.lineItem.Availabilities, 
            function (option) {
                return option.Session.SessionId;
            }),
        function (options) {
            return {
                Session: options[0].Session,
                Slots: map(
                    groupBy(
                        options,
                        function (options) {
                            return options.CourseScheduleSlot.CourseScheduleSlotId;
                        }),
                    function (options) {
                        return {
                            CourseScheduleSlot: options[0].CourseScheduleSlot,
                            Options: map(
                                groupBy(
                                    options,
                                    function (option) {
                                        return option.CourseOption.CourseOptionId;
                                    }),
                                function (options) {
                                    return {
                                        CourseOption: options[0].CourseOption,
                                        CourseAvailabilies: options
                                    };
                                })
                        };
                    })
            };
        }
    );
}

function oldMapOptions(payload) {
    // takes a list of courseavailability and maps to [{ Session: xxx, Slots: [{ CourseScheduleSlot: yyy, Options: [{ CourseOption: zzz, CourseAvailabilities: [CourseAvailabilities]}]}]}]
    var courseAvailabilities = payload.options;
    return map(
        groupBy(
            courseAvailabilities,
            function (courseAvailabilty) {
                return courseAvailabilty.Session ? courseAvailabilty.Session.SessionId : courseAvailabilty.StartDate;
            }
        ),
        function (courseAvailabilities) {
            return {
                Session: courseAvailabilities[0].Session,
                StartDate: courseAvailabilities[0].StartDate,
                Slots: sortBy(
                    map(
                        groupBy(
                            courseAvailabilities,
                            function (courseAvailability) {
                                return courseAvailability.CourseScheduleSlot.CourseScheduleSlotId;
                            }),
                        function (courseAvailabilities) {
                            return {
                                CourseScheduleSlot: courseAvailabilities[0].CourseScheduleSlot,
                                Options: sortBy(
                                    map(
                                        groupBy(
                                            courseAvailabilities,
                                            function (courseAvailability) {
                                                return courseAvailability.CourseOption.CourseOptionId;
                                            }),
                                        function (courseAvailabilities) {
                                            return {
                                                CourseOption: courseAvailabilities[0].CourseOption,
                                                CourseAvailabilities: courseAvailabilities
                                            };
                                        }
                                    ),
                                    function (option) { return option.CourseOption.Name; })
                            };
                        }
                    ),
                    function (slot) {
                        return slot.CourseScheduleSlot.DisplayOrder;
                    })
            };
        });
}

//function getCourse(key) {
//    for (var i = 0; i < optionsLookup[key].length; i++) {
//        if (optionsLookup[key][i].Course) {
//            return optionsLookup[key][i].Course;
//        }
//    }

//    alert('There was an unknown error. Please contact customer support at admin@oxford-royale.co.uk');
//}

//function getSelectedRegions(key) {
//    if (selectedRegionsLookup.hasOwnProperty(key)) {
//        return selectedRegionsLookup[key];
//    }

//    return [];
//}

//function mapChoicesToOptions(key) {
//    var selectedRegions = getSelectedRegions(key);
//    var options = filter(optionsLookup[key], function (option) {
//        return any(selectedRegions, function (selectedRegion) {
//            return option.Site && option.Site.Region && option.Site.Region.RegionId == selectedRegion.RegionId;
//        });
//    });

//    var choices = choicesLookup.hasOwnProperty(key) ? choicesLookup[key] : [];
//    var course = getCourse(key);
//    var intersectedSiteIds = [];
//    var isSessionBased = options[0].CourseType.AvailabilityModel === 0;
//    var isDatesBased = options[0].CourseType.AvailabilityModel === 1;

//    if (isDatesBased) {
//        intersectedSiteIds = intersection.apply(global, map(choices, function (choice) {
//            return map(choice.courseAvailabilities, function(courseAvailability) {
//                return courseAvailability.Site.SiteId;
//            });
//        }));
//    } else if (isSessionBased) {
//        var sessionIds = uniq(map(options, function(option) {
//            return option.Session.SessionId;
//        }));

//        intersectedSiteIds = zipObject(map(sessionIds, function(sessionId) { return 'SessionId' + sessionId; }), map(sessionIds, function(sessionId) {
//            return intersection.apply(global, map(filter(choices, function(choice) {
//                return choice.session.SessionId == sessionId;
//            }), function(choice) {
//                return map(filter(choice.courseAvailabilities, function(courseAvailability) {
//                        return courseAvailability.Male > 0 || courseAvailability.Female > 0;
//                    }), 
//                    function(courseAvailability) {
//                        return courseAvailability.Site.SiteId;
//                    });
//            }));
//        }));
//    }

//    for (var i = 0; i < options.length; i++) {
//        var courseAvailabilityOption = options[i];
//        var isInChoices = inChoices(choices, courseAvailabilityOption);
//        courseAvailabilityOption.Selected = isInChoices;
//        courseAvailabilityOption.Disabled = !isInChoices
//            && ((courseAvailabilityOption.Male === 0 && courseAvailabilityOption.Female === 0)
//                    || choiceMadeInSlot(choices, courseAvailabilityOption)
//                    || (!course.AreOptionsBookablePerSlot && optionAlreadySelected(choices, courseAvailabilityOption))
//                    || ((isDatesBased && choices.length && nonApplicableSite(courseAvailabilityOption, intersectedSiteIds))
//                        || (isSessionBased && intersectedSiteIds['SessionId' + courseAvailabilityOption.Session.SessionId].length && nonApplicableSite(courseAvailabilityOption, intersectedSiteIds))));
//    }

//    return options;
//}

//function inChoices(choices, courseAvailabilityOption) {
//    for (var i = 0; i < choices.length; i++) {
//        if (choices[i].courseAvailabilities[0].CourseOption.CourseOptionId == courseAvailabilityOption.CourseOption.CourseOptionId
//            && choices[i].courseScheduleSlot.CourseScheduleSlotId == courseAvailabilityOption.CourseScheduleSlot.CourseScheduleSlotId
//            && (!choices[i].session || choices[i].session.SessionId == courseAvailabilityOption.Session.SessionId)) {
//            return true;
//        }
//    }

//    return false;
//}

//function choiceMadeInSlot(choices, courseAvailabilityOption) {
//    for (var i = 0; i < choices.length; i++) {
//        if (choices[i].courseScheduleSlot.CourseScheduleSlotId == courseAvailabilityOption.CourseScheduleSlot.CourseScheduleSlotId
//            && (!choices[i].session || choices[i].session.SessionId == courseAvailabilityOption.Session.SessionId)) {
//            return true;
//        }
//    }

//    return false;
//}

//function optionAlreadySelected(choices, courseAvailabilityOption) {
//    for (var i = 0; i < choices.length; i++) {
//        if (choices[i].courseAvailabilities[0].CourseOption.CourseOptionId == courseAvailabilityOption.CourseOption.CourseOptionId) {
//            return true;
//        }
//    }

//    return false;
//}

//function allOptionsSelectedForContext(key) {
//    var options = mapChoicesToOptions(key);
//    var choices = choicesLookup.hasOwnProperty(key) ? choicesLookup[key] : [];

//    // we need to group by options by session and slot and then see if we have a choice in each section
//    return all(options, function(option) {
//        var courseScheduleSlotId = option.CourseScheduleSlot.CourseScheduleSlotId;
//        return some(choices, function(choice) {
//            return (!option.Session || choice.session.SessionId == option.Session.SessionId)
//                && choice.courseScheduleSlot.CourseScheduleSlotId == courseScheduleSlotId;
//        });
//    });
//}

//function nonApplicableSite(courseAvailabilityOption, intersectedSiteIds) {
//    if (courseAvailabilityOption.Session) {
//        return indexOf(intersectedSiteIds['SessionId' + courseAvailabilityOption.Session.SessionId], courseAvailabilityOption.Site.SiteId) === -1;
//    }

//    return indexOf(intersectedSiteIds, courseAvailabilityOption.Site.SiteId) === -1;
//}

export default optionsStore;