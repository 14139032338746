import CountryMap from "./country-list.js";

export default function guessCountry() {
    var langCode = navigator.language; // could be "fr", "fr-FR", "en", "en-GB"...
    var usersIso2CountryCode = langCode;
    if (langCode.includes("-")) {
        usersIso2CountryCode = langCode.split("-")[1].toLowerCase();
    }

    var usersCountry = CountryMap[usersIso2CountryCode] || false;
    return usersCountry;
}