import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import BasketStore from '../../stores/BasketStore';

var NavigationButtons = createReactClass({
    displayName: 'NavigationButtons',
    props: {
        props: {
            wizard: PropTypes.object
        },
    },

    getInitialState: function () {
        return {
            hideCancel: this.props.hideCancel || BasketStore.numberOfItems() == 0
        };
    },

    getDefaultProps: function () {
        return {
            hideBack: false,
            hideNext: false,
            backText: '❮ Back',
            nextText: 'Next ❯',
            cancelText: 'Cancel',
            onBack: null,
            onNext: null,
            onCancel: null,
            backClass: 'float-left',
            cancelClass: 'float-left',
            nextClass: 'float-right'
        }
    },

    next: function () {
        if (this.props.onNext) {
            this.props.onNext();
        } else {
            this.props.wizard.nextQuestion();
        }
    },

    back: function () {
        if (this.props.onBack) {
            this.props.onBack();
        } else {
            this.props.wizard.previousQuestion();
        }
    },

    cancel: function () {
        if (this.props.onCancel) {
            this.props.onCancel();
        } else {
            this.props.wizard.jumpToWhatNext(true);
        }
    },

    render: function() {       

        return <div>
            {   
                this.props.hideNext 
                    ? false
                    : <button className={"btn btn-primary " + this.props.nextClass}
                        onClick={this.next}>{ this.props.nextText }</button> 
            }
            {
                this.props.hideBack 
                    ? false 
                    : <button className={"btn btn-default mr-2 " + this.props.backClass}
                        onClick={this.back}>{ this.props.backText }</button> 
            }
            {
                this.state.hideCancel || this.props.wizard.state.CurrentComponentDisplayName == 'Billing'
                    ? false 
                    : <button className={"btn btn-default " + this.props.cancelClass}
                        onClick={this.cancel}>{ this.props.cancelText }</button> 
            }
    </div>;
    }
});

export default NavigationButtons;