import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import ReactFitText from '../common/react-fit-text.jsx';
import UI from '../../utilities/Ui.js';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import some from 'lodash/some';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import reduce from 'lodash/reduce';
import partial from 'lodash/partial';
import assign from 'lodash/assign';
import flatten from 'lodash/flatten';
import size from 'lodash/size';
import moment from 'moment';
import classNames from 'classnames';
import permute from '../../utilities/permute.js';

var OptionsPicker = createReactClass({
    propTypes: {
        agentAvailability: PropTypes.array,
        course: PropTypes.object.isRequired,
        intensity: PropTypes.object.isRequired,
        options: PropTypes.array,
        startDate: PropTypes.instanceOf(moment),
        availabilities: PropTypes.array.isRequired,
        defaultSelectedOptions: PropTypes.array, // allows you to specify an option to auto select, might (/does) not include the course availabilities for that selection
        onValid: PropTypes.func, // triggered when all options have been made (and the form can be submitted)
        onInvalid: PropTypes.func // triggered whenever an option is deselected and the picker is in a valid state
    },

    getInitialState: function () {
        // { CourseScheduleSlotGroupId: [Option, ... ]} 
        var availabilityGroupedInToCourseScheduleSlotGroups = groupBy(
            this.props.availabilities,
            function (option) {
                if (option.CourseScheduleSlot.CourseScheduleSlotGroup) {
                    return option.CourseScheduleSlot.CourseScheduleSlotGroup.CourseScheduleSlotGroupId;
                }

                return -1;
            });

        // { CourseScheduleSlotGroupId: [{ CourseScheduleSlotGroup: {}, Session: {}, CourseScheduleSlot: {} }]}
        var courseScheduleSlotGroups = mapValues(
            availabilityGroupedInToCourseScheduleSlotGroups,
            function (optionsArray, courseScheduleSlotGroupId) {
                var indicator = optionsArray[0];
                return uniq(
                    map(
                        optionsArray,
                        function (option) {
                            return {
                                CourseScheduleSlotGroup: indicator.CourseScheduleSlot.CourseScheduleSlotGroup,
                                Session: option.Session,
                                CourseScheduleSlot: option.CourseScheduleSlot
                            };
                        }),
                    function (mappedOption) {
                        var sessionId = mappedOption.Session.SessionId;
                        var slotId = mappedOption.CourseScheduleSlot.CourseScheduleSlotId;
                        return sessionId + '|' + slotId;
                    });
            });

        var selectedOptions = mapValues(
            availabilityGroupedInToCourseScheduleSlotGroups,
            function(options, courseScheduleSlotGroupId) {
                return map(
                    filter(
                        this.props.defaultSelectedOptions,
                        function(selectedOption) {
                            return courseScheduleSlotGroupId == -1 &&
                                !selectedOption.CourseScheduleSlot.CourseScheduleSlotGroup ||
                                (selectedOption.CourseScheduleSlot.CourseScheduleSlotGroup &&
                                    selectedOption.CourseScheduleSlot.CourseScheduleSlotGroup.CourseScheduleSlotGroupId ==
                                    courseScheduleSlotGroupId);
                        }),
                    function(selectedOption) {
                        return selectedOption.CourseOption;
                    });
            }.bind(this));

        return {
            availabilityInCourseScheduleSlotGroups: availabilityGroupedInToCourseScheduleSlotGroups,
            courseScheduleSlotGroups: courseScheduleSlotGroups,
            selectedOptions: selectedOptions
        };
    },

    // returns { permutation: [Slot/Session, ...], solution: [AvailabilityItem, ...] }
    getSolution: function (courseScheduleSlotGroups, selectedOptions, candidateCourseOption) {
        var solutions = this.innerGetSolutions(courseScheduleSlotGroups, selectedOptions, candidateCourseOption, true);
        if (!solutions.length) {
            return null;
        }

        return solutions[0];
    },

    // returns [{ permutation: [Slot/Session, ...], solution: [AvailabilityItem, ...] }]
    getSolutions: function(courseScheduleSlotGroups, selectedOptions) {
        return this.innerGetSolutions(courseScheduleSlotGroups, selectedOptions);
    },

    // returns [{ permutation: [Slot/Session, ...], solution: [AvailabilityItem, ...] }]
    innerGetSolutions: function (courseScheduleSlotGroups, selectedOptions, candidateCourseOption, returnFirst) {
        // let's flatten out our selectedOptions (which is {group1: [CourseOption, ...]}) 
        var flattenedSelectedOptions = flatten(map(selectedOptions,
            function(groupCourseOptions) {
                return groupCourseOptions;
            }));

        // there's a shortcut here ... if the options can only be picked once across the booking then we can bail out if the candidate is a dupe
        if (!this.props.course.AreOptionsBookablePerSlot && candidateCourseOption) {
            if (some(flattenedSelectedOptions,
                function(courseOption) {
                    return courseOption.CourseOptionId == candidateCourseOption.CourseOptionId;
                })) {
                return [];
            }
        }

        if (candidateCourseOption) {
            flattenedSelectedOptions.push(candidateCourseOption);
        }

        // this functions tries to find a solution to the selections that have been made
        // i.e. it is possible to solve which options go in which slots in multiple different ways
        // but we must have at least one way to do
        // this also lets us figure out which options we still have left to choose
        // What we do is this:
        //      Get all the session/slot combinations
        //      Generate the permutations of all those combinations
        //      Then work our way through each selected course option and generate the different possible assignments for that option
        var slotSessionCombos = flatten(map(courseScheduleSlotGroups, function (courseScheduleSlotGroup) { return courseScheduleSlotGroup; })); // all the session/slot pairs
        var permutedSlotSessionCombos = permute(slotSessionCombos); // all the session/slot pairs in all the permutations
        var solutions = [];
        for (var permIdx = 0; permIdx < permutedSlotSessionCombos.length; permIdx++) {
            var permutation = permutedSlotSessionCombos[permIdx]; // [{Session1, Slot1}, {Session2: Slot2} ...]
            var permutationSolutionsArray = []; // we build this up as we go (array of arrays i.e. each possible solution for this permutation is an entry in the array)

            for (var selIdx = 0; selIdx < flattenedSelectedOptions.length; selIdx++) {
                var courseOption = flattenedSelectedOptions[selIdx];
                var slotSession = permutation[selIdx];

                // we need to find the availability items that would allow us to satisfy the selected options
                // availabilities must have Total > 0
                // availabilities must match site with existing sessions
                if (selIdx === 0) {
                    // we don't need to consider our previous selections
                    var matchingAvailability = getAvailabilityForCourseOptionAndSessionSlot(
                        this.props.availabilities,
                        courseOption,
                        slotSession.Session,
                        slotSession.CourseScheduleSlot);
                    if (!matchingAvailability.length) {
                        break;;
                    }

                    if (flattenedSelectedOptions.length === 1) {
                        if (returnFirst) {
                            return [{ permutation: permutation, solution: [matchingAvailability[0]] }];
                        }

                        solutions.push.apply(solutions,
                            map(matchingAvailability, function(availability) {
                                return {
                                    permutation: permutation,
                                    solution: [availability]
                                };
                            })
                        );

                        break; // goes round to next permutation
                    }

                    permutationSolutionsArray = map(matchingAvailability,
                        function(availabilityItem) {
                            return [availabilityItem];
                        });
                } else {
                    // we need to loop through the current solutions and make sure we have another solution
                    var nextPermutationSolutionsArray = [];
                    for (var solIdx = 0; solIdx < permutationSolutionsArray.length; solIdx++) {
                        var currentPossibleSolution = permutationSolutionsArray[solIdx];
                        var previousSessionSelection = find(currentPossibleSolution,
                            function(availabilityItem) {
                                return availabilityItem.Session.SessionId === slotSession.Session.SessionId;
                            }); // this will be null if we've not been constrained to a particular site for this session yet
                        var matchingAvailability = getAvailabilityForCourseOptionAndSessionSlot(
                            this.props.availabilities,
                            courseOption,
                            slotSession.Session,
                            slotSession.CourseScheduleSlot,
                            previousSessionSelection ? previousSessionSelection.Site : null);
                        if (!matchingAvailability.length) {
                            continue;
                        }

                        if (selIdx === flattenedSelectedOptions.length - 1) {
                            // we're at the end of our selections and we have a solution!
                            if (returnFirst) {
                                return [{
                                    permutation: permutation,
                                    solution: currentPossibleSolution.concat(matchingAvailability[0])
                                }];
                            }

                            solutions.push.apply(solutions,
                                map(matchingAvailability, function (availability) {
                                    return {
                                        permutation: permutation,
                                        solution: currentPossibleSolution.concat(availability)
                                    };
                                })
                            );

                            break;
                        }

                        for (var mIdx = 0; mIdx < matchingAvailability.length; mIdx++) {
                            nextPermutationSolutionsArray.push(
                                currentPossibleSolution.concat(matchingAvailability[mIdx]));
                        }
                    }

                    if (!nextPermutationSolutionsArray.length) {
                        break;
                    }

                    permutationSolutionsArray = nextPermutationSolutionsArray;
                }
            }
        }

        return solutions;

        function getAvailabilityForCourseOptionAndSessionSlot(availabilities, courseOption, session, courseScheduleSlot, site) {
            // returns those options that match the option, slot and session
            return filter(
                availabilities,
                function (availabilityItem) {
                    return availabilityItem.CourseScheduleSlot.CourseScheduleSlotId === courseScheduleSlot.CourseScheduleSlotId
                        && availabilityItem.Session.SessionId === session.SessionId
                        && availabilityItem.CourseOption.CourseOptionId === courseOption.CourseOptionId
                        && (!site || availabilityItem.Site.SiteId === site.SiteId)
                        && availabilityItem.Total > 0
                        && !availabilityItem.IsClosed;
                });
        } 
    },

    // indicates if all slots have an option selected
    isValid: function (selectedOptions) {
        selectedOptions = selectedOptions || this.state.selectedOptions;
        return every(selectedOptions,
            function(groupSelections, key) {
                return groupSelections.length === this.state.courseScheduleSlotGroups[key].length;
            }.bind(this));
    },
    
    isDisabledForAgent: function (session, courseOption, courseScheduleSlot) {
        // TODO come back to this
        if (!this.props.agentAvailability || this.props.agentAvailability.length === 0) {
            return false;
        }

        var match = find(this.props.agentAvailability,
            function (availability) {
                return availability.SessionId === session.SessionId &&
                availability.CourseOptionId === courseOption.CourseOptionId &&
                availability.CourseScheduleSlotId === courseScheduleSlot.CourseScheduleSlotId;
            });

        return match == undefined ? true : match.Allocated >= match.Capacity;
    },

    chooseOption: function (courseScheduleSlotGroupId, // may be -1 if no slot group
        idx, // the index in to the list of choices that has been selected
        event // the course option that has been chosen
    ) {
        var courseOptionId = event.target.value;
        var courseOption = find(this.props.availabilities,
            function(option) { return option.CourseOption.CourseOptionId == courseOptionId; }).CourseOption;
        var selectedOptions = this.state.selectedOptions;
        selectedOptions[courseScheduleSlotGroupId].push(courseOption);
        this.setState({
            selectedOptions: selectedOptions
        });

        if (this.isValid()) {
            // take the first solution and provide those options as the selection
            this.props.onValid(map(this.getSolutions(this.state.courseScheduleSlotGroups, selectedOptions), function (solution) {
                return solution.solution;
            }));
        }
    },

    clearOption: function (courseScheduleSlotGroupId, idx) {
        var wasValid = this.isValid();
        var selectedOptions = this.state.selectedOptions;
        var groupSelectedOptions = selectedOptions[courseScheduleSlotGroupId];
        var oIdx = groupSelectedOptions.length - 1;
        while (oIdx >= idx) {
            groupSelectedOptions.pop();
            oIdx--;
        }

        this.setState({
            selectedOptions: selectedOptions
        });

        if (wasValid) {
            this.props.onInvalid();
        }
    },

    getSelectedOption: function(courseScheduleSlotGroup, idx) {
        var optionsArray = this.state.selectedOptions[courseScheduleSlotGroup];
        if (optionsArray.length <= idx) {
            return false;
        }

        return optionsArray[idx];
    },

    renderChoice: function(title, choiceElement) {
        return (
            <div className="col-md-4 col-sm-6 col-xs-12 col">
                <label>{title}</label>
                <div className="mb-4">
                    {choiceElement}
                </div>
            </div>);
    },
    
    render: function () {
        var userAgent = navigator.userAgent;
        var removeDisabledOptions = some(['iPhone', 'iPad'], function (word) { return userAgent.indexOf(word) >= 0; });

        return <div>
                {size(Object.values(this.state.courseScheduleSlotGroups)[0]) > 1
                    ? <p className="mb-2">Please note that the selection you make in one list may determine the availability in another list.</p>
                    : false}
                   {map(this.state.courseScheduleSlotGroups, function (options, courseScheduleSlotGroupId) {
                var indicatorGroup = options[0].CourseScheduleSlotGroup;
                var name = indicatorGroup ? indicatorGroup.Name : false;

                var choiceElements = [];
                for (var i = 0; i < options.length; i++) {
                    // we show selections where they've been made
                    // we show a select box for the next choice they need to make
                    // we show help text where they've not chosen a predecessor
                    var selectedOptionForIdx = this.getSelectedOption(courseScheduleSlotGroupId, i);
                    var optionTitle = (!!name
                        ? name + ' '
                        : 'Subject ') + (i + 1);

                    if (selectedOptionForIdx) { // choice already made, just show it
                        choiceElements.push(this.renderChoice(
                            <span>{optionTitle}</span>,
                            <span className="form-control option-picker-selection">
                                <a className="remove-option pull-right float-right" href="javascript:void(0)" onClick={partial(this.clearOption, courseScheduleSlotGroupId, i)}>&#10005;</a>
                                {selectedOptionForIdx.Name}
                            </span>));
                    } else {
                        if (i === 0 || this.getSelectedOption(courseScheduleSlotGroupId, i - 1)) { // it's the first slot in the group or it's the next slot after the last selected
                            // show a selected box
                            var groupAvailability = this.state.availabilityInCourseScheduleSlotGroups[courseScheduleSlotGroupId];
                            var courseOptionAvailability = groupBy(
                                groupAvailability,
                                function(option) {
                                    return option.CourseOption.CourseOptionId;
                                }); // { courseOptionId: [Availability, ...] }
                            courseOptionAvailability = map(
                                courseOptionAvailability,
                                function (availability, courseOptionId) { //[Availability, ...]
                                    var courseOption = availability[0].CourseOption;
                                    return {
                                        CourseOption: courseOption,
                                        IsDisabled: this.getSolution(this.state.courseScheduleSlotGroups, this.state.selectedOptions, courseOption) === null,
                                        Options: availability
                                    };
                                }.bind(this));

                            if (removeDisabledOptions) {
                                courseOptionAvailability = filter(courseOptionAvailability,
                                    function (courseOptionOption) {
                                        return !courseOptionOption.IsDisabled;
                                    });
                            }

                            choiceElements.push(this.renderChoice(
                                <span>{optionTitle}</span>,
                                <select className="form-control mb-4" onChange={partial(this.chooseOption, courseScheduleSlotGroupId, i)}>
                                    <option value="">Select...</option>
                                    {map(
                                        sortBy(
                                            courseOptionAvailability,
                                            function(courseOptionOption) {
                                                return courseOptionOption.CourseOption.Name;
                                            }),
                                        function(courseOptionOption) {
                                            return <option value={courseOptionOption.CourseOption.CourseOptionId} disabled={courseOptionOption.IsDisabled}>{courseOptionOption.CourseOption.Name}</option>;
                                        })}
                                </select>));
                        } else {
                            // show a hint
                            choiceElements.push(this.renderChoice(
                                <span>{optionTitle}</span>,
                                <select className="form-control mb-4"><option>Please make your choices in order</option></select>));
                        }
                    }
                }

                return <div key={indicatorGroup ? indicatorGroup.CourseScheduleSlotGroupId : -1} className="row mb-1">{choiceElements}</div>;
            }.bind(this))}
               </div>;
    }
});

export default OptionsPicker;