import Dispatcher from '../utilities/OraDispatcher.js';
import { ActionTypes } from '../constants/Constants.js';
import BasketStore from '../stores/BasketStore.js';
import BookingService from '../utilities/BookingService.js';
import map from 'lodash/map';
import partial from 'lodash/partial';
import moment from 'moment';
import { DATE_FORMAT } from '../constants/Constants.js';

function makeReservation(isOptingOutOfMarketing, isOraReservation, overrideAgeConstraints, isAgentBooking, agentId, agentName, financesVisibleToCustomer, sendPaymentEmail, isOnlyPayingDeposit) {
    var basketIdentifier = BasketStore.getBasketIdentifier();
    return BookingService.makeReservation(BasketStore.getCustomer(),
        map(BasketStore.getCourses(), function(course) {
            var courseCopy = JSON.parse(JSON.stringify(course)); // create a copy as we're manipulating to send
            var dateOfBirth = course.Student.DateOfBirth;
            if (typeof dateOfBirth === 'string') {
                dateOfBirth = moment.utc(dateOfBirth, [moment.ISO_8601, DATE_FORMAT]);
            }

            courseCopy.Student.DateOfBirth = dateOfBirth.format('YYYY-MM-DDT00:00:00');
            return courseCopy;
        }),
        isOptingOutOfMarketing,
        map(BasketStore.getApplicableTermsAndConditions(), 'DocumentId'),
        isOraReservation,
        overrideAgeConstraints,
        isAgentBooking,
        agentId,
        agentName,
        financesVisibleToCustomer,
        sendPaymentEmail,
        isOnlyPayingDeposit,
        basketIdentifier);
}

function checkoutError(response, actionType) {
    var responseJson = response.responseJSON;
    if (!responseJson) {
        try {
            responseJson = jQuery.parseJSON(response.responseText);
        } catch (e) {
            responseJson = { Message: 'There was an unknown error: ' + response.responseText };
        }
    }
    Dispatcher.dispatch({
        type: actionType,
        response: responseJson
    });
}

function reservationError(response) {
    checkoutError(response, ActionTypes.MAKE_RESERVATION_FAILURE);
}

var pollingStart;

function beginPollPaymentStatus(paymentIntentId, orderId) {
    setTimeout(function () {
        Dispatcher.dispatch({
            type: ActionTypes.POLL_PAYMENT_START
        });
    }, 1000);

    pollingStart = new Date();
    pollPaymentStatus(paymentIntentId, orderId);
}

function pollPaymentStatus(paymentIntentId, orderId) {
    var reservation = BasketStore.getReservation();
    var reservationId = reservation ? reservation.ReservationId : null;

    BookingService.getPaymentIntent(reservationId, paymentIntentId, orderId).done(function (response) {
        // success or failure response
        // - if the user comes back to this page and they've paid successfully, we want them to be able to continue regardless of whether we're checking the correct attempt.
        // - if the payment has failed, we want to ensure that we are checking the current attempt.
        if (response.Status === 2 || (response.Status === 3)) {
            Dispatcher.dispatch({
                type: ActionTypes.POLL_PAYMENT_FINISHED,
                paymentStatus: response.Status,
                paymentFailureReason: response.Error,
                redirectUri: response.RedirectUri,
                order: response.Order
            });
        } else if ((new Date() - pollingStart) > 1000 * 60 * 30) { // Poll for 30 mins
            Dispatcher.dispatch({
                type: ActionTypes.POLL_PAYMENT_FINISHED,
                paymentStatus: 3,
                paymentFailureReason: 'Payment timed out waiting from a response from Flywire',
                redirectUri: null,
                order: null
            });
        } else {
            setTimeout(partial(pollPaymentStatus, paymentIntentId, orderId), 2000);
        }
    });
}

export default {
    makeReservation: function(isOptingOutOfMarketing,
        isOraReservation,
        overrideAgeConstraints,
        isAgentBooking,
        agentId,
        agentName,
        financesVisibleToCustomer,
        doPay,
        isOnlyPayingDeposit) {
        // issue the request to the api for making a reservation
        Dispatcher.dispatch({
            type: ActionTypes.MAKE_RESERVATION_STARTED
        });

        makeReservation(isOptingOutOfMarketing,
                isOraReservation,
                overrideAgeConstraints,
                isAgentBooking,
                agentId,
                agentName,
                financesVisibleToCustomer,
                null,
                isOnlyPayingDeposit)
            .done(function(reservation) {
                Dispatcher.dispatch({
                    type: ActionTypes.MAKE_RESERVATION_SUCCESS,
                    reservation: reservation,
                    makePayment: doPay
                });
            })
            .fail(reservationError);
    },

    confirmBooking: function(isOptingOutOfMarketing,
        isOraReservation,
        overrideAgeConstraints,
        isAgentBooking,
        agentId,
        agentName,
        financesVisibleToCustomer,
        sendPaymentEmail,
        bookingCompleteUrl) {
        Dispatcher.dispatch({
            type: ActionTypes.MAKE_RESERVATION_STARTED
        });

        makeReservation(isOptingOutOfMarketing,
                isOraReservation,
                overrideAgeConstraints,
                isAgentBooking,
                agentId,
                agentName,
                financesVisibleToCustomer)
            .done(function(reservation) {
                Dispatcher.dispatch({
                    type: ActionTypes.MAKE_BOOKING_STARTED
                });

                // make the order
                BookingService.completeBooking(reservation.ReservationId, sendPaymentEmail).done(
                    function(orderResponse) {
                        if (orderResponse.WasSuccessful) {
                            Dispatcher.dispatch({
                                type: ActionTypes.MAKE_BOOKING_SUCCESS
                            });
                            if (confirm('The order has been made. Would you like to view the order?') &&
                                bookingCompleteUrl) {
                                location.assign(bookingCompleteUrl + "/" + orderResponse.Order.OrderId);
                            }
                        } else {
                            Dispatcher.dispatch({
                                type: ActionTypes.MAKE_RESERVATION_FAILURE,
                                response: { Message: 'The reservation was successful but the booking failed' }
                            });
                        }
                    }).fail(function() {
                    Dispatcher.dispatch({
                        type: ActionTypes.MAKE_RESERVATION_FAILURE,
                        response: { Message: 'The reservation was successful but the booking failed' }
                    });
                });
            })
            .fail(reservationError);
    },

    beginPolling: function (paymentIntentId, orderId) {
        if (paymentIntentId === null) {
            paymentIntentId = BasketStore.getPaymentIntentId();
        }

        beginPollPaymentStatus(paymentIntentId, orderId);
    },

    createPaymentIntentForOrder: function(orderId, transactionIds) {
        if (orderId !== null) {
            BookingService.createPaymentIntentForOrder(orderId, transactionIds).done(function(orderResponse) {
                Dispatcher.dispatch({
                    type: ActionTypes.CREATE_PAYMENT_INTENT_SUCCESS,
                    paymentIntentId: orderResponse.PaymentIntentId
                });
            });
        }
    }
};