import widgetOptions from './WidgetOptions.js';
import http from './Http.js';

export default {
    getCourseAvailability: function (courseId, agentId) {
        // TODO fix for agents
        return http.get(widgetOptions.apiEndpoint() + '/availability?courseId=' + courseId);
    },

    getAgentAvailability: function (courseId, agentId) {
        // TODO Fix
        return http.get(widgetOptions.apiEndpoint() + '/Course/' + courseId + '/AgentAvailability?agentId=' + agentId);
    },

    sendEnquiry: function(customer, course, intensity, options, message, regions) {
        return http.post(widgetOptions.apiEndpoint() + '/Course/' + course.CourseId + '/Enquiry', {
            customer: customer,
            course: course,
            intensity: intensity,
            options: options,
            message: message,
            regions: regions
        });
    },

    sendNotifyRequest: function(course, intensity, options, region, emailAddress, phoneNumber, studentName, studentGender) {
        return http.post(widgetOptions.apiEndpoint() + '/NotifyRequest', {
            course: course,
            intensity: intensity,
            options: options,
            region: region,
            emailAddress: emailAddress,
            phoneNUmber: phoneNumber,
            studentName: studentName,
            studentGender: studentGender
        });
    },

    getTimeZones: function(course, sessions) {
        return http.get(widgetOptions.apiEndpoint() +
            '/availability/timezones?courseId=' +
            course.CourseId +
            sessions.map(function (s) { return "&sessionIds=" + s.SessionId; }).join(""));
    },

    getOptions: function(course, sessions, regionId) {
        return http.get(widgetOptions.apiEndpoint() +
            '/Availability/Item?courseId=' +
            course.CourseId +
            sessions.map(function(s) { return "&sessionIds=" + s.SessionId; }).join("") +
            "&regionId=" +
            regionId);
    }
};